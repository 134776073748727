import { BaseTitle, IRouteConfig } from '@/infrastructure/route/vip-router'
import { Administrator, Informatieaanvrager, Informatieleverancier, Kwaliteitscontroleur } from '@/infrastructure/constants/roles'

export const RouteDashboard: IRouteConfig = {
  path: '/',
  name: 'dashboard',
  meta: {
    title: BaseTitle,
    authorize: {
      roles: [Administrator, Informatieaanvrager, Informatieleverancier, Kwaliteitscontroleur]
    },
    breadcrumb: {
      title: 'Vastgoedinformatieplatform'
    }
  },
  component: () => import('./dashboard.vue')
}

export default [RouteDashboard] as IRouteConfig[]
