import Vue from 'vue'

export default Vue.extend({
  name: 'vip-preview-stedenbouwkundige-overtreding-minnelijke-schikking',
  props: {
    data: Object
  },
  data () {
    return {
      previewData: JSON.parse(JSON.stringify(this.data))
    }
  }
})
