import Vue from 'vue'
import App from './modules/app-module/app.vue'
import { createRouter } from './infrastructure/route/vip-router'
import WebComponents from './infrastructure/web-components'
import IpproComponents from './infrastructure/ippro-components'
import VipComponents from './infrastructure/vip-components'
import Filters from './infrastructure/filters/filters'
import VueGtag from 'vue-gtag'
import VueCookies from 'vue-cookies'
import Multiselect from 'vue-multiselect'
import { PiniaVuePlugin } from 'pinia'
import pinia from '@/infrastructure/stores/store'

Vue.use(WebComponents)
Vue.use(IpproComponents)
Vue.use(VipComponents)
Vue.use(Filters)
Vue.use(VueCookies)
Vue.component('multi-select', Multiselect)
Vue.use(PiniaVuePlugin)

const router = createRouter()

if (config.VUE_APP_ENV !== 'development') {
  Vue.use(VueGtag, {
    config: {
      id: config.VUE_APP_GTAG_ID,
      params: {
        anonymize_ip: true
      }
    }
  }, router)
}

new Vue({
  router,
  pinia,
  render: h => h(App)
}).$mount('#app')
