import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-verkavelingsvergunning-vergunning',
  data () {
    return {
      beschrijving: this.getAttribute('beschrijving'),
      dossiernummer: this.getAttribute('dossier_nummer'),
      gemeentelijkDossiernummer: this.getAttribute('gemeentelijk_dossier_nummer'),
      gewestelijkDossiernummer: this.getAttribute('gewestelijk_dossier_nummer'),
      aardVanDeAanvraag: this.getAttribute('aard_van_de_aanvraag'),
      aanvrager: this.getAttribute('aanvrager'),
      verlener: this.getAttribute('vergunningVerlenendeOverheid'),
      status: this.getAttribute('beslissing_eerste_aanleg'),
      beslist_op: this.getAttribute('beslist_op'),
      vervalDatum: this.getAttribute('Vervaldatum'),
      vervalMotivering: this.getAttribute('VervalMotivering'),
      vervalStatus: this.getAttribute('VervalStatus'),
      beroep_aangetekend: this.getAttribute('beroep_aangetekend'),
      beroepsProcedures: this.getAttribute('beroeps_procedures'),
      beroepsProceduresFields: [
        'beroeps_instantie',
        'beroep_status',
        'beroep_beslist_op'
      ],
      voorwaarden: this.getAttribute('voorwaarden_vergunning'),
      lasten: this.getAttribute('lasten_verbonden_aan_vergunning'),
      verkoopbaarheidsAttest_uitgereikt_op: this.getAttribute('verkoopbaarheidsattest_uitgereikt_op'),
      lotenFields: [
        'lot_nummer',
        'lot_datum',
        'lot_status',
        'lot_motivering'
      ],
      loten: this.getAttribute('loten'),
      link: this.getAttribute('link'),
      bijlage: this.getAttribute('bijlagen'),
      intrekkingPartij: this.getAttribute('Intrekking_PartijType'),
      intrekkingDatum: this.getAttribute('Intrekking_Datum'),
      intrekkingMotivering: this.getAttribute('Intrekking_Motivering'),
    }
  },
  methods: {
    beroepAangetekendChange: function (beroepAangetekend: boolean) {
      // Om één of andere reden triggered vl-radio dit 2x. De tweede maal bevat het de gekozen waarde.
      if (typeof beroepAangetekend === 'boolean' && !beroepAangetekend) {
        this.beroepsProcedures.value = null
      }
    }
  },
  computed: {
    lastenOptions: {
      get: function () {
        return this.getAttribute('lasten_verbonden_aan_vergunning').options
      }
    },
    setDatumBeslissingVerplicht () {
      const verplicht = this.getAttribute('beslissing_eerste_aanleg').value !== 'GeenTijdigeBeslissing'
      this.getAttribute('beslist_op').required = verplicht
      return verplicht
    }
  },
  watch: {
    'vervalStatus.value': function (value: unknown) {
      if (value && value !== 'Ingetrokken') {
        this.intrekkingPartij.value = ''
        this.intrekkingDatum.value = ''
        this.intrekkingMotivering.value = ''
      } else if (value === 'Ingetrokken') {
        this.vervalMotivering.value = ''
        this.vervalDatum.value = ''
      }
    }
  }
})
