import { generateKey } from '@/infrastructure/helpers/general'
import Vue from 'vue'

export default Vue.extend({
  name: 'vip-inlichting-van-toepassing',
  props: {
    label: {
      required: true,
      type: String
    },
    value: Boolean
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.userValue = this.value
      }
    }
  },
  methods: {
    update () {
      this.$emit('input', this.userValue)
      Vue.nextTick(() => {
        this.$emit('toggled', this.userValue)
      })
    }
  },
  data () {
    return {
      key: generateKey(),
      userValue: null
    }
  }
})
