import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'
import EventBus from '@/infrastructure/events/event-bus'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-stedenbouwkundige-overtreding',
  data () {
    return {
      beschrijving: this.getAttribute('omschrijving'),
      dossierNummer: this.getAttribute('dossierNummer'),
      gemeentelijkDossiernummer: this.getAttribute('gemeentelijkDossiernummer'),
      datum: this.getAttribute('overtredingDatum'),
      toelichting: this.getAttribute('overtredingToelichting'),
      status: this.getAttribute('overtredingStatus'),
      gerelateerdeWetgeving: this.getAttribute('gerelateerdeWetgeving'),
      procedures: this.getAttribute('procedures'),
      wetgevingGewijzigd: false,
      externeDocumentatie: this.getAttribute('externeDocumentatie'),
      proceduresToRemove: [],
      voegMisdrijfToe: false,
      bijlage: this.getAttribute('bijlagen')
    }
  },
  computed: {
    overtredingStatusRequired () {
      const datum = this.getAttribute('overtredingDatum').value
      const toelichting = this.getAttribute('overtredingToelichting').value
      return (datum != null && (datum as []).length > 0) || (toelichting != null && toelichting !== '')
    }
  },
  methods: {
    cancelRemove () {
      this.wetgevingGewijzigd = false
      this.proceduresToRemove = []
      this.gerelateerdeWetgeving.value = 'NaOmgevingsvergunningendecreet'
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    },
    removeInbreuken () {
      for (let i = this.proceduresToRemove.length - 1; i >= 0; i--) {
        this.procedures.value.splice(this.proceduresToRemove[i], 1)
      }
      this.proceduresToRemove = []
      this.wetgevingGewijzigd = false
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    },
    voorOmgevingVergunningDecreetSelected () {
      if (this.gerelateerdeWetgeving.value === 'VoorOmgevingsvergunningendecreet' && (!this.procedures.value || this.procedures.value.length === 0)) {
        this.voegMisdrijfToe = true
      }
    }
  },
  mounted () {
    this.status.required = this.overtredingStatusRequired
  }
})
