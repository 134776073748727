import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-vergund-geachte-gebouwen',
  data () {
    return {
      beschrijving: this.getAttribute('beschrijving'),
      gemeentelijkDossiernummer: this.getAttribute('gemeentelijkDossiernummer'),
      type: this.getAttribute('type'),
      functieGebouw: this.getAttribute('functieGebouw'),
      isKwetsbaarGebied: this.getAttribute('isKwetsbaarGebied'),
      vergunningverlenendeOverheid: this.getAttribute('vergunningverlenendeOverheid'),
      status: this.getAttribute('status'),
      beslistOp: this.getAttribute('beslistOp'),
      beroepAangetekend: this.getAttribute('beroepAangetekend'),
      beroepStatus: this.getAttribute('beroep_status'),
      beroepBeslistOp: this.getAttribute('beroep_beslist_op'),
      referentie: this.getAttribute('referentie'),
      externeDocumentatie: this.getAttribute('externeDocumentatie'),
      boxClasses: 'vip-box vip-box--small'
    }
  },
  watch: {
    'beroepAangetekend.value': function (value: unknown) {
      if (value === false) {
        this.beroepStatus.value = null
        this.beroepStatus.required = false
        this.beroepBeslistOp.value = null
        this.beroepBeslistOp.required = false
        this.boxClasses = 'vip-box vip-box--small'
      } else {
        this.beroepStatus.required = true
        this.beroepBeslistOp.required = true
        this.boxClasses = 'vip-box vip-box--small vip-box-no-bottom'
      }
    }
  }
})
