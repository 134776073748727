import { useUserStore } from '@/infrastructure/stores/user-store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UnauthorizedModal',
  setup () {
    const userStore = useUserStore()

    const logoutUser = () => {
      userStore.userModuleSetUserInactive()
    }

    return {
      logoutUser
    }
  }
})
