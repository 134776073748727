import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '@/components/vip-inlichting/components/vip-inlichting-attributes/vip-inlichting-attributes'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-exploitatie-handeling-activiteiten',
  data () {
    return {
      rubriek: this.getAttribute('activiteitRubriek'),
      vlareboCode: this.getAttribute('activiteitVlareboCode'),
      klasse: this.getAttribute('activiteitRisicoKlasse'),
      omschrijving: this.getAttribute('activiteitOmschrijving'),
      status: this.getAttribute('activiteitStatus'),
      geldigVan: this.getDatePeriodFromAttribute('periode'),
      geldigTotEnMet: this.getDatePeriodUntilAttribute('periode'),
      rubriekTypeLabel: ''
    }
  }
})
