import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-as-built-attest',
  data () {
    return {
      beschrijving: this.getAttribute('Beschrijving'),
      referentie: this.getAttribute('Referentie'),
      dossiernummer: this.getAttribute('Dossiernummer'),
      aardBeslissing: this.getAttribute('AardBeslissing'),
      datumBeslissing: this.getAttribute('DatumBeslissing'),
      externeDocumentatie: this.getAttribute('ExterneDocumentatie')
    }
  }
})
