import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-kapmachtiging',
  data () {
    return {
      beschrijving: this.getAttribute('beschrijving'),
      referentie: this.getAttribute('referentie'),
      geldigVan: this.getDatePeriodFromAttribute('geldigPeriode'),
      geldigTot: this.getDatePeriodUntilAttribute('geldigPeriode'),
      bijlage: this.getAttribute('bijlagen')
    }
  }
})
