import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  // De directory en bestandsnamen zijn ingekort t.o.v. de anderen omdat het tegen de max. OS length aanliep.
  name: 'vip-inlichting-attributes-template-erfdienstbaarheid-openbaar-nut',
  data () {
    return {
      beheerderBegunstigde: this.getAttribute('beheerderBegunstigde'),
      omschrijving: this.getAttribute('omschrijving'),
      link: this.getAttribute('externeDocumentatie'),
      bijlage: this.getAttribute('bijlagen')
    }
  },
  watch: {

  },
  computed: {
    erfdienstbaarheidType: {
      get: function () {
        return this.getAttribute('erfdienstbaarheidType')
      }
    }
  }
})
