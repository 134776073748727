import Vue from 'vue'

export default Vue.extend({
  name: 'vip-inlichting-actions-remove-modal',
  data () {
    return {
      removeModalopen: false,
      isRemoving: false,
      modalZindex: 402300
    }
  },
  props: {
    modDisabled: Boolean
  },
  methods: {
    cancel () {
      this.$emit('close')
    },
    removeInlichting () {
      this.isRemoving = true
      this.$emit('remove')
    }
  },
  mounted () {
    this.$root.$on('closed', () => {
      this.cancel()
    })
  }
})
