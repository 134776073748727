import { BffClientFactory } from '@/infrastructure/bff-client/bff-client-handler'
import IpproMap, { IpproMapZone } from '@/components/ippro/vl-ippro-map/vl-ippro-map'
import Vue from 'vue'
import IpproMapSearch from '@/components/ippro/vl-ippro-map/components/ippro-map-search/ippro-map-search'
import { CaPaKeyInfoResponse, GemeenteAansluitInfo } from '@/infrastructure/bff-client/bff-client'
import Feature from 'ol/Feature'
import WKT from 'ol/format/WKT'
import IpproMapSearchExtended from '@/components/ippro/vl-ippro-map/components/ippro-map-search-extended/ippro-map-search-extended'
import { catchBffError, generateKey, getLocationExtentAndGemeente, isCaPaKeyFormat } from '@/infrastructure/helpers/general'
import VipGebouwEenheden from '../vip-gebouw-eenheden/vip-gebouw-eenheden'
import Fill from 'ol/style/Fill'
import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import GeoJSON from 'ol/format/GeoJSON'
import { useKaartStore } from '@/infrastructure/stores/kaart-store'
import { Extent, getCenter } from 'ol/extent'
import { AlertMethod, AlertType, useAlertStore } from '@/infrastructure/stores/alert-store'
import CapakeySearch from './components/capakey-search/capakey-search.vue'
import { mapState, mapStores } from 'pinia'

import axios from 'axios'
import VipInlichtingAttributesTemplateBeleidsplan
  from '@/components/vip-inlichting/components/vip-inlichting-attributes/templates/beleidsplan/vip-inlichting-attributes-template-beleidsplan'
import VipKaartImport from '@/components/vip-kaart/components/vip-kaart-import/vip-kaart-import.vue'
import { useUserStore } from '@/infrastructure/stores/user-store'
import { formatDate } from '@/infrastructure/filters/filters'

enum ZoneType {
  selected = 1,
  added = 2,
  inActive = 3,
  active = 4,
  hoverZone = 5
}

interface VipStatistiek {
  kpi?: string | undefined;
  waarde?: number;
}

export default Vue.extend({
  name: 'vip-kaart',
  components: {
    VipKaartImport,
    VipInlichtingAttributesTemplateBeleidsplan,
    CapakeySearch
  },
  data () {
    return {
      toonSpinner: false,
      map: null,
      toonSidebar: false,
      toonGebouweenhedenSeperator: false,
      geometryKey: '' as string,
      adres: '' as string,
      geometrie: '' as string,
      zoneSelectie: [] as { geometryKey: string, zone: IpproMapZone }[],
      hoveredFeature: null as Feature,
      canSelectPerceelMinZoomLevel: 7,
      searchComponentKey: generateKey(),
      gebouwEenhedenComponentKey: generateKey(),
      zoomAllowed: true,
      toelichting: '',
      gemeenteNietAangeslotenMelding: '',
      gemeenteNietAangeslotenExtra: { loket: '', email: '' },
      caPaKeysNietAangeslotenGemeenten: [] as string[],
      zoomedIn: false,
      popupVisible: false,
      popupContentTitle: '',
      popupContentTitleTextInline: '',
      popupContentText: { loket: '', email: '', extraInfo: '' },
      popupPosition: null,
      adresNietGekend: false,
      hoveredCapaKey: '',
      laatsteAanvraagDatum: null,
      openbaarDomeinActive: false,
      openbaarDomeinSelected: false
    }
  },
  props: {
    modMapBasic: Boolean
  },
  computed: {
    zones (): IpproMapZone[] {
      const defStyle = this.getStyle(ZoneType.selected)
      return Array.from(new Set([...[{
        geometry: this.geometrie, style: defStyle, meta: { id: 's-' + this.geometryKey, title: this.geometryKey, ref: ZoneType.selected.toString() }
      }],
      ...this.zoneSelectie.map(gz => gz.zone)
      ]))
    },
    kpiList (): VipStatistiek[] {
      return config.VUE_APP_STATISTICS
    },
    zoomLevel (): number {
      return this.map.getView().getZoom()
    },
    canSelectPerceel (): boolean {
      return this.map?.getView()?.getZoom() >= this.canSelectPerceelMinZoomLevel
    },
    classes (): Record<string, unknown> {
      return {
        'vip-kaart': true,
        'vip-kaart--interactive': this.canSelectPerceel
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    feature (): Feature<any> {
      return this.geometrie ? new WKT().readFeature(this.geometrie) : null
    },
    gemeenteInfo (): GemeenteAansluitInfo[] {
      return this.kaartStore.kaartGeefAangeslotenGemeenten
    },
    openbaarDomeinButtonClass (): string {
      return this.openbaarDomeinActive ? 'import-shape openbaar-domein-toggle-active' : 'import-shape'
    },
    userCanSelectOpenbaarDomein (): boolean {
      return this.userGetCapabilities?.kanOpenbaarDomeinAanvragen
    },
    ...mapStores(useKaartStore, useAlertStore),
    ...mapState(useUserStore, ['userGetCapabilities'])
  },
  methods: {
    formatDate,
    getToelichting (): string {
      return this.toelichting
    },
    getGeometrie (): string {
      return this.geometrie
    },
    voegHuidigZoneToeAanSelectie () {
      this.voegZoneToeAanSelectie(this.geometryKey, this.geometrie, ZoneType.added, 'c-' + this.geometryKey)
      this.zoomAllowed = false
      this.toelichting = ''
    },
    getStyle (zoneType?: ZoneType): Style {
      if (!zoneType) {
        zoneType = ZoneType.added
      }
      let fillColor = 'rgba(0, 85, 204, 0.2)'
      let strokeColor = 'rgba(0, 85, 204,0.6)'
      let strokeWidth = 1
      switch (zoneType) {
        case ZoneType.selected:
          fillColor = 'rgba(0, 85, 204, 0.8)'
          strokeColor = 'rgba(0, 85, 204, 1)'
          strokeWidth = 3
          break
        case ZoneType.inActive:
          fillColor = 'rgba(217, 0, 27, 0.2)'
          strokeColor = 'rgba(217, 0, 27, 0.6)'
          strokeWidth = 0.25
          break
        case ZoneType.active:
          fillColor = this.modMapBasic ? 'rgba(64, 191, 64, 0.15)' : 'rgba(64, 191, 64, 0)'
          strokeColor = this.modMapBasic ? 'rgba(64, 191, 64, 0.6)' : 'rgba(64, 191, 64, 0)'
          strokeWidth = 0.25
          break
        case ZoneType.hoverZone:
          fillColor = 'rgba(0, 0, 0, 0)'
          strokeColor = 'rgba(0, 0, 0, 0)'
          strokeWidth = 0
          break
      }
      const myfill = new Fill({ color: fillColor })
      const myStroke = new Stroke({ color: strokeColor, width: strokeWidth })
      return new Style({ fill: myfill, stroke: myStroke })
    },
    voegZoneToeAanSelectie (caPaKey: string, geometrie: string, zoneType?: ZoneType, zoneNaam?: string) {
      if (!zoneType) {
        zoneType = ZoneType.added
      }
      if (zoneType === ZoneType.selected) {
        caPaKey = 'selected'
      }
      const myStyle = this.getStyle(zoneType)
      this.zoneSelectie.push({
        geometryKey: caPaKey,
        zone: {
          style: myStyle,
          geometry: geometrie,
          meta: { id: zoneNaam, title: zoneNaam, ref: zoneType.toString() }
        }
      })
    },
    verwijderZoneVanSelectie (capaKey: string) {
      if (capaKey) {
        this.zoneSelectie = this.zoneSelectie.filter(zone => zone.geometryKey !== capaKey)
        if (this.caPaKeysNietAangeslotenGemeenten.includes(capaKey)) {
          this.caPaKeysNietAangeslotenGemeenten.splice(this.caPaKeysNietAangeslotenGemeenten.indexOf(capaKey), 1)
        }
        if (capaKey === this.geometryKey) {
          this.geometryKey = ''
          this.geometrie = ''
        }
        this.updateSize()
      }
    },
    moetGebouwEenhedenSelecteren (): boolean {
      if (this.$refs.gebouwEenhedenRef) {
        return this.$_gebouwEenHedenComponent().mustSelectGebouwEenheden
      } else {
        return false
      }
    },
    $_gebouwEenHedenComponent (): InstanceType<typeof VipGebouwEenheden> {
      return (this.$refs.gebouwEenhedenRef as InstanceType<typeof VipGebouwEenheden>)
    },
    geselecteerdeGebouwEenheden (): number[] {
      return this.$_gebouwEenHedenComponent().selectedGebouwEenheden
    },
    mapPerceelInformatie (capaKeyInfo: CaPaKeyInfoResponse) {
      this.toonGebouweenhedenSeperator = false
      if (capaKeyInfo.adresEenheden) {
        this.$_gebouwEenHedenComponent().mapGebouwEenheden(capaKeyInfo.adresEenheden)
      }
      if (this.$_gebouwEenHedenComponent().mustSelectGebouwEenheden && (capaKeyInfo.adresEenheden.length || this.adres)) {
        this.toonGebouweenhedenSeperator = true
      }
      this.checkDubbelDossier(capaKeyInfo.caPaKey)
      this.mapCaPaKeyResponse(capaKeyInfo)
    },
    checkDubbelDossier (caPaKey: string) {
      BffClientFactory().percelen_ControleerDubbelDossier(caPaKey)
        .then(res => {
          this.laatsteAanvraagDatum = res.datumMeestRecenteDossier
        })
        .catch(() => {
          this.laatsteAanvraagDatum = null
        })
    },
    percelen_GetPerceelByCoordinates (x: string, y: string, click?: boolean) {
      this.toonSidebar = true
      this.toonSpinner = true
      this.gebouwEenhedenComponentKey = generateKey()
      BffClientFactory().percelen_GetPerceelByCoordinates(x, y)
        .then(async (capaKeyResponse) => {
          this.toonSidebar = true
          this.mapPerceelInformatie(capaKeyResponse)
        })
        .catch((error) => {
          if (error.status === 404 && !this.modMapBasic) {
            this.sluitSidebar()
          } else {
            this.toonSpinner = false
            if (error && error.errors && error.errors.length > 0) {
              this.setSearchFeedback(error.errors[0].detail)
            } else if (click === undefined || click === false) {
              catchBffError(error, 'perceel-coordinates', true)
            } else if (click) {
              this.sluitSidebar()
            }
          }
        })
        .finally(() => { this.toonSpinner = false })
    },
    async click (event: {
      coordinate: number[]
    }) {
      if (!this.modMapBasic && this.canSelectPerceel) {
        if (this.hoveredCapaKey) {
          this.toonPerceel(this.hoveredCapaKey)
        } else {
          this.percelen_GetPerceelByCoordinates(event.coordinate[0].toString(), event.coordinate[1].toString(), true)
        }
      } else if (this.modMapBasic) {
        await getLocationExtentAndGemeente(event.coordinate)
          .then(async coordinateResult => {
            if (coordinateResult.gemeente !== null) {
              await getLocationExtentAndGemeente(null, coordinateResult.gemeente)
                .then(gemeenteResult => {
                  if (gemeenteResult.gemeente !== null) {
                    this.showPopup(gemeenteResult.extent, gemeenteResult.gemeente)
                  }
                })
            }
          })
      }
    },
    showPopup (extent: Extent, gemeente: string, zoom?: false) {
      const info = this.gemeenteInfo.find(f => f.gemeente === gemeente)
      if (zoom) {
        (this.$refs.map as IpproMap).zoomToExtent(extent)
      }
      if (info) {
        this.popupPosition = getCenter(extent)
        this.popupContentTitle = info.gemeente
        this.popupContentTitleTextInline = info.aangesloten ? ': aangesloten' : ': niet aangesloten'
        this.popupContentText = info.aangesloten ? null : { loket: info.aanvraagLoket, email: info.aanvraagEmail, extraInfo: info.gemeente === 'Antwerpen' ? 'Beschikbaar tot 27/12/2023' : '' }
        this.openPopup()
      }
    },
    openPopup () {
      this.popupVisible = true
    },
    closePopup () {
      this.popupVisible = false
    },
    async onGetLocationResult (location: {
      LocationType: string,
      Location: {
        X_Lambert72: number; // eslint-disable-line camelcase
        Y_Lambert72: number; // eslint-disable-line camelcase
      },
      Municipality: string
    }) {
      if (this.modMapBasic) {
        await getLocationExtentAndGemeente(null, location.Municipality).then(res => {
          setTimeout(() => (this.$refs.map as IpproMap).zoomToExtent(res.extent, 0), 500)
          this.showPopup(res.extent, res.gemeente)
        })
      } else if ((location?.LocationType.includes('huisnummer') || location?.LocationType === 'perceel') && (location.Location.X_Lambert72 && location.Location.Y_Lambert72)) {
        this.percelen_GetPerceelByCoordinates(location.Location.X_Lambert72.toString(), location.Location.Y_Lambert72.toString())
      }
    },
    sluitSidebar () {
      this.reset()
    },
    updateSize () {
      Vue.nextTick(() => {
        (this.$refs.map as IpproMap).updateSize()
      })
    },
    reset () {
      this.geometrie = ''
      this.toonSidebar = false
      this.toonGebouweenhedenSeperator = false
      this.geometryKey = ''
      this.adres = ''
      this.searchComponentKey = generateKey()
      this.gebouwEenhedenComponentKey = generateKey()
      this.gemeenteNietAangeslotenMelding = ''
      this.gemeenteNietAangeslotenExtra = null
      this.caPaKeysNietAangeslotenGemeenten = []
      this.openbaarDomeinActive = false
      this.openbaarDomeinSelected = false
      this.updateSize()
    },
    mapCaPaKeyResponse (response: CaPaKeyInfoResponse) {
      if (response.caPaKey === 'fout') {
        this.alertStore.addAlert({
          id: 'alert' + generateKey(),
          closable: true,
          method: AlertMethod.Page,
          title: 'Ophalen informatie voor gekozen perceel/CaPaKey is mislukt.',
          message: 'Probeer opnieuw of contacteer de helpdesk.',
          type: AlertType.Error
        })
        this.sluitSidebar()
      }
      if (response.caPaKey) {
        this.adres = response.hoofdAdres
        this.adresNietGekend = false
        if (response.adresEenheden.length === 0 && !response.hoofdAdres) {
          this.adresNietGekend = true
        } else if (response.adresEenheden.length === 1 && response.adresEenheden[0].adres) {
          this.adres = response.adresEenheden[0].adres
        } else if (response.adresEenheden.length > 1 && response.adresEenheden[0].adres) {
          this.adres = ''
          this.toonGebouweenhedenSeperator = false
        }
        this.geometryKey = response.caPaKey
        this.geometrie = response.geometrieAsWkt
        if (response.aangesloten === false) {
          let gemeente = response.gemeente
          if (!gemeente || gemeente === '') {
            const coordinate = getCenter((this.$refs.map as IpproMap).stringToWkt(response.geometrieAsWkt).getGeometry().getExtent())
            getLocationExtentAndGemeente(coordinate).then(res => {
              gemeente = res.gemeente
              if (!gemeente || gemeente === '') {
                gemeente = 'Gemeente'
              }
              this.setGemeenteNietAangeslotenMelding(gemeente)
            })
          } else {
            this.setGemeenteNietAangeslotenMelding(gemeente)
          }
          this.caPaKeysNietAangeslotenGemeenten.push(response.caPaKey)
        }
        if (this.zoomAllowed) {
          this.updateSize()
        } else if (this.geometrie) {
          // Bij openen sidebar wordt de map verplaatst en deel is onzichtbaar wegens overflow: hidden. Centreren kaart werkt hierdoor voor sommige percelen niet correct meer.
          // Aanpassen breedte kaart naar beschikbare ruimte voor toepassen centreren. Ratio voor kaart met sidebar is 1,2207 met open sidebar op een normaal breedbeeldscherm.
          const size = (this.$refs.map as IpproMap).map.olMap.getSize()
          const mapRatio = size[0] / size[1] !== 0 ? size[1] : 1
          let newSize = 0
          if (mapRatio >= 1.6) {
            newSize = (size[1] * 1.2207)
            size[0] = Math.round((newSize + Number.EPSILON) * 100) / 100
          }
          (this.$refs.map as IpproMap).zoomToFeature((this.$refs.map as IpproMap).stringToWkt(response.geometrieAsWkt), 500)
        }
      }
    },
    setGemeenteNietAangeslotenMelding (gemeente: string) {
      const info = this.gemeenteInfo.find(f => f.gemeente === gemeente)
      if (info) {
        this.gemeenteNietAangeslotenExtra = { loket: info.aanvraagLoket, email: info.aanvraagEmail }
      }
      this.gemeenteNietAangeslotenMelding = gemeente + ' is nog NIET aangesloten'
    },
    setSearchFeedback (feedback: string) {
      (this.$refs.mapSearch as IpproMapSearchExtended).setFeedback(feedback)
    },
    searchSubmit () {
      const keyword = (this.$refs.mapSearch as IpproMapSearch).location.address
      if (isCaPaKeyFormat(keyword)) {
        this.toonPerceel(keyword)
      }
    },
    toonPerceel (caPaKey: string) {
      if (isCaPaKeyFormat(caPaKey)) {
        this.toonSidebar = true
        this.toonSpinner = true
        BffClientFactory().percelen_GetPerceelByCaPaKey(caPaKey)
          .then(response => {
            this.mapPerceelInformatie(response)
          })
          .finally(() => {
            this.toonSpinner = false
            this.hoveredCapaKey = ''
          })
          .catch(error => {
            this.toonSpinner = false
            this.toonSidebar = false
            this.setSearchFeedback(error && error.errors && error.errors.length > 0 ? error.errors[0].detail : 'De CaPaKey kan niet gevonden worden')
          })
      }
    },
    canSendAanvraag (): boolean {
      return this.caPaKeysNietAangeslotenGemeenten?.length === 0
    },
    loadAansluitingStatus () {
      if (!this.gemeenteInfo) {
        BffClientFactory().gemeenteAansluitInfo_GetAansluitInfo()
          .then(response => {
            if (!response || !response.gemeenteAansluitInfoList) { return }
            this.kaartStore.kaartSetAangeslotenGemeenten(response.gemeenteAansluitInfoList)
            this.invullingAansluitStatusOpKaart()
          })
      } else {
        this.invullingAansluitStatusOpKaart()
      }
    },
    invullingAansluitStatusOpKaart () {
      if (!this.gemeenteInfo) { return }
      this.gemeenteInfo.forEach(gemeente => {
        this.voegZoneToeAanSelectie(gemeente.aangesloten ? 'active' + generateKey() : 'inactive' + generateKey(), gemeente.geometrieAsWkt,
          gemeente.aangesloten ? ZoneType.active : ZoneType.inActive, 'g-' + gemeente.gemeente)
      })
      this.zoomAllowed = false
    },
    zoomToFlanders () {
      const vipMap = (this.$refs.map as IpproMap)
      if (vipMap === undefined) {
        return
      }
      vipMap.zoomToFlandersExtent()
      const origZoom = vipMap.map.olMap.getView().getZoom()
      if (origZoom === undefined) {
        return
      }
      vipMap.map.olMap.getView()
        .animate({ zoom: origZoom - 0.25 })
    },
    zoomOut () {
      this.map
        .getView()
        .animate({ zoom: this.zoomLevel - 1 })
    },
    zoomIn () {
      if (this.modMapBasic && this.zoomLevel >= 7) {
        return
      }
      this.map
        .getView()
        .animate({ zoom: this.zoomLevel + 1 })
    },
    laadGrbPercelenData (view: Extent) {
      // Ophalen data uit ADPF register. Alternatief is GRB/ADP register, maar dit is te traag! +- 1s vs +- 6-7 seconden voor zelfde filters. Nadeel ADPF is dat het gaat om fiscale status op 1 januari van het huidige jaar. Dus mogelijks outdated. GRB wordt 1 maal per maand bijgewerkt, maar dus niet bruikbaar wegens te traag.
      if (this.canSelectPerceel) {
        // Filter op zichtbare regio op de kaart. Percelen buiten de zichtbare regio kunnen niet gehovered worden en dus niet nodig om deze op te halen.
        // Doel: beperken op te halen dataset.
        const url = 'https://geo.api.vlaanderen.be/GRB/ogc/features/collections/ADP/items?limit=2500&bbox=' + view.join(',') + '&filter-lang=cql-text&crs=EPSG%3A31370&bbox-crs=EPSG%3A31370'
        this.downloadGrbData(url)
      }
    },
    downloadGrbData (url: string) {
      axios.get(url).then(res => {
        const features = new GeoJSON().readFeatures(res.data)
        features.forEach(feature => {
          const capaKey = feature.getProperties().CAPAKEY
          this.voegZoneToeAanSelectie(capaKey, new WKT().writeFeature(feature), ZoneType.hoverZone, capaKey)
        })
        if (res.data.links && res.data.links.length > 0) {
          // Omwille van snelheid: beperking request return limit, recursief uitvoeren indien meer dan 2500 percelen in zichtbaar gebied.
          const nextUrl = res.data.links.find(f => f.rel === 'next')
          if (nextUrl) {
            this.downloadGrbData(nextUrl.href)
          }
        }
      })
    },
    setHoverActive (hoveredFeature: Feature) {
      if (!this.canSelectPerceel) { return }
      let hoverStyle = new Style({ fill: new Fill({ color: 'rgba(0, 85, 204, 0.35)' }), stroke: new Stroke({ color: 'rgba(0, 85, 204, 0.6)', width: 1.5 }) })
      const featureId = hoveredFeature.getId() as string
      if (!featureId.startsWith('g-')) {
        if (featureId.startsWith('s-')) {
          hoverStyle = new Style({ fill: new Fill({ color: 'rgba(0, 85, 204, 0.7)' }), stroke: new Stroke({ color: 'rgba(0, 85, 204, 1)', width: 4 }) })
        } else if (featureId.startsWith('c-')) {
          hoverStyle = new Style({ fill: new Fill({ color: 'rgba(0, 85, 204, 0.45)' }), stroke: new Stroke({ color: 'rgba(0, 85, 204, 1)', width: 3 }) })
        }
        hoveredFeature.setStyle(hoverStyle)
        this.hoveredFeature = hoveredFeature
        this.hoveredCapaKey = featureId // capakey al lokaal bewaren, zodat perceel niet opgehaald hoeft te worden op basis van de click coordinaten. Opzoekfunctie op coordinaten wordt wel nog behouden als fallback indien perceel nog niet opgenomen is in ADPF register.
      }
    },
    setHoverInactive (oldHoveredFeature: Feature) {
      if (!this.canSelectPerceel) { return }
      if (!oldHoveredFeature) { return }
      let clearStyle = new Style({ fill: new Fill({ color: 'rgba(0, 0, 0, 0)' }), stroke: new Stroke({ color: 'rgba(0, 0, 0, 0)', width: 1 }) })
      const featureId = oldHoveredFeature.getId() as string
      if (featureId.startsWith('s-')) {
        clearStyle = this.getStyle(ZoneType.selected)
      } else if (featureId.startsWith('c-')) {
        clearStyle = this.getStyle(ZoneType.added)
      }
      if (!featureId.startsWith('g-')) {
        if (oldHoveredFeature) {
          oldHoveredFeature.setStyle(clearStyle)
        }
      }
    },
    leaveMap () {
      this.setHoverInactive(this.hoveredFeature)
      this.hoveredFeature = null
    },
    toggleOpenbaarDomein () {
      this.openbaarDomeinActive = !this.openbaarDomeinActive
      this.toonSidebar = this.openbaarDomeinActive
      if (!this.openbaarDomeinActive) {
        this.openbaarDomeinSelected = false
      }
    },
    voegOpenbaarDomeinToe (geometrie: string) {
      this.geometryKey = 'openbaar-domein-' + this.randomKey(5)
      this.geometrie = geometrie;
      (this.$refs.map as IpproMap).zoomToFeature((this.$refs.map as IpproMap).stringToWkt(geometrie), 500)
      this.openbaarDomeinSelected = true
    },
    randomKey (chars: number): string {
      let result = ''
      for (let i = 0; i < chars; i++) {
        const randomCharCode = Math.floor(Math.random() * (127 - 33) + 33) // ASCII characters between 33 and 126
        result += String.fromCharCode(randomCharCode)
      }
      return result
    }
  },
  watch: {
    geometryKey: {
      handler () {
        this.$emit('input', this.geometryKey)
      }
    }
  },
  mounted () {
    this.map = (this.$refs.map as IpproMap).instance
    if (this.modMapBasic) {
      setTimeout(() => {
        this.zoomToFlanders()
      }, 100)
    }

    Vue.nextTick(() => {
      this.loadAansluitingStatus()
    })
  }
})
