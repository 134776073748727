import vipInlichtingAttributes from '../../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-stedenbouwkundige-overtreding-herstelmaatregel',
  data () {
    return {
      datum: this.getAttribute('datum'),
      omschrijving: this.getAttribute('omschrijving'),
      instantie: this.getAttribute('instantie'),
      type: this.getAttribute('type'),
      datumBeroep: this.getAttribute('datumBeroep'),
      beslissingBeroep: this.getAttribute('beslissingBeroep'),
      beroepAangetekend: this.getAttribute('beroepAangetekend')
    }
  }
})
