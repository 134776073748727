import { InlichtingAttribuut } from '@/infrastructure/bff-client/bff-client'
import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '../../vip-inlichting-attributes'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-intrekking',
  data () {
    return {
      datum: this.datumAttribuut ?? this.getAttribute('Intrekking_Datum'),
      motivering: this.motiveringAttribuut ?? this.getAttribute('Intrekking_Motivering'),
      partij: this.partijAttribuut ?? this.getAttribute('Intrekking_PartijType')
    }
  },
  props: {
    datumAttribuut: Object as ()=> InlichtingAttribuut,
    motiveringAttribuut: Object as ()=> InlichtingAttribuut,
    partijAttribuut: Object as ()=> InlichtingAttribuut
  },
  methods: {
    intrekkingPartijSelected (): boolean {
      return (this.partij.value !== null && this.partij.value !== '' && this.partij.value.toString().toLowerCase() !== 'onbekend')
    },
    showDatum (): boolean {
      return (this.intrekkingPartijSelected()) && this.datum != null
    },
    showMotivering (): boolean {
      return (this.intrekkingPartijSelected()) && this.motivering != null
    }
  }
})
