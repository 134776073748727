import { RouteDashboard } from '@/modules/dashboard-module/dashboard-routes'
import Vue from 'vue'

export default Vue.extend({
  name: 'error-module',
  computed: {
    error (): {title?: string, message?: string, button?: {icon?: string, text: string, cb: ()=> void}, buttonDashboard?: {icon?: string, text: string, cb: ()=> void}} {
      if (this.errorContent !== '') {
        return { title: 'Oeps, er liep iets mis', message: this.errorContent, button: { icon: 'text-undo', text: 'Opnieuw proberen', cb: this.reloadPage }, buttonDashboard: { icon: 'places-home', text: 'Terug naar de startpagina', cb: this.goToDashboard } }
      } else if (this.errorCode === 404) {
        return { title: 'Oeps, die pagina vonden we niet terug', message: 'De pagina die u zoekt, vonden we niet terug.', button: { icon: 'places-home', text: 'Terug naar de startpagina', cb: this.goToDashboard } }
      } else {
        return { title: 'Oeps, er liep iets mis', message: 'De pagina die u zoekt, kon niet geladen worden.', button: { icon: 'text-undo', text: 'Opnieuw proberen', cb: this.reloadPage }, buttonDashboard: { icon: 'places-home', text: 'Terug naar de startpagina', cb: this.goToDashboard } }
      }
    },
    message () {
      return 'De pagina die u zoekt, kon niet geladen worden.'
    }
  },
  methods: {
    goToDashboard () {
      this.$router.replace(RouteDashboard)
    },
    reloadPage () {
      window.location.reload()
    }
  },
  components: {},
  props: {
    errorCode: Number,
    errorContent: String
  }
})
