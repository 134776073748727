import vipInlichtingAttributes from '../../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-soort-weg-v2',
  data () {
    return {
      straatNaam: this.getAttribute('StraatNaam'),
      beherendeInstantie: this.getAttribute('BeherendeInstantie'),
      wegKlasse: this.getAttribute('WegKlasse'),
      wegCategorie: this.getAttribute('WegCategorie'),
      toegankelijkheid: this.getAttribute('Toegankelijkheid')
    }
  }
})
