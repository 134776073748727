import { Component, Vue, Prop } from 'vue-property-decorator'
import { IpproMapLayer } from '../../vl-ippro-map-layers'

@Component
export default class IpproMapLayerToggle extends Vue {
  @Prop({ type: Array, default: (): IpproMapLayer[] => [] })
  public layers!: IpproMapLayer[]

  public changeLayer (layerToggle: { $event: Event; active: string }) {
    const layer: IpproMapLayer | null =
      this.layers.find((obj: IpproMapLayer) => obj.id === layerToggle.active) ||
      null
    if (layer && layer) {
      this.$emit('switch-layer', layer)
    }
  }
}
