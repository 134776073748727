import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Map from 'ol/Map'
// eslint-disable-next-line import/no-named-default
import { default as View, ViewOptions } from 'ol/View'
import Projection from 'ol/proj/Projection'
import { addProjection, ProjectionLike } from 'ol/proj'
import MousePosition from 'ol/control/MousePosition'
import { createStringXY } from 'ol/coordinate'
import ScaleLine from 'ol/control/ScaleLine'
import IpproMapLayerToggle from './components/ippro-map-layer-toggle/ippro-map-layer-toggle.vue'
import IpproMapFullscreen from './components/ippro-map-fullscreen/ippro-map-fullscreen.vue'
import IpproMapPopup from './components/ippro-map-popup/ippro-map-popup.vue'
import {
  IpproMapLayer,
  projectionExtent,
  resolutions,
  registeredLayers,
  defaultStyle,
  IpproMapLayerSetting
} from './vl-ippro-map-layers'
import { Extent, boundingExtent, getCenter } from 'ol/extent'
import Feature, { FeatureLike } from 'ol/Feature'
import { VlTooltip } from '@govflanders/vl-ui-vue-components'
import VectorSource from 'ol/source/Vector'
import Style from 'ol/style/Style'
import VectorLayer from 'ol/layer/Vector'
import Geometry from 'ol/geom/Geometry'
import GeometryCollection from 'ol/geom/GeometryCollection'
import WKT from 'ol/format/WKT'
import Select from 'ol/interaction/Select'
import { click } from 'ol/events/condition'
import { Tile } from 'ol/layer'
import proj4 from 'proj4'
import { register } from 'ol/proj/proj4'

interface IpproViewOptions extends ViewOptions {
  constrainResolution?: boolean;
}

export interface EntityLayerStateLevelStyleParams {
  backgroundColor: string;
  borderColor: string;
  borderThickness: number;
}
export interface EntityLayerStateStyleParams {
  primary: EntityLayerStateLevelStyleParams;
  secondary: EntityLayerStateLevelStyleParams;
}
export interface IpproMapFeatureStyle {
  default: Style;
  hover?: Style;
  active?: Style;
}
export interface EntityStyleSettings {
  primary: string;
  mapStyle: {
    default: EntityLayerStateStyleParams;
    hover: EntityLayerStateStyleParams;
    active: EntityLayerStateStyleParams;
  };
}

export enum MapStyles {
  Primary = 'primary',
  Secondary = 'secondary'
}

export interface IpproMapZone {
  meta?: {
    id?: string;
    ref?: string;
    title?: any;
    description?: string;
  };
  visible?: boolean;
  geometry: string;
  style?: Style;
  styleType?: EntityStyleSettings;
  mapStyle?: MapStyles;
  linkedZone?: IpproMapZone;
}

// eslint-disable-next-line no-use-before-define
export interface IpproMapType extends IpproMap {
  olMap: Map;
}

proj4.defs(
  'EPSG:31370',
  '+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.868628,52.297783,-103.723893,0.336570,-0.456955,1.842183,-1.2747 +units=m +no_defs'
)
proj4.defs(
  'EPSG:3857',
  '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs'
)

proj4.defs('EPSG:3812', '+proj=lcc +lat_0=50.797815 +lon_0=4.35921583333333 +lat_1=49.8333333333333 +lat_2=51.1666666666667 +x_0=649328 +y_0=665262 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs')

proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs +type=crs')

register(proj4)

Vue.directive('vl-tooltip', VlTooltip)
@Component({
  name: 'IpproMap',
  components: {
    IpproMapLayerToggle,
    IpproMapFullscreen,
    IpproMapPopup
  }
})
export default class IpproMap extends Vue {
  public fullscreen = false
  public mapScaleId = `ippro-map-scale-${Math.random()
    .toString(36)
    .substring(7)}`

  public mapMousePositionId = `ippro-map-mouse-position-${Math.random()
    .toString(36)
    .substring(7)}`

  public source = new VectorSource()
  public vector = new VectorLayer({
    source: this.source,
    style: defaultStyle.default
  })

  public selectClick: Select = new Select({
    condition: click,
    multi: true,
    layers: [this.vector],
    style: null
  })

  public hoveredFeature: Feature = null
  public hoveredFeatureIndex: number = null
  public map: IpproMapType = null
  public tiledLayers: IpproMapLayer[] = []
  public inZoomAnimation = false

  public currentBackgroundColor: string = null

  public olMapId = `vl-ol-map-id-${Math.random()
    .toString(36)
    .substring(7)}`

  public refMap = `ippro-map-ref-${Math.random()
    .toString(36)
    .substring(7)}`

  public refLayerToggle = `ippro-map-ref-layer-toggle-${Math.random()
    .toString(36)
    .substring(7)}`

  @Prop({
    type: String,
    default: `ippro-map-${Math.random()
      .toString(36)
      .substring(7)}`
  })
  public id!: string

  @Prop({ type: Array, default: (): IpproMapZone[] => [] })
    value: IpproMapZone[]

  @Prop({ type: Boolean, default: true })
  public modFitToWkt!: boolean

  @Prop({ type: Number, default: 2 })
  public zoom!: number

  @Prop({ type: Number, default: 15 })
  public maxZoom!: number

  @Prop({ type: Number, default: 1 })
  public minZoom!: number

  @Prop({ type: Boolean, default: true })
  public constrainResolution!: boolean

  @Prop({ type: Boolean, default: true })
  public enableRotation!: boolean

  @Prop({ type: Array, default: (): number[] => [51.03675, 3.70811] })
  public center!: number[]

  @Prop({ type: Array, default: () => resolutions })
  public resolutions!: number[]

  @Prop({ type: String, default: 'grb_bsk' })
  public defaultLayerId!: string

  @Prop({ type: String, default: '#e8ebee' })
  public backgroundColor: string

  public currentLayerId: string = this.defaultLayerId

  @Prop({ type: Boolean, default: true })
  public modZoom!: boolean

  @Prop({ type: Boolean, default: false })
  public modToggleFullscreen!: boolean

  @Prop({ type: Boolean, default: false })
  public modFullscreen!: boolean

  @Prop({ type: Boolean, default: false })
  public modDisableScrollZoom!: boolean

  @Prop({ type: Boolean, default: false })
  public modAbsoluteZoom!: boolean

  @Prop({ type: Boolean, default: true })
  public modZoomToFlandersOnInit!: boolean

  @Prop({ type: Boolean, default: false })
  public isLoading!: boolean

  @Prop({ type: String, default: 'De kaart is aan het laden' })
  public loadingMessage!: string

  @Prop({ type: Array, default: (): string[] => [] })
  public modLayers!: string[]

  @Prop({ type: Array, default: (): string[] => [] })
  public layerSettings!: IpproMapLayerSetting[]

  @Prop({
    type: [Object, String],
    default: (): ProjectionLike => {
      const lambertProjection = new Projection({
        code: 'EPSG:31370',
        extent: projectionExtent,
        units: 'm',
        getPointResolution: (resolution) => {
          return (resolution / 6) * 5
        }
      })
      addProjection(lambertProjection)
      return lambertProjection
    }
  })
  public projection!: ProjectionLike

  get instance () {
    if (this.map && this.map.olMap) {
      return this.map.olMap
    }
    return null
  }

  get classes () {
    return [
      'ippro-map',
      {
        'ippro-map--fullscreen': this.fullscreen
      }
    ]
  }

  get zoomLevel () {
    return this.map.olMap.getView().getZoom()
  }

  get layers (): IpproMapLayer[] {
    return this.tiledLayers
      .filter(
        (layer: IpproMapLayer) =>
          layer.id === this.defaultLayerId || this.modLayers.includes(layer.id)
      )
      .map((layer: IpproMapLayer) => ({
        ...layer,
        active: layer.id === this.currentLayerId
      }))
  }

  get defaultLayer (): IpproMapLayer {
    if (this.layers && this.defaultLayerId) {
      return this.layers.filter(layer => layer.id === this.defaultLayerId)[0]
    }
    return null
  }

  get features (): Feature[] {
    return this.source ? this.source.getFeatures() : null
  }

  get mapStyle (): any {
    let color: string = this.currentBackgroundColor
    if (!color) {
      color = this.backgroundColor
    }
    if (!color) {
      color = '#e8ebee'
    }

    return {
      backgroundColor: color
    }
  }

  public setLayer (layer: IpproMapLayer) {
    if (this.layerSettings.length) {
      const layerSettings = this.layerSettings.find(
        layerSetting => layerSetting.layerId === layer.id
      )
      if (layerSettings.opacity) {
        layer.tile.setOpacity(layerSettings.opacity)
      }
      this.currentBackgroundColor = layerSettings.backgroundColor
        ? layerSettings.backgroundColor
        : null
    }
    this.map.olMap.getLayers().setAt(0, layer.tile)
  }

  public toggleFullscreen (fullscreen: boolean) {
    if (this.fullscreen !== fullscreen) {
      this.$emit('toggle-full-screen', fullscreen)
    }
    this.fullscreen = fullscreen
    Vue.nextTick(() => {
      this.map.olMap.updateSize()
    })
  }

  public zoomIn () {
    if (!this.inZoomAnimation || !this.modAbsoluteZoom) {
      this.inZoomAnimation = true
      this.map.olMap.getView().animate(
        {
          zoom: this.map.olMap.getView().getZoom() + 1
        },
        () => {
          this.inZoomAnimation = false
        }
      )
    }
  }

  public zoomOut () {
    if (!this.inZoomAnimation || !this.modAbsoluteZoom) {
      this.inZoomAnimation = true
      this.map.olMap.getView().animate(
        {
          zoom: this.map.olMap.getView().getZoom() - 1
        },
        () => {
          this.inZoomAnimation = false
        }
      )
    }
  }

  public zoomToFeature (feature: Feature, duration: number) {
    this.zoomToExtent(feature.getGeometry().getExtent(), duration)
  }

  public zoomToCoordinates (
    coordinates: number[],
    duration?: number,
    zoom?: number
  ) {
    if (this.map && this.map.olMap) {
      this.map.olMap.getView().animate({
        center: coordinates,
        zoom: zoom || 12,
        duration: duration || 500
      })
    }
  }

  public zoomToExtent (extent?: Extent, duration?: number) {
    if (this.map && this.map.olMap) {
      this.map.olMap.getView().fit(extent, {
        size: this.map.olMap.getSize(),
        duration: duration || 500
      })
    }
  }

  public zoomToFlandersExtent () {
    const flandersExtent: Extent = boundingExtent([
      [22000, 153000],
      [259000, 245000]
    ])
    const flandersCenter = getCenter(flandersExtent)
    this.map.olMap.getView().setZoom(this.zoom)
    this.map.olMap.getView().setCenter(flandersCenter)
  }

  zoomToZone (duration = 500, source?: VectorSource) {
    const extent = source ? source.getExtent() : this.source.getExtent()
    if (extent.filter(isFinite).length === 4) {
      this.zoomToExtent(extent, duration)
    }
  }

  public showMousePosition () {
    if (this.map && this.map.olMap) {
      const mousePositionControl = new MousePosition({
        coordinateFormat: createStringXY(2),
        target: this.mapMousePositionId
          ? (document.getElementById(this.mapMousePositionId) as HTMLElement)
          : undefined,
        undefinedHTML: ' '
      })
      this.map.olMap.addControl(mousePositionControl)
    }
  }

  public updateSize () {
    this.map.olMap.updateSize()
  }

  public showScale () {
    if (this.map && this.map.olMap) {
      const scaleLineControl = new ScaleLine({
        units: 'metric',
        target: this.mapScaleId
          ? (document.getElementById(this.mapScaleId) as HTMLElement)
          : undefined
      })
      this.map.olMap.addControl(scaleLineControl)
    }
  }

  public showMapInfo () {
    this.showMousePosition()
    this.showScale()
  }

  public stringToWkt (string: string) {
    return new WKT().readFeature(string)
  }

  public addFeature (
    feature: Feature,
    style: Style,
    source: VectorSource,
    id: string | number | undefined = undefined
  ) {
    const featureGeometry = feature.getGeometry()
    const geometries: Geometry[] =
      featureGeometry instanceof GeometryCollection
        ? (featureGeometry as GeometryCollection).getGeometries()
        : [featureGeometry]
    geometries.forEach((geometry: Geometry) => {
      const feature: Feature = new Feature({
        geometry
      })
      if (style) {
        feature.setStyle(style)
      }
      if (id) {
        feature.setId(id)
      }
      source.addFeature(feature)
    })
    this.$emit('feature-added', feature)
    if (this.modFitToWkt) {
      Vue.nextTick(() => {
        this.zoomToZone(10)
      })
    }
  }

  public removeFeature (feature: Feature, source: VectorSource) {
    source.removeFeature(feature)
  }

  public initMap () {
    this.map = this.$refs[this.refMap] as IpproMapType
    this.tiledLayers = registeredLayers.map(layer => ({
      ...layer,
      tile: new Tile(layer.tileTemplate)
    }))

    if (this.map && this.map.olMap) {
      this.map.olMap.setView(
        new View({
          projection: this.projection,
          zoom: this.zoom,
          center: this.center,
          minZoom: this.minZoom,
          maxZoom: this.maxZoom,
          resolutions: this.resolutions,
          constrainResolution: this.constrainResolution,
          enableRotation: this.enableRotation
        } as IpproViewOptions)
      )
      if (this.defaultLayer) {
        this.setLayer(this.defaultLayer)
      }
      this.map.olMap.addLayer(this.vector)
      this.setFeatures()
      this.map.olMap.on('pointerdrag', () => {
        if (document.activeElement !== this.map.$el) {
          (this.map.$el as HTMLElement).focus()
        }
      })
      this.map.olMap.addInteraction(this.selectClick)
      this.selectClick.on('select', e => {
        this.$emit('features-selected', e.target.getFeatures())
      })
      this.map.olMap.on('click', e => {
        this.$emit('click', e)
        const clickedFeatures: Feature[] = []
        this.map.olMap.forEachFeatureAtPixel(e.pixel, feature => {
          clickedFeatures.push(feature as Feature)
        })
        if (clickedFeatures.length) {
          this.$emit('features-clicked', clickedFeatures, e)
        }
      })
      this.map.olMap.on('pointermove', e => {
        const features = this.map.olMap.forEachFeatureAtPixel(
          e.pixel,
          (feature: FeatureLike) => {
            this.hoveredFeature = feature as Feature
            return true
          }
        )
        if (!features) {
          this.hoveredFeature = null
        }
      })
      this.map.olMap.on('moveend', () => {
        this.$emit(
          'view-updated',
          this.map.olMap.getView().calculateExtent(this.map.olMap.getSize())
        )
      })
    }
  }

  @Watch('hoveredFeature', { immediate: false, deep: true })
  public onHoveredFeatureChanged (
    hoveredFeature: Feature,
    oldHoveredFeature: Feature
  ) {
    if (hoveredFeature !== oldHoveredFeature) {
      if (hoveredFeature) {
        this.$emit('mouseover', hoveredFeature)
      }
      if (oldHoveredFeature) {
        this.$emit('mouseout', oldHoveredFeature)
      }
    }
  }

  setFeatures () {
    if (this.value && this.value.length) {
      this.value.forEach(zone => {
        try {
          this.addFeature(
            this.stringToWkt(zone.geometry as string),
            zone.style,
            this.source,
            zone.meta ? zone.meta.id : undefined
          )
        } catch {
          this.$emit('read-wkt-failed', zone.geometry)
        }
      })
    }
  }

  @Watch('value', { immediate: false, deep: true })
  public onValueChanged () {
    this.source.clear()
    this.setFeatures()
  }

  public mounted () {
    this.initMap()
    if (this.modZoomToFlandersOnInit) {
      this.zoomToFlandersExtent()
    }
    this.showMapInfo()
    this.toggleFullscreen(this.modFullscreen)
  }
}
