import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-verbod-permanent-wonen',
  data () {
    return {
      beslistDoor: this.getAttribute('Instantie'),
      datumBeslissing: this.getAttribute('Datum'),
      externeDocumentatie: this.getAttribute('ExterneDocumentatie')
    }
  }
})
