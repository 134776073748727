import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-gemeentelijk-rooilijnplan',
  data () {
    return {
      plannaam: this.getAttribute('plannaam'),
      planreferentie: this.getAttribute('planreferentie'),
      oorsprongRooilijn: this.getAttribute('oorsprongrooilijn'),
      planstatus: this.getAttribute('planstatus'),
      datumStatuswijziging: this.getAttribute('datumstatuswijziging'),
      indicatieveInterpretatie: this.getAttribute('indicatieveInterpretatie'),
      link: this.getAttribute('link'),
      externeDocumentatie: this.getAttribute('externedocumentatie'),
      bijlage: this.getAttribute('bijlagen')
    }
  },
  computed: {
    showDatumStatusWijziging: {
      get: function () {
        return this.getAttribute('planstatus').value && this.getAttribute('planstatus').value !== ''
      }
    }
  }
})
