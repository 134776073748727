import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '@/components/vip-inlichting/components/vip-inlichting-attributes/vip-inlichting-attributes'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-stedenbouwkundige-handeling',
  data () {
    return {
      types: this.getAttribute('types'),
      aardAanvragen: this.getAttribute('types').options.map(o => o.label),
      omschrijving: this.getAttribute('omschrijving'),
      vervalDatum: this.getAttribute('Vervaldatum'),
      vervalMotivering: this.getAttribute('VervalMotivering'),
      vervalStatus: this.getAttribute('VervalStatus'),
      melding: this.getAttribute('melding')
    }
  },
  methods: {
    addAardVanAanvraag (aardVanAanvraag) {
      const tag = {
        label: aardVanAanvraag,
        value: aardVanAanvraag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.types.options.push(tag)
      this.types.value.push(tag.label)
    }
  }
})
