import Vue from 'vue'
import { generateKey } from '@/infrastructure/helpers/general'
import { BffClientFactory } from '@/infrastructure/bff-client/bff-client-handler'
import { useUserStore } from '@/infrastructure/stores/user-store'
import { mapState } from 'pinia'

export default Vue.extend({
  name: 'vip-zoek-gemeente',
  props: {
    showLoginOption: Boolean,
    showWebloket: Boolean
  },
  data () {
    return {
      searchComponentKey: generateKey(),
      gemeenteWebloket: '',
      gemeenteEmail: '',
      gemeenteAangesloten: '',
      gemeenteNietAangesloten: '',
      gemeenteAlgemeen: '',
      loggedIn: false,
      extraInfo: ''
    }
  },
  computed: {
    ...mapState(useUserStore, ['userModuleGetUserInfo'])
  },
  methods: {
    onGetLocationResult (location: { Municipality: string }) {
      this.gemeenteAangesloten = ''
      this.gemeenteNietAangesloten = ''
      this.gemeenteAlgemeen = ''
      BffClientFactory().gemeenteAansluitInfo_GetAansluitInfoVoorGemeente(location.Municipality.toString(), true)
        .then(response => {
          const aansluitGegevens = response.gemeenteAansluitInfoList[0]
          this.gemeenteWebloket = aansluitGegevens.aanvraagLoket
          this.gemeenteEmail = aansluitGegevens.aanvraagEmail
          if (this.showLoginOption) {
            if (aansluitGegevens.aangesloten) {
              this.gemeenteAangesloten = aansluitGegevens.gemeente + ' is aangesloten op het Vastgoedinformatieplatform.'
            } else {
              this.gemeenteNietAangesloten = aansluitGegevens.gemeente + ' is nog niet aangesloten.'
            }
          } else {
            this.gemeenteAlgemeen = aansluitGegevens.gemeente
          }
          if (this.gemeenteAlgemeen === 'Antwerpen') {
            this.extraInfo = 'Beschikbaar tot 27/12/2023'
          }
        })
    },
    login () {
      if (this.loggedIn) {
        this.$router.push('/')
      } else {
        const expireDate = new Date()
        expireDate.setMonth(expireDate.getMonth() + 24)
        this.$cookies.set('Vip.Web.LandingPageLoaded', '', expireDate)
        window.location.href = config.VUE_APP_BASE_URI + '/login'
      }
    }
  },
  mounted () {
    Vue.nextTick(() => {
      const userInfo = this.userModuleGetUserInfo
      if (userInfo && userInfo?.userId) {
        this.loggedIn = true
      }
    })
  }
})
