import Vue from 'vue'

export default Vue.extend({
  name: 'vip-number-input',
  props: {
    modError: Boolean,
    id: String,
    value: {
      default: '',
      type: [String, Number]
    }
  },
  methods: {
    keyPressHandler (e: KeyboardEvent) {
      // Alleen numerieke karakers zijn toegestaan.
      if (/^\d+$/.test(e.key)) {
        return true
      } else {
        e.preventDefault()
      }

      this.$emit('input', (this.$refs.VlNumberInput as HTMLInputElement).value)
    },
    checkNumberInput (e: ClipboardEvent) {
      const pasted = e.clipboardData.getData('text')
      if (!/^\d+$/.test(pasted)) {
        e.preventDefault()
      }
      this.$emit('input', (this.$refs.VlNumberInput as HTMLInputElement).value)
    },
    clickHandler () {
      const value = +(this.$refs.VlNumberInput as HTMLInputElement).value
      if (value < 0) {
        (this.$refs.VlNumberInput as HTMLInputElement).value = '0'
      }
      this.$emit('input', (this.$refs.VlNumberInput as HTMLInputElement).value)
    },
    inputHandler ($event) {
      this.$emit('input', $event.target.value)
    }
  }
})
