import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '@/components/vip-inlichting/components/vip-inlichting-attributes/vip-inlichting-attributes'
import { InlichtingAttribuut } from '@/infrastructure/bff-client/bff-client'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-beroep',
  data () {
    return {
      status_options: [],
      status_value: null,
      instantie: this.getAttribute('beroeps_instantie'),
      status: this.getAttribute('beroep_status'),
      showStatus: true,
      beslist_op: this.getAttribute('beroep_beslist_op')
    }
  },
  methods: {
    fillStatusDropdown (instantie: string) {
      if (instantie) {
        const selectedInstantieOption = this.instantie.options.find(option => option.value === instantie)
        this.status_options = selectedInstantieOption ? selectedInstantieOption?.options : []
      }
      this.status_value = null
      this.showStatus = true
    }
  },
  watch: {
    'instantie.value': {
      handler (selectedInstantie) {
        this.fillStatusDropdown(selectedInstantie)
      }
    },
    status_value: {
      handler (status) {
        this.status.value = status
      }
    }
  },
  computed: {
    beslistOp: {
      get: function () {
        return [this.getAttribute('beroep_beslist_op').value]
      },
      set: function (date: string) {
        this.getAttribute('beroep_beslist_op').value = new Date(Date.parse(date))
      }
    }
  },
  created () {
    if (!this.instantie?.options?.length) {
      this.instantie.options = (this.inlichting.attributen as InlichtingAttribuut[] || []).find(attribute => attribute.name === 'beroeps_instantie').options
    }
    if (this.instantie.value) {
      this.fillStatusDropdown(this.instantie.value)
    }
    if (this.status.value) {
      this.status_value = this.status.value
    }
  }
})
