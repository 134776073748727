import { BaseTitle, IRouteConfig } from '@/infrastructure/route/vip-router'

export const RouteLanding: IRouteConfig = {
  path: '/info',
  name: 'info',
  meta: {
    title: BaseTitle
  },
  component: () => import('./landing-page.vue')
}

export default [RouteLanding] as IRouteConfig[]
