import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-default',
  data () {
    return {
      bestemmingSuggesties: this.getAttribute('bestemmingen')?.options?.map(o => o.label),
      plannaam: this.getAttribute('plannaam'),
      planreferentie: this.getAttribute('planreferentie'),
      planstatus: this.getAttribute('planstatus'),
      interpreteerbaarWegensSchaal: this.getAttribute('interpreteerbaar_wegens_schaal'),
      link: this.getAttribute('link'),
      bijlage: this.getAttribute('bijlagen')
    }
  },
  watch: {
    bestemmingValue: function (val) {
      this.getAttribute('bestemmingen').value = [val]
    },
    'planstatus.value': {
      handler (planStatus: string) {
        if (this.getAttribute('bestemmingen') === undefined || this.getAttribute('bestemmingen') === null) {
          return
        }
        const planStatusToLower = planStatus.toLowerCase()
        this.getAttribute('bestemmingen').required = planStatusToLower === 'definitievevaststelling' || planStatusToLower === 'besluittotgoedkeuring'
      },
      immediate: true
    }
  },
  computed: {

    datumStatusWijzigingAttribuut: {
      get: function () {
        return this.getAttribute('datum_statuswijziging')
      }
    },
    bestemming: {
      get: function () {
        return this.getAttribute('bestemmingen')
      }
    },
    verordeningsType: {
      get: function () {
        return this.getAttribute('verordenings_type')
      }
    },
    bestemmingOptions: {
      get: function () {
        return this.getAttribute('bestemmingen').options.map(option => option.value)
      }
    }
  },
  methods: {
    addBestemmingSuggestie (bestemming) {
      const tag = {
        label: bestemming,
        value: bestemming.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.bestemming.options.push(tag)
      if (!this.bestemming.value) {
        this.bestemming.value = []
      }
      this.bestemming.value.push(tag.label)
    }
  }
})
