import { BaseTitle, IRouteConfig } from '@/infrastructure/route/vip-router'
import { Informatieaanvrager } from '@/infrastructure/constants/roles'
import { RouteAanvraagDetail } from '@/modules/aanvraag-detail/aanvraag-detail-routes'

export const RouteAanvraagBetalingVerwerkt: IRouteConfig = {
  path: '/aanvragen/:aanvraagId/betaling-status',
  name: 'aanvraag-betaling-verwerkt',
  meta: {
    title: `Aanvraag - Betaling Verwerkt | ${BaseTitle}`,
    authorize: {
      roles: [Informatieaanvrager]
    },
    breadcrumb: {
      title: 'Betaling verwerking',
      parent: RouteAanvraagDetail
    }
  },
  component: () => import('./aanvraag-betaling-verwerkt.vue')
}

export default [RouteAanvraagBetalingVerwerkt] as IRouteConfig[]
