import vipInlichtingPreview from '../../vip-inlichting-preview'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingPreview).extend({
  name: 'vip-inlichting-preview-template-overstromingsgebied-oeverzone',
  methods: {
    getGebiedType (): string {
      const typeAbbr = (this.previewData.detail.beschrijving as string).substring(0, 2)
      let gebiedType = 'Overstromingsgebied of oeverzone'
      switch (typeAbbr.toUpperCase()) {
        case 'OZ':
          gebiedType = 'Oeverzone'
          break
        case 'OG':
          gebiedType = 'Overstromingsgebied'
          break
      }
      return gebiedType
    }
  }
})
