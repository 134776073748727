import { InlichtingComponentData } from '@/components/vip-inlichting/vip-inlichting'
import { DossierDetail, DossierStatus, Kardinaliteit, TypeInlichting } from '@/infrastructure/bff-client/bff-client'
import { useUserStore } from '@/infrastructure/stores/user-store'
import { mapState, mapActions } from 'pinia'
import Vue from 'vue'
import { BffClientFactory } from '@/infrastructure/bff-client/bff-client-handler'
import { useDossierStore } from '@/infrastructure/stores/dossier-store'

export default Vue.extend({
  name: 'vip-inlichting-preview-wrapper',
  data () {
    return {
      isValidated: false,
      isVerplicht: false
    }
  },
  props: {
    previewComponent: String,
    inlichting: Object as ()=> InlichtingComponentData,
    dossier: Object as ()=> DossierDetail
  },
  computed: {
    ...mapState(useUserStore, ['userCanEditDossier', 'userIsAdmin']),
    userCanEdit (): boolean {
      return this.userCanEditDossier
    },
    userIsOperationeelBeheerder (): boolean {
      return this.userIsAdmin
    }
  },
  methods: {
    ...mapActions(useDossierStore, ['dossierSetInlichtingLastChangedId']),
    openInteractieveKaart () {
      window.open(this.inlichting.interactieveKaart, '_blank')
    },
    herbevraagInlichtingType (inlichtingType: TypeInlichting) {
      BffClientFactory().dossierInlichtingen_HerbevraagInlichtingType(this.dossier.dossierId, inlichtingType)
        .then(() => {
          this.$emit('herbevraagd')
        })
    }
  },
  mounted () {
    this.isValidated = this.dossier.dossierStatus === DossierStatus.Gevalideerd
    this.isVerplicht = this.inlichting.inlichtingTypeCode.kardinaliteit === Kardinaliteit.OneOrMore
  }
})
