import _Vue from 'vue'

import { IpproMap } from '@/components/ippro/vl-ippro-map'
import { IpproMapSearchExtended } from '@/components/ippro/vl-ippro-map/components/ippro-map-search-extended'
import { IpproMapZoomToFeature } from '@/components/ippro/vl-ippro-map/components/ippro-map-zoom-to-feature'
import { IpproMapPopup } from '@/components/ippro/vl-ippro-map/components/ippro-map-popup'
import { IpproDatatable } from '@/components/ippro/vl-ippro-datatable'
import { IpproAutocomplete } from '@/components/ippro/vl-ippro-autocomplete'

export default {
  install (Vue: typeof _Vue): void {
    Vue.component('ippro-map', IpproMap)
    Vue.component('ippro-map-search-extended', IpproMapSearchExtended)
    Vue.component('ippro-map-zoom-to-feature', IpproMapZoomToFeature)
    Vue.component('ippro-map-popup', IpproMapPopup)
    Vue.component('ippro-datatable', IpproDatatable)
    Vue.component('ippro-autocomplete', IpproAutocomplete)
  }
}

export { IpproMap }
export { IpproMapSearchExtended }
export { IpproMapZoomToFeature }
export { IpproMapPopup }
export { IpproAutocomplete }
