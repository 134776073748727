import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-gemeentelijk-onteigeningsplan',
  data () {
    return {
      plannaam: this.getAttribute('beschrijving'),
      planreferentie: this.getAttribute('referentie'),
      planstatus: this.getAttribute('status'),
      onteigeningStatus: this.getAttribute('onteigeningStatus'),
      datumStatusWijziging: this.getAttribute('datum'),
      link: this.getAttribute('externedocumentatie'),
      bijlage: this.getAttribute('bijlagen')
    }
  }
})
