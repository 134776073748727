import _Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

export default {
  install (Vue: typeof _Vue): void {
    const requireComponent = require.context(
      '@/',
      true,
      // Regex is aangepast om ook namen toe te laten die cijfers bevatten bv.:
      // vip-inlichting-preview-template-overstromingsgevoeligheid-v2.vue
      /vip-.*.vue$/
    )
    requireComponent.keys().forEach(componentLocation => {
      loadComponent(
        Vue,
        requireComponent(componentLocation),
        componentLocation
      )
    })
  }
}

function loadComponent (
  Vue: typeof _Vue,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any,
  componentLocation: string
) {
  const locationMatch = componentLocation.match(/([^/]+)(?=\.\w+$)/)
  if (locationMatch && locationMatch.length > 0) {
    const componentName = upperFirst(camelCase(locationMatch[1]))
    Vue.component(componentName, component.default || component)
  }
}
