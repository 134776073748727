import Vue from 'vue'

export default Vue.extend({
  name: 'vip-date-picker',
  props: {
    placeholder: {
      default: 'dd/mm/jjjj',
      type: String
    },
    visualFormat: {
      default: 'd/m/Y',
      type: String
    },
    modError: Boolean,
    id: String
  },
  methods: {
    // Werkt momenteel enkel voor dd/mm/yyyy en UTC datums.
    // Gedrag manueel ingegeven datums:
    // 25 => 25 januari + huidig jaar  (= gebaseerd op Vl component)
    // 25/02 => 25 februari + huidig jaar (= gebaseerd op Vl component)
    // 25/02/19 => 25 februari + huidig jaar (= gebaseerd op Vl component)
    // 25/02/2000 => 25 februari 2000
    // 31122021 => 31 december 2021
    // Andere datums zijn niet ondersteund omdat dit ook in de Vl component rare resultaten geeft.
    customDateParser (date: string) {
      if (
        date.length === 0 ||
        (!(date.includes('/') || date.includes('-')) && date.length < 8)
      ) {
        return null
      }

      if (date.includes('T') || date.includes('Z')) {
        // Date is a UTC date string
        return new Date(Date.parse(date))
      }

      if (date.includes('/')) {
        // Voorbeeld ingegeven datum: 31/12/2012
        return this.$_parseDatumMetSeperator(date, '/')
      } else if (date.includes('-')) {
        // Voorbeeld ingegeven datum: 31-12-2012
        return this.$_parseDatumMetSeperator(date, '-')
      } else if (date.length === 8) {
        // Voorbeeld ingegeven datum: 31122012
        return new Date(date.substring(4) + '/' + date.substring(2, 4) + '/' + date.substring(0, 2))
      }
    },
    $_parseDatumMetSeperator  (date: string, seperator: string) {
      const dateParts = date.split(seperator)
      let year = new Date().getFullYear()
      let month = 1
      let day = 31

      switch (dateParts.length) {
        case 1:
          day = Number.parseInt(dateParts[0])
          break
        case 2:
          day = Number.parseInt(dateParts[0])
          month = (dateParts[1]) ? Number.parseInt(dateParts[1]) : month
          break
        case 3:
          day = Number.parseInt(dateParts[0])
          month = (dateParts[1]) ? Number.parseInt(dateParts[1]) : 1
          year = (dateParts[2] && dateParts[2].length === 4) ? Number.parseInt(dateParts[2]) : year
          break
      }

      return new Date(year, month - 1, day)
    }
  },
  data () {
    return {
      flatpickr: null,
      vlDatePicker: null
    }
  },
  watch: {
    '$attrs.value': {
      handler (newValue) {
        this.flatpickr.setDate(newValue, true)
      }
    }
  },
  mounted () {
    this.vlDatePicker = this.$refs.VlDatepicker as Vue
    this.flatpickr = this.vlDatePicker.$data.flatpickr
    this.flatpickr.setDate(this.$attrs.value, true)
    this.$el.querySelector("input:not([type='hidden'])").id = this.id
  }
})
