import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import IpproAutocomplete from '../../../vl-ippro-autocomplete'
import {
  AutoCompleteResult,
  AutoCompleteSelection
} from '../../../vl-ippro-autocomplete/ippro-autocomplete'
import axios from 'axios'
import { Extent, boundingExtent } from 'ol/extent'
import { IpproMapType } from '../../../vl-ippro-map/vl-ippro-map'

export interface IMapLocation {
  address: string;
}

export interface LocationResponse {
  Thoroughfarename: string;
  Housenumber: string;
  Municipality: string;
  FormattedAddress: string;
  BoundingBox: {
    LowerLeft: {
      Lat_WGS84: number;
      Lon_WGS84: number;
      X_Lambert72: number;
      Y_Lambert72: number;
    };
    UpperRight: {
      Lat_WGS84: number;
      Lon_WGS84: number;
      X_Lambert72: number;
      Y_Lambert72: number;
    };
  };
}

@Component({
  components: {
    IpproAutocomplete
  }
})
export default class IpproMapSearchExtended extends Vue {
  @Ref() readonly autocomplete!: IpproAutocomplete

  @Prop({ required: false, default: () => ({}), type: Object })
    value!: IMapLocation

  @Prop({
    required: false,
    default: 'https://geo.api.vlaanderen.be/geolocation/Suggestion?q=:keyword&c=5'
  })
    apiUrl!: string

  @Prop({
    required: false,
    default: 'Adres...'
  })
    placeholder!: string

  @Prop({
    required: false,
    default: 'https://geo.api.vlaanderen.be/geolocation/Location?q=:keyword&c=1'
  })
    locationUrl!: string

  public map: IpproMapType = null
  public data: AutoCompleteResult[] = []
  public minLength = 3
  public fetching = false
  public feedback = ''
  public location: IMapLocation = this.value || {
    address: ''
  }

  public onInput () {
    this.$emit('input', this.value)
    if (this.location.address.length > this.minLength) {
      this.fetchAddress(this.location.address)
    } else {
      this.resetFeedback()
      this.data = []
    }
  }

  public setFeedback (feedback: string) {
    this.feedback = feedback
  }

  public resetFeedback () {
    this.feedback = ''
  }

  public onSelect (item: AutoCompleteSelection) {
    this.location.address = item.name
    this.$emit('change', this.location)
    this.zoomToAddress()
  }

  public focusSearch () {
    (((this.$refs.autocomplete as IpproAutocomplete).$refs.inputField as Vue)
      .$el as HTMLInputElement).focus()
  }

  public onEnter (e: Event) {
    this.$emit('submit', e)
    e.preventDefault();
    (this.$refs.autocomplete as any).selectFocussedResult()
  }

  public fetchAddress (input: string) {
    if (this.apiUrl) {
      this.fetching = true
      const apiUrl = this.apiUrl.replace(':keyword', input.trim())
      axios
        .get(apiUrl)
        .then(response => {
          if (response && response.data && response.data.SuggestionResult) {
            this.data = response.data.SuggestionResult.map(
              (item: string) => ({
                title: item,
                value: {
                  name: item
                }
              })
            )
            this.setFeedback(
              !this.data.length ? 'Er zijn geen resultaten gevonden' : ''
            )
          }
        })
        .finally(() => {
          this.fetching = false
        })
    }
  }

  initMap () {
    // see: https://rangle.io/blog/how-to-use-typescript-type-guards/ ==> created custom type guard.
    const isIpproMap = (
      variableToCheck: any
    ): variableToCheck is IpproMapType =>
      (variableToCheck as IpproMapType).mapScaleId !== undefined

    if (!this.map) {
      let parent = this.$parent
      while (parent && !this.map) {
        if (isIpproMap(parent)) {
          this.map = parent as IpproMapType
        }
        parent = parent.$parent
      }
    }
  }

  public zoomToAddress () {
    if (!this.location.address) {
      return
    }
    this.fetching = true
    const apiUrl = this.locationUrl.replace(':keyword', this.location.address)
    axios
      .get(apiUrl)
      .then(response => {
        if (response && response.data && response.data.LocationResult) {
          const locationResult = response.data.LocationResult[0]
          this.$emit('get-location-result', locationResult)
          const coordinates = locationResult.BoundingBox
          const extent: Extent = boundingExtent([
            [
              coordinates.LowerLeft.X_Lambert72,
              coordinates.LowerLeft.Y_Lambert72
            ],
            [
              coordinates.UpperRight.X_Lambert72,
              coordinates.UpperRight.Y_Lambert72
            ]
          ])
          if (this.map) {
            this.map.zoomToExtent(extent, 500)
          }
        }
      })
      .finally(() => {
        this.fetching = false
      })
  }

  public mounted () {
    Vue.nextTick(() => {
      this.initMap()
    })
  }
}
