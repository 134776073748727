import vipInlichtingPreview from '../../../vip-inlichting-preview'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingPreview).extend({
  name: 'vip-inlichting-preview-template-soort-weg',
  methods: {
    label (): string {
      return this.typeLabel
    }
  }
})
