import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-leegstaande-gebouwen',
  data () {
    return {
      referentie: this.getAttribute('Referentie'),
      inventarisType: this.getAttribute('InventarisType'),
      onroerendGoedType: this.getAttribute('OnroerendGoedType'),
      inventarisStatus: this.getAttribute('InventarisStatus'),
      opgenomenSinds: this.getAttribute('OpgenomenSinds'),
      link: this.getAttribute('ExterneDocumentatie'),
      locatieBeschrijving: this.getAttribute('Beschrijving'),
      locatieStraat: this.getAttribute('Straat'),
      locatieHuisnummer: this.getAttribute('Huisnummer'),
      locatieBusnummer: this.getAttribute('Busnummer'),
      locatiePostcode: this.getAttribute('Postcode'),
      locatieGemeente: this.getAttribute('Gemeente')
    }
  },
  methods: {
    getOptionLabel (option: string) {
      const res = this.inventarisStatus.options.find(f => f.value === option)
      if (res !== undefined) {
        return res.label
      }
      return ''
    },
    onPostcodeInput () {
      this.setAddressRequired()
    },
    onChange () {
      this.setAddressRequired()
    },
    setAddressRequired () {
      this.locatiePostcode.required = this.addressIsRequired()
      this.locatieStraat.required = this.addressIsRequired()
      this.locatieGemeente.required = this.addressIsRequired()
      this.locatieHuisnummer.required = this.addressIsRequired()
    },
    addressIsRequired () {
      return !!this.locatieStraat.value ||
      !!this.locatieHuisnummer.value ||
      !!this.locatieBusnummer.value ||
      !!this.locatiePostcode.value ||
      !!this.locatieGemeente.value
    }
  },
  mounted () {
    this.setAddressRequired()
  }
})
