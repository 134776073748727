import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-stedenbouwkundige-vergunning',
  data () {
    return {
      beschrijving: this.getAttribute('beschrijving'),
      dossiernummer: this.getAttribute('dossier_nummer'),
      gemeentelijkDossiernummer: this.getAttribute('gemeentelijk_dossier_nummer'),
      gewestelijkDossiernummer: this.getAttribute('gewestelijk_dossier_nummer'),
      aardVanDeAanvraag: this.getAttribute('aard_van_de_aanvraag'),
      verlener: this.getAttribute('vergunningVerlenendeOverheid'),
      status: this.getAttribute('beslissing_eerste_aanleg'),
      beslist_op: this.getAttribute('beslist_op'),
      vergundTot: this.getAttribute('vergundTot'),
      vervalDatum: this.getAttribute('Vervaldatum'),
      vervalMotivering: this.getAttribute('VervalMotivering'),
      vervalStatus: this.getAttribute('VervalStatus'),
      beroep_aangetekend: this.getAttribute('beroep_aangetekend'),
      voorwaarden: this.getAttribute('voorwaarden_vergunning'),
      beroepsProcedures: this.getAttribute('beroeps_procedures'),
      beroepsProceduresFields: [
        'beroeps_instantie',
        'beroep_status',
        'beroep_beslist_op'
      ],
      externeDocumentatie: this.getAttribute('externeDocumentatie'),
      bijlage: this.getAttribute('bijlagen'),
      intrekkingPartij: this.getAttribute('Intrekking_PartijType'),
      intrekkingDatum: this.getAttribute('Intrekking_Datum'),
      intrekkingMotivering: this.getAttribute('Intrekking_Motivering')
    }
  },
  methods: {
    getOptionLabel (option: string) {
      const res = this.aardVanAanvraagOptions.find(f => f.value === option)
      if (res !== undefined) {
        return res.label
      }
      return ''
    }
  },
  computed: {
    aardVanAanvraagOptions: {
      get: function () {
        return (this.getAttribute('aard_van_de_aanvraag').options || [])
      }
    },
    setDatumBeslissingVerplicht () {
      const verplicht = this.getAttribute('beslissing_eerste_aanleg').value !== 'GeenTijdigeBeslissing'
      this.getAttribute('beslist_op').required = verplicht
      return verplicht
    }
  },
  watch: {
    'vervalStatus.value': function (value: unknown) {
      if (value && value !== 'Ingetrokken') {
        this.intrekkingPartij.value = ''
        this.intrekkingDatum.value = ''
        this.intrekkingMotivering.value = ''
      } else if (value === 'Ingetrokken') {
        this.vervalMotivering.value = ''
        this.vervalDatum.value = ''
      }
    }
  }
})
