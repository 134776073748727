import Vue from 'vue'
import VueRouter, { Route } from 'vue-router'
import { RouteConfigSingleView } from 'vue-router/types/router'
import { metaDataHandler } from './meta-data-handler'
import { authHandler } from './auth-handler'

Vue.use(VueRouter)

export const BaseTitle = 'VIP - Vastgoedinformatieplatform | Digitaal Vlaanderen'

export interface IRoute extends Route {
  // eslint-disable-next-line no-use-before-define
  meta?: IRouteMeta;
}
export interface IRouteMeta {
  title?: string;
  dynamicTitle?: (route: IRoute)=> string;
  redirect?: (route: IRoute)=> string;
  authorize?: {
    roles?: string[];
    organizations?: string[];
  };
  breadcrumb?: {
    title: string;
    // eslint-disable-next-line no-use-before-define
    parent?: IRouteConfig;
  },
  environments?: string[];
}

export interface IRouteConfig extends RouteConfigSingleView {
  meta?: IRouteMeta;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createRouter = () => {
  const router = new VueRouter({
    mode: 'history',
    base: '',
    routes: getAllRoutes()
  })

  router.beforeEach(metaDataHandler)
  router.beforeEach(authHandler)

  return router
}

export function getAllRoutes (): IRouteConfig[] {
  const routeFiles = require.context('@/', true, /[A-Za-z0-9\-_]+-routes\.ts$/i)
  const routes: IRouteConfig[] = []
  routeFiles.keys().forEach(key => {
    routeFiles(key).default.forEach((route: IRouteConfig) => {
      routes.push(route)
    })
  })
  return routes
}
