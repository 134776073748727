import { InlichtingAanvullenRequest, InlichtingAttribuut, InlichtingData, InlichtingDatePeriodAttribuut } from '@/infrastructure/bff-client/bff-client'

export class VipInlichtingMapper {
  mapToInlichtingData (dossierId: string, vanToepassing: boolean, inlichtingTypeCode: string, inlichtingAttributen: InlichtingAttribuut[]): InlichtingAanvullenRequest {
    const inlichtingAanvulling = { data: [] as InlichtingData[] } as InlichtingAanvullenRequest
    inlichtingAanvulling.vanToepassing = vanToepassing
    inlichtingAanvulling.dossierId = dossierId
    inlichtingAanvulling.inlichtingTypeCode = inlichtingTypeCode

    inlichtingAttributen
    // Meta data attributen moeten niet worden verstuurd.
      .filter(a => !a.isMetaData)
      .forEach((a: InlichtingAttribuut) => {
        const inlichtingData = this._mapToInlichtingData(a)
        inlichtingAanvulling.data.push(inlichtingData)
      })

    return inlichtingAanvulling
  }

  _mapToInlichtingData (attribuut: InlichtingAttribuut): InlichtingData {
    const inlichtingData = {} as InlichtingData

    if (this.isComplexTypeAttribuutValue(attribuut.value)) {
      this.mapComplexAttribuut(inlichtingData, attribuut)
    } else if (this.isSetOfComplexTypeAttributen(attribuut.value)) {
      this.mapSetOfComplexTypeAttributen(inlichtingData, attribuut)
    } else if (this.isDatePeriodAttribuut(attribuut)) {
      this.mapDatePeriodAttribuut(inlichtingData, attribuut)
    } else {
      inlichtingData.field = attribuut.name
      inlichtingData.value = attribuut.value
    }

    return inlichtingData
  }

  /*
    ComplexTypeAttribuut value is een array waarvan elke item een inlichtingattribuut is die samen een gegeven
    vormen (bv. beroepsprocedure).
  */
  isComplexTypeAttribuutValue (attribuutValue: any) {
    return Array.isArray(attribuutValue) &&
    attribuutValue.length > 0 &&
    // De gebruikte datum component geeft de waarde door als een array, dit mag niet worden gezien als een complex type.
    (Object.prototype.toString.call(attribuutValue[0]) !== '[object Date]') &&
    // Array van strings mag niet worden gezien als een complex type.
    (Object.prototype.toString.call(attribuutValue[0]) !== '[object String]') &&
    // Het mag geen set zijn van attributen.
    !Array.isArray(attribuutValue[0])
  }

  mapComplexAttribuut (inlichtingData: InlichtingData, attribuut: InlichtingAttribuut) {
    inlichtingData.field = attribuut.name
    inlichtingData.value = [] as InlichtingData[]
    for (const complexTypeInlichtingAttribuut of attribuut.value) {
      const inlichtingDataItem = this._mapToInlichtingData(complexTypeInlichtingAttribuut)
      inlichtingData.value.push(inlichtingDataItem)
    }
  }

  /**
    AttribuutSet is een array waarvan elke item uit meerdere attributen bestaat welke samen een gegeven vormen
    (bv. beroepsprocedure).
  */
  isSetOfComplexTypeAttributen (attribuutValue: any): boolean {
    return Array.isArray(attribuutValue) &&
    attribuutValue.length > 0 &&
    Array.isArray(attribuutValue[0]) &&
    attribuutValue[0].length > 0
  }

  mapSetOfComplexTypeAttributen (inlichtingData: InlichtingData, attribuut: InlichtingAttribuut) {
    inlichtingData.field = attribuut.name
    inlichtingData.value = [] as InlichtingData[]
    for (const complexTypeAttributenSet of attribuut.value) {
      const complexTypeInlichtingData = [] as InlichtingData[]

      for (const complexTypeAttribuut of complexTypeAttributenSet) {
        const complexTypeAttribuutData = this._mapToInlichtingData(complexTypeAttribuut)
        complexTypeInlichtingData.push(complexTypeAttribuutData)
      }
      inlichtingData.value.push(complexTypeInlichtingData)
    }
  }

  isDatePeriodAttribuut (attribuut: InlichtingAttribuut): boolean {
    return attribuut.hasOwnProperty('dateFrom') && attribuut.hasOwnProperty('dateUntil')
  }

  mapDatePeriodAttribuut (inlichtingData: InlichtingData, attribuut: InlichtingAttribuut) {
    const datePeriodAttribuut = attribuut as InlichtingDatePeriodAttribuut
    inlichtingData.field = datePeriodAttribuut.name
    inlichtingData.value = [] as InlichtingData[]

    const dateFromData = {} as InlichtingData
    dateFromData.field = datePeriodAttribuut.dateFrom.name
    dateFromData.value = datePeriodAttribuut.dateFrom.value
    inlichtingData.value.push(dateFromData)

    const dateUntilData = {} as InlichtingData
    dateUntilData.field = datePeriodAttribuut.dateUntil.name
    dateUntilData.value = datePeriodAttribuut.dateUntil.value
    inlichtingData.value.push(dateUntilData)
  }
}
