import { Administrator, Informatieaanvrager, Informatieleverancier, Kwaliteitscontroleur } from '@/infrastructure/constants/roles'
import { BaseTitle, IRouteConfig } from '@/infrastructure/route/vip-router'
import { RouteDashboard } from '../dashboard-module/dashboard-routes'

export const RouteDossier: IRouteConfig = {
  path: '/dossiers/:dossierType/:vipReferentie',
  name: 'dossier',
  meta: {
    // :vipReferentie will be replaced with the value of the corresponding route parameter in meta-data-handler.ts
    title: `Dossier {{:vipReferentie}} | ${BaseTitle}`,
    authorize: {
      roles: [Administrator, Informatieaanvrager, Informatieleverancier, Kwaliteitscontroleur]
    },
    breadcrumb: {
      title: ':vipReferentie',
      parent: RouteDashboard
    }
  },
  component: () => import('./dossier.vue')
}

export default [RouteDossier] as IRouteConfig[]
