import Vue from 'vue'

export default Vue.extend({
  name: 'vip-overstromingsgevoeligheid-score',
  props: {
    scoreType: String,
    globaleScore: String,
    pluviaalScore: String,
    fluviaalScore: String,
    kustScore: String
  },
  methods: {
    getScoreLabel (score: string): string {
      if (!score) {
        return ''
      }
      switch (score.toLocaleLowerCase()) {
        case 'a':
          return 'Geen overstroming gemodelleerd'
        case 'b':
          return 'Kleine kans op overstromingen onder klimaatverandering'
        case 'c':
          return 'Kleine kans op overstromingen'
        case 'd':
          return 'Middelgrote kans op overstromingen'
        default:
          return ''
      }
    }
  }
})
