import { InlichtingAttribuut } from '@/infrastructure/bff-client/bff-client'
import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '../../vip-inlichting-attributes'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-vergunning-verval',
  data () {
    return {
      datumverval: this.datumVervalAttribuut ?? this.getAttribute('vervalDatum'),
      motivering: this.motiveringAttribuut ?? this.getAttribute('vervalMotivering'),
      vervalstatus: this.vervalstatusAttribuut ?? this.getAttribute('vervalStatus')
    }
  },
  props: {
    datumVervalAttribuut: Object as ()=> InlichtingAttribuut,
    motiveringAttribuut: Object as ()=> InlichtingAttribuut,
    vervalstatusAttribuut: Object as ()=> InlichtingAttribuut,
    oudeVergunning: Boolean
  },
  methods: {
    vervalSelected (): boolean {
      return (this.vervalstatus.value !== null && this.vervalstatus.value !== '' && this.vervalstatus.value.toString().toLowerCase() !== 'onbekend')
    },
    showDatum (): boolean {
      const vervalStatusValue = this.vervalstatus?.value?.toLowerCase()
      if (this.oudeVergunning && vervalStatusValue === 'ingetrokken') {
        return false
      }
      return this.vervalSelected() && this.datumverval != null && vervalStatusValue !== 'nietonderzocht' && vervalStatusValue !== 'nietvervallen'
    },
    showMotivering (): boolean {
      const vervalStatusValue = this.vervalstatus?.value?.toLowerCase()
      if (this.oudeVergunning && vervalStatusValue === 'ingetrokken') {
        return false
      }
      return (this.vervalSelected()) && this.motivering != null
    }
  }
})
