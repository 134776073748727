import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-complex-project',
  data () {
    return {
      naam: this.getAttribute('naam'),
      status: this.getAttribute('status'),
      link: this.getAttribute('link'),
      bijlage: this.getAttribute('bijlagen')
    }
  },
  computed: {
    datumInWerkingTredingWijzigingAttribuut: {
      get: function () {
        return this.getAttribute('datum_in_werking_treding')
      }
    }
  }
})
