import EventBus from '@/infrastructure/events/event-bus'
import Vue from 'vue'

export default Vue.extend({
  name: 'vip-inlichting-actions',
  data () {
    return {
      removeModalopen: false,
      isRemoving: false,
      modalZindex: 402300
    }
  },
  props: {
    modDisabled: Boolean,
    modSaving: Boolean,
    submitButtonLabel: {
      default: 'Bewaren',
      type: String
    },
    canDelete: Boolean,
    invalid: Boolean
  },
  methods: {
    save () {
      this.$emit('save')
    },
    cancel () {
      this.$emit('cancel')
      this.$emit('cancelled')
    },
    remove () {
      this.removeModalopen = true
    },
    modalClosed () {
      this.removeModalopen = false
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    },
    removeInlichting () {
      this.isRemoving = true
      this.$emit('remove')
    }
  }
})
