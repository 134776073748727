import Vue from 'vue'

export default Vue.extend({
  name: 'vip-validation-wrapper',
  props: {
    for: String,
    label: String,
    annotation: String,
    error: String,
    modHiddenLabel: Boolean,
    modRequired: Boolean,
    modBlockLabel: Boolean
  },
  computed: {
    parsedLabel (): string {
      return `${this.label ? this.label : '&nbsp;'}${
        this.modRequired ? '&nbsp;<span class="vl-u-text--error">*</span>' : ''
      }`
    },
    forValue (): string {
      return this.for
    }
  }
})
