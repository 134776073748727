import Vue from 'vue'
import { CaPaKeyAdresEenheid } from '../../infrastructure/bff-client/bff-client'

export default Vue.extend({
  name: 'vip-gebouw-eenheden',
  data () {
    return {
      gebouwEenhedenPerAdres: [] as CaPaKeyAdresEenheid[],
      selectedGebouwEenheden: [] as number[],
      verplichteGebouwEenhedenTekst: 'Selecteer minstens 1 gebouweenheid:',
      showGebouwEenhedenOptions: false
    }
  },
  computed: {
    mustSelectGebouwEenheden (): boolean {
      if (this.gebouwEenhedenPerAdres) {
        if ((this.gebouwEenhedenPerAdres.length > 1 &&
          // Er zijn meerdere adressen met minstens 1 gebouweenheid.
          this?.gebouwEenhedenPerAdres.some((a) => a.gebouweenheden && a.gebouweenheden.length > 0)) ||
          // Er is maar 1 adres maar meerdere gebouweenheden.
          (this.gebouwEenhedenPerAdres.length === 1 && this.gebouwEenhedenPerAdres[0]?.gebouweenheden.length > 1)) {
          return true
        }
      }

      return false
    }
  },
  methods: {
    mapGebouwEenheden (gebouwEenhedenPerAdres: CaPaKeyAdresEenheid[]) {
      this.selectedGebouwEenheden = [] as number[]
      this.gebouwEenhedenPerAdres = []
      if (gebouwEenhedenPerAdres?.length > 0) {
        this.gebouwEenhedenPerAdres = gebouwEenhedenPerAdres

        if (this.gebouwEenhedenPerAdres.length === 1 &&
          (this.gebouwEenhedenPerAdres[0]?.gebouweenheden.length ?? 0) === 1) {
          // Indien er maar 1 adres is met 1 gebouweenheid selecteer deze dan automatisch.
          this.selectedGebouwEenheden.push(this.gebouwEenhedenPerAdres[0].gebouweenheden[0])
          // De keuzelijst moet niet worden getoond.
          this.showGebouwEenhedenOptions = false
        } else if (this.gebouwEenhedenPerAdres.length > 1) {
          // Toon enkel de keuzelijst indien er 1 adres is met meerdere gebouweenheden of als er meerdere adressen zijn.
          this.showGebouwEenhedenOptions = true
        }
      }
    }
  }
})
