import vipInlichtingPreview from '../../vip-inlichting-preview'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingPreview).extend({
  name: 'vip-inlichting-preview-template-leegstaande-gebouwen',
  methods: {
    getAddress (): string {
      if (!this.previewData.detail.locatie || !this.previewData.detail.locatie.straat) {
        return ''
      }
      let adres = this.previewData.detail.locatie.straat
      if (this.previewData.detail.locatie.huisnummer) {
        adres += ' ' + this.previewData.detail.locatie.huisnummer
      }
      if (this.previewData.detail.locatie.busnummer) {
        adres += ' bus ' + this.previewData.detail.locatie.busnummer
      }
      if (this.previewData.detail.locatie.postcode) {
        adres += ', ' + this.previewData.detail.locatie.postcode
      }
      if (this.previewData.detail.locatie.gemeente) {
        adres += ' ' + this.previewData.detail.locatie.gemeente
      }
      return adres
    }
  }
})
