import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-stedenbouwkundig-attest',
  data () {
    return {
      beschrijving: this.getAttribute('beschrijving'),
      referentie: this.getAttribute('referentie'),
      gemeentelijkDossiernummer: this.getAttribute('gemeentelijkDossierNummer'),
      aardAanvraag: this.getAttribute('aardAanvraag'),
      datumBeslissing: this.getAttribute('datumBeslissing'),
      instantieBeslissing: this.getAttribute('instantieBeslissing'),
      aardBeslissing: this.getAttribute('aardBeslissing'),
      externeDocumentatie: this.getAttribute('externeDocumentatie'),
      bijlage: this.getAttribute('bijlagen')
    }
  },
  methods: {
    getOptionLabel (option: string) {
      const res = this.aardVanAanvraagOptions.find(f => f.value === option)
      if (res !== undefined) {
        return res.label
      }
      return ''
    }
  },
  computed: {
    aardVanAanvraagOptions: {
      get: function () {
        return (this.getAttribute('aard_van_de_aanvraag').options || [])
      }
    }
  }
})
