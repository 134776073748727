import { defineStore } from 'pinia'
import { GemeenteAansluitInfo } from '../bff-client/bff-client'

interface IKaartState{
    aansluitInfo: GemeenteAansluitInfo[]
}

export const useKaartStore = defineStore('kaart', {
  state: (): IKaartState => ({
    aansluitInfo: null
  }),
  getters: {
    kaartGeefAangeslotenGemeenten: (state) => {
      if (state.aansluitInfo) {
        return state.aansluitInfo
      }
    },
    kaartGeefAansluitStatusVoorGemeente: (state) => {
      if (state.aansluitInfo) {
        return (gemeente: string) => state.aansluitInfo.find(f => f.gemeente === gemeente)
      }
    }
  },
  actions: {
    kaartSetAangeslotenGemeenten (aansluitGegevens) {
      this.aansluitInfo = aansluitGegevens
    }
  }
})
