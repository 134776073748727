import Vue from 'vue'
import { InlichtingResponse } from '@/infrastructure/bff-client/bff-client'
import { useUserStore } from '@/infrastructure/stores/user-store'
import { useDossierStore } from '@/infrastructure/stores/dossier-store'

export default Vue.extend({
  name: 'vip-inlichting-preview',
  props: {
    inlichting: Object as ()=> InlichtingResponse,
    modAlt: Boolean,
    template: {
      default: 'default',
      type: String
    },
    isAangevraagd: String
  },
  data () {
    return {
      previewData: JSON.parse(JSON.stringify(this.inlichting))
    }
  },
  computed: {
    classes () {
      return [
        'vip-inlichting__preview', {
          'vip-inlichting__preview--alt': this.modAlt
        }
      ]
    },
    component () {
      return `vip-inlichting-preview-template-${this.template}`
    },
    datumGoedgekeurd () {
      return (this.inlichting as InlichtingResponse).detail.datumGoedgekeurd
    },
    canEdit () {
      const userStore = useUserStore()
      const dossierStore = useDossierStore()
      return userStore.userCanEditDossier && !dossierStore.dossierIsReadOnly && !this.isAangevraagd
    },
    typeLabel (): string {
      return (this.inlichting as InlichtingResponse)?.label || ''
    }
  },
  methods: {
    downloadUrl (bijlageId: string): string {
      return config.VUE_APP_BASE_URI + '/bff/dossiers/bijlagen/' + bijlageId
    },
    geldigVanTotPreviewTekst (geldigVan: string, geldigTotEnMet: string) {
      if (!geldigVan && !geldigTotEnMet) {
        return ''
      }

      let geldigVanTekst = ''
      if (geldigVan) {
        geldigVanTekst = ' van ' + geldigVan
      }
      let geldigTotEnMetTekst = ''
      if (geldigTotEnMet) {
        geldigTotEnMetTekst = ' tot en met ' + geldigTotEnMet
      }
      return 'Geldig' + geldigVanTekst + geldigTotEnMetTekst
    },
    getCategorieDescription (categorie: string): string {
      let catDescription = ''
      switch (categorie) {
        case 'O':
          catDescription = 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement'
          break
        case 'A':
          catDescription = 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de twintig jaar'
          break
        case 'B':
          catDescription = 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de tien jaar'
          break
        case 'S':
          catDescription = 'De exploitant dient onder leiding van een bodemsaneringsdeskundige een oriënterend bodemonderzoek uit te laten voeren en het verslag ervan aan de OVAM te bezorgen op verschillende tijdstippen, zie Vlarem I bijlage 1'
          break
        case 'A*':
          catDescription = 'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend bodemonderzoek verplicht is bij overdracht, onteigening, sluiting en faillissement; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar'
          break
        case 'B*':
          catDescription = 'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de tien jaar'
          break
        case 'C':
          catDescription = 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de vijf jaar'
          break
        default:
          break
      }
      return catDescription
    },
    linksAvailable () {
      if (this.previewData.detail === null) {
        return false
      }
      return this.previewData.detail.bijlagen?.length > 0 || this.previewData.detail.externeDocumentatie?.length > 0
    }
  }
})
