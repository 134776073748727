import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '../../../../vip-inlichting-attributes'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-tweede-administratieve-aanleg',
  data () {
    return {
      ingediendOp: this.getAttribute('DatumBeroepIngediend'),
      beslistOp: this.getDatePeriodFromAttribute('tweedeAdministratieveAanlegGeldigPeriode'),
      vergundTot: this.getDatePeriodUntilAttribute('tweedeAdministratieveAanlegGeldigPeriode'),
      beslissing: this.getAttribute('Type'),
      beslistDoor: this.getAttribute('Instantie'),
      isTijdelijkVergund: true
    }
  },
  methods: {
    determinTijdelijkVergund () {
      this.isTijdelijkVergund = (this.beslissing.value && this.beslissing.value.toLowerCase() === 'tijdelijkvergund')
    }
  },
  watch: {
    'beslissing.value' () {
      this.determinTijdelijkVergund()
    }
  },
  mounted () {
    this.determinTijdelijkVergund()
  }
})
