import { defineComponent, ref, computed, onMounted, watch, getCurrentInstance } from 'vue'
import ErrorModule from './modules/error-module/error.vue'
import FunctionalHeader from '@/components/vip-functional-header/functional-header.vue'
import ForbiddenModal from './components/forbidden-modal/forbidden-modal.vue'
import UnauthorizedModal from './components/unauthorized-modal/unauthorized-modal.vue'
import { useUserStore } from '@/infrastructure/stores/user-store'
import vipAlert from '@/components/vip-alert/vip-alert.vue'
import EventBus from '@/infrastructure/events/event-bus'

declare const BUILD_VERSION: string
declare const config: any

export default defineComponent({
  name: 'app-module',
  components: {
    error: ErrorModule,
    FunctionalHeader,
    ForbiddenModal,
    vipAlert,
    UnauthorizedModal
  },
  setup () {
    const instance = getCurrentInstance()
    const root = instance?.proxy

    const errorPageCode = ref(0)
    const forbidden = ref(false)
    const unAuthorized = ref(false)
    const errorContent = ref('')
    const onboarding = ref(false)
    const buildNumber = ref('')
    const userStore = useUserStore()

    const isForbidden = computed(() => {
      return !showLandingPage() && !userStore.userModuleHasRole((root.$route.meta as any)?.authorize?.roles)
    })

    const environment = computed(() => config.VUE_APP_ENV)

    const showForbiddenModal = () => {
      forbidden.value = true
    }

    const handleError = (error: { errorCode: string; correlationId: string; message: string }) => {
      if (error.message) {
        errorContent.value = error.message
      } else {
        errorContent.value = `Gelieve ons te <a href="mailto:vip@athumi.eu?body=Referentie=${error.correlationId}">contacteren</a> met vermelding van de referentie en het tijdstip van de melding.`
      }
      if (error.correlationId) {
        errorContent.value += `\n\nReferentie: ${error.correlationId}`
      }
      if (error.errorCode === '403') {
        showForbiddenModal()
        return
      }
      if (error.errorCode !== '401') {
        errorPageCode.value = +error.errorCode
      } else {
        unAuthorized.value = true
      }
    }

    const closeModal = (className: string) => {
      const element = document.getElementsByClassName(className)[0]
      if (element) {
        element.remove()
      }
    }

    const showLandingPage = () => {
      let cookieSet = document.cookie.includes('Vip.Web.LandingPageLoaded')
      if (root.$route.path.includes('info')) {
        cookieSet = false
      }
      return !cookieSet
    }

    const getEnvironment = () => {
      return environment.value === 'production' ? '' : environment.value
    }

    const getHeaderStyle = () => {
      if (showLandingPage()) {
        return 'vip-header-landing'
      } else if (getEnvironment() !== '') {
        return 'vip-header-environment'
      }
      return 'vip-header-normal'
    }

    const getContentStyle = () => {
      if (showLandingPage()) {
        return 'vip-content-landing'
      } else if (getEnvironment() !== '') {
        return 'vip-content-environment'
      }
      return 'vip-content'
    }

    watch(
      () => root.$route,
      async () => {
        resetErrorFlags()

        const userInfo = userStore.userModuleGetUserInfo

        if (!isLandingPageLoaded()) {
          await handleFirstVisit(userInfo)
        } else {
          await handleSubsequentVisits()
        }
      },
      { immediate: true }
    )

    function resetErrorFlags () {
      errorPageCode.value = 0
      forbidden.value = false
      unAuthorized.value = false
    }

    function isLandingPageLoaded () {
      return root.$cookies.isKey('Vip.Web.LandingPageLoaded')
    }

    async function handleFirstVisit (userInfo: any) {
      if (!userInfo || !userInfo.userId) {
        try {
          await root.$router.push('/info')
        } catch (error) {
          handleRouterError(error)
        }
      } else {
        root.$cookies.set('Vip.Web.LandingPageLoaded', '')
      }
    }

    async function handleSubsequentVisits () {
      if (isUnknownRoute()) {
        errorPageCode.value = 404
      }
    }

    function isUnknownRoute () {
      return !root.$route.name && root.$route.path !== '/'
    }

    function handleRouterError (error: any) {
      if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
        console.error(error)
      }
    }

    onMounted(() => {
      if (config.VUE_APP_ENV === 'production') {
        if (BUILD_VERSION !== '') {
          buildNumber.value = BUILD_VERSION
        }
      }

      EventBus.$on('forbidden-access', showForbiddenModal)
      EventBus.$on('error', (error: { errorCode: string; correlationId: string; message: string }) => handleError(error))
      EventBus.$on('close-modal', closeModal)
    })

    return {
      errorPageCode,
      forbidden,
      unAuthorized,
      errorContent,
      onboarding,
      buildNumber,
      isForbidden,
      environment,
      showForbiddenModal,
      handleError,
      closeModal,
      showLandingPage,
      getEnvironment,
      getHeaderStyle,
      getContentStyle,
      userStore,
    }
  },
})
