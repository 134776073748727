import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-besluit-burgemeester-openbare-veiligheid',
  data () {
    return {
      beschrijving: this.getAttribute('Beschrijving'),
      gemeentelijkDossiernummer: this.getAttribute('GemeentelijkDossiernummer'),
      beslistOp: this.getAttribute('Datum'),
      externeDocumentatie: this.getAttribute('ExterneDocumentatie')
    }
  }
})
