import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'
export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-aankoop-of-gebruik-gemeentegrond',
  data () {
    return {
      beschrijving: this.getAttribute('Beschrijving'),
      type: this.getAttribute('Type'),
      bedrag: this.getAttribute('Bedrag'),
      externeDocumentatie: this.getAttribute('ExterneDocumentatie'),
      bijlagen: this.getAttribute('Bijlagen')
    }
  }
})
