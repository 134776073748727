import vipInlichtingAttributes from '../../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-stedenbouwkundige-overtreding-proces-verbaal',
  data () {
    return {
      datum: this.getAttribute('datum'),
      nummer: this.getAttribute('nummer'),
      beslissingParket: this.getAttribute('beslissingParket'),
      seponeringDatum: this.getAttribute('seponeringDatum'),
      seponeringReden: this.getAttribute('seponeringReden'),
      seponeringSanctie: this.getAttribute('seponeringSanctie'),
      vervolgingDatum: this.getAttribute('vervolgingDatum'),
      vervolgingOmschrijving: this.getAttribute('vervolgingOmschrijving'),
      vervolgingVorderingInstantie: this.getAttribute('vervolgingVorderingInstantie'),
      vervolgingUitspraken: this.getAttribute('vervolgingUitspraken'),
      vervolgingUitsprakenAttributes: this.getAttribute('vervolgingUitsprakenMetaData').value,
      vervolgingUitsprakenFields: this.getAttribute('vervolgingUitsprakenMetaData').value.map(m => m.name)
    }
  },
  methods: {
    setRequired () {
      this.seponeringDatum.required = false
      this.vervolgingDatum.required = false
      this.vervolgingOmschrijving.required = false
      this.vervolgingVorderingInstantie.required = false
      if (this.beslissingParket.value === 'Seponering') {
        this.seponeringReden.required = true
        this.seponeringDatum.required = true
      } else if (this.beslissingParket.value === 'Vervolging') {
        this.vervolgingDatum.required = true
        this.vervolgingOmschrijving.required = true
        this.vervolgingVorderingInstantie.required = true
      } else {
        this.seponeringReden.required = false
        this.seponeringDatum.required = false
        this.vervolgingDatum.required = false
        this.vervolgingOmschrijving.required = false
        this.vervolgingVorderingInstantie.required = false
      }
    }
  },
  mounted () {
    this.setRequired()
  }
})
