import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-risicogrond-v2',
  data () {
    return {
      rubrieken: this.getAttribute('Rubrieken'),
      bijlage: this.getAttribute('bijlagen'),
      rubriekenFields: [
        'Rubrieknummer',
        'Omschrijving',
        'VlareboCode',
        'Startdatum',
        'Einddatum',
        'VitoUrl',
        'ReferentieVergunning',
        'RubriekType',
        'Schrapping',
        'Reden',
        'DatumBeslissing',
        'Dossiernummer'
      ],
      addButtonLabel: '+ Rubriek toevoegen',
    }
  }
})
