import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-beleidsplan',
  data () {
    return {
      beschrijving: this.getAttribute('Beschrijving'),
      datumOpmaak: this.getAttribute('DatumOpmaak'),
      impactPerceel: this.getAttribute('ImpactPerceel'),
      planType: this.getAttribute('PlanType'),
      externeDocumentatie: this.getAttribute('ExterneDocumentatie')
    }
  }
})
