import { IpproMapType } from '../../../vl-ippro-map/vl-ippro-map'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Overlay from 'ol/Overlay'
import Map from 'ol/Map'
import { Coordinate } from 'ol/coordinate'
import Feature from 'ol/Feature'

@Component
export default class IpproMapPopup extends Vue {
  public map: IpproMapType = null
  public ol: Map = null
  public popupOverlay: Overlay = null

  @Prop({ type: Array })
  public position: Coordinate

  @Prop({ type: Boolean, default: true })
  public autoPan: boolean

  @Prop({ type: Boolean, default: false })
  public modCloseClickOutside: boolean

  @Watch('position')
  onPositionUpdated (position: Coordinate) {
    if (position && position.length && this.popupOverlay) {
      this.popupOverlay.setPosition(position)
    }
  }

  close () {
    this.$emit('close')
  }

  initMap () {
    const isIpproMap = (
      variableToCheck: any
    ): variableToCheck is IpproMapType =>
      (variableToCheck as IpproMapType).mapScaleId !== undefined

    if (!this.map) {
      let parent = this.$parent
      while (parent && !this.map) {
        if (isIpproMap(parent)) {
          this.map = parent as IpproMapType
          this.ol = this.map.instance
          this.popupOverlay = new Overlay({
            element: parent.$el.querySelector('.ippro-map-popup'),
            autoPan: !!this.autoPan,
            autoPanAnimation: {
              duration: 250
            }
          })
          this.ol.addOverlay(this.popupOverlay)
          if (this.modCloseClickOutside) {
            this.ol.on('click', e => {
              const clickedFeatures: Feature[] = []
              this.ol.forEachFeatureAtPixel(e.pixel, feature => {
                clickedFeatures.push(feature as Feature)
              })
              if (!clickedFeatures.length) {
                this.close()
              }
            })
          }
        }
        parent = parent.$parent
      }
    }
  }

  public mounted () {
    Vue.nextTick(() => {
      this.initMap()
    })
  }
}
