import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-gemeentelijke-heffing',
  data () {
    return {
      beschrijving: this.getAttribute('beschrijving'),
      belastingType: this.getAttribute('belastingType'),
      link: this.getAttribute('link')
    }
  }
})
