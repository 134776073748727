import Vue from 'vue'

export default Vue.extend({
  name: 'vip-box',
  props: {
    modNoBorderBottom: Boolean
  },
  data () {
    return {
    }
  },
  computed: {
    classes () {
      return [
        'vip-box', {
          'vip-box--no-border-bottom': this.modNoBorderBottom
        }
      ]
    }
  },
  methods: {
  }
})
