import { defineStore } from 'pinia'

export interface IDossierState{
    dossierId: string,
    dossierReferentie: string,
    vipReferentie: string,
    readOnly: boolean,
    laatsteInlichting: string
}

export const useDossierStore = defineStore('dossier', {
  state: (): IDossierState => ({
    dossierId: '',
    dossierReferentie: '',
    vipReferentie: '',
    readOnly: false,
    laatsteInlichting: ''
  }),
  getters: {
    dossierModuleGetDossierId: (state) => {
      if (state.dossierId !== undefined && state.dossierId !== '') {
        return state.dossierId
      }
      return localStorage.getItem('active-dossier-id')
    },
    dossierModuleGetVipReferentie: (state) => {
      if (state.vipReferentie) {
        return state.vipReferentie
      }
      return localStorage.getItem('active-vip-referentie')
    },
    dossierModuleGetDossierReferentie: (state) => {
      if (state.dossierReferentie) {
        return state.dossierReferentie
      }
      return localStorage.getItem('active-dossier-referentie')
    },
    dossierIsReadOnly: (state) => state.readOnly,
    dossierGetInlichtingLastChangedId: (state) => state.laatsteInlichting
  },
  actions: {
    dossierModuleSetDossierId (dossierId: string) {
      this.readOnly = false
      this.dossierId = dossierId
      localStorage.setItem('active-dossier-id', dossierId)
    },
    dossierModuleSetVipReferentie (vipReferentie: string) {
      this.vipReferentie = vipReferentie
      localStorage.setItem('active-vip-referentie', vipReferentie)
    },
    dossierModuleSetDossierReferentie (dossierReferentie: string) {
      this.dossierReferentie = dossierReferentie
      localStorage.setItem('active-dossier-referentie', dossierReferentie)
    },
    dossierSetReadOnly (readOnly: boolean) {
      this.readOnly = readOnly
    },
    dossierSetInlichtingLastChangedId (inlichtingId: string) {
      this.laatsteInlichting = inlichtingId
    },
    dossierModuleSetLocalStorageDossierId (dossierId: string) {
      localStorage.setItem('active-dossier-id', dossierId)
    }
  }
})
