import { FileParameter } from '@/infrastructure/bff-client/bff-client'
import { BffClientFactory } from '@/infrastructure/bff-client/bff-client-handler'

export class VipInlichtingBijlagenService {
  async save (dossierId: string, inlichtingId: string, bijlagenToUpload: File[]) {
    if (!bijlagenToUpload || bijlagenToUpload.length === 0) {
      return
    }
    const promises = [] as Promise<string>[]
    for (const bijlage of bijlagenToUpload) {
      const bijlageFile = {
        data: bijlage,
        fileName: bijlage.name
      } as FileParameter

      promises.push(BffClientFactory().dossierInlichtingenBijlagen_Post(dossierId, inlichtingId, bijlageFile))
    }
    await Promise.allSettled(promises)
  }
}
