import { RestCapabilities, UserInfo } from '../bff-client/bff-client'
import { BffClientFactory } from '../bff-client/bff-client-handler'
import { Administrator, Informatieaanvrager, Informatieleverancier, Kwaliteitscontroleur } from '../constants/roles'
import { defineStore } from 'pinia'

export interface IUserStore {
  user?: UserInfo,
  activeTab: string,
  aanvraagFacturatieEmail: string,
  aanvraagContactEmail: string,
  onthoudEmailVoorVolgendeAanvragen: boolean,
  capabilities?: RestCapabilities
}

export const useUserStore = defineStore('user', {
  state: (): IUserStore => ({
    user: undefined,
    activeTab: undefined,
    aanvraagFacturatieEmail: '',
    aanvraagContactEmail: '',
    onthoudEmailVoorVolgendeAanvragen: null,
    capabilities: undefined
  }),
  getters: {
    userModuleGetRoles: (state) => state?.user?.roles,
    userModuleGetLowerCaseRoles: (state) => state?.user?.roles?.map(role => role.toLowerCase()),
    userModuleGetSignOutUri: () => `${window.location.origin}/auth/signout`,
    userModuleGetSwitchIdentityUri: () => `${window.location.origin}/auth/switch-identity`,
    userModuleGetLoginUri: () => `${window.location.origin}/auth/login`,
    userModuleGetOnthoudEmailVoorVolgendeAanvragen: state => {
      if (state.onthoudEmailVoorVolgendeAanvragen !== null) {
        return state.onthoudEmailVoorVolgendeAanvragen
      }
      const onthoudLocal = localStorage.getItem('aanvraag-email-onthoud')
      if (onthoudLocal) {
        state.onthoudEmailVoorVolgendeAanvragen = onthoudLocal === '1'
      }
      return state.onthoudEmailVoorVolgendeAanvragen
    },
    userModuleGetAanvraagFacturatieEmail: state => {
      if (state.aanvraagFacturatieEmail && state.aanvraagFacturatieEmail !== '') {
        return state.aanvraagFacturatieEmail
      }
      state.aanvraagFacturatieEmail = localStorage.getItem('aanvraag-facturatie-email')
      if (!state.aanvraagFacturatieEmail) {
        state.aanvraagFacturatieEmail = state.user?.email
      }

      return state.aanvraagFacturatieEmail
    },
    userModuleGetAanvraagContactEmail: state => {
      if (state.aanvraagContactEmail && state.aanvraagContactEmail !== '') {
        return state.aanvraagContactEmail
      }
      state.aanvraagContactEmail = localStorage.getItem('aanvraag-contact-email')
      if (!state.aanvraagContactEmail) {
        state.aanvraagContactEmail = state.user?.email
      }
      return state.aanvraagContactEmail
    },
    userModuleGetUserInfo: state => state.user ? JSON.parse(JSON.stringify(state.user)) : undefined,
    userModuleUserActive: (state) => {
      if (state.user && state.user.userId) {
        return true
      }
      const activeUser = JSON.parse(localStorage.getItem('Vip.Web.ActiveUser'))
      if (!activeUser || !activeUser.userId || activeUser.userId === '') {
        return false
      }
      const diffMinutes = Math.floor((new Date().valueOf() - new Date(activeUser.loginTimestamp).valueOf()) / 60000)

      if (diffMinutes < 600) {
        return true
      }
      return false
    },
    userIsAdmin: (state) => {
      const userRoles = state?.user?.roles
      return userRoles?.includes(Administrator)
    },
    userCanValidateDossier: (state) => {
      const userRoles = state?.user?.roles
      return userRoles?.includes(Kwaliteitscontroleur)
    },
    userIsAanvrager: (state) => {
      const userRoles = state?.user?.roles
      return userRoles?.includes(Informatieaanvrager)
    },
    userCanEditDossier: (state) => {
      const userRoles = state?.user?.roles
      return userRoles?.includes(Informatieleverancier) || userRoles?.includes(Kwaliteitscontroleur)
    },
    userIsAanvragerAndLeverancier: (state) => {
      const userRoles = state?.user?.roles
      return userRoles?.includes(Informatieaanvrager) && (userRoles.includes(Informatieleverancier) || userRoles.includes(Kwaliteitscontroleur))
    },
    userIsBurger: state => {
      return !state?.user?.organisationCode
    },
    userActiveTab: state => {
      if (state.activeTab !== undefined && state.activeTab !== '') {
        return state.activeTab
      }
      return localStorage.getItem(`${state.user.userId}-active-tab`)
    },
    userGetCapabilities: state => state.capabilities,
  },
  actions: {
    userModuleSetOnthoudEmailVoorVolgendeAanvragen (onthoud: boolean) {
      this.onthoudEmailVoorVolgendeAanvragen = onthoud
      localStorage.setItem('aanvraag-email-onthoud', onthoud ? '1' : '0')
    },
    userModuleSetAanvraagFacturatieEmail (facturatieEmail: string) {
      this.aanvraagFacturatieEmail = facturatieEmail
      localStorage.setItem('aanvraag-facturatie-email', facturatieEmail)
    },
    userModuleSetAanvraagContactEmail (contactEmail: string) {
      this.aanvraagContactEmail = contactEmail
      localStorage.setItem('aanvraag-contact-email', contactEmail)
    },
    userModuleMutUpdateUser (user: UserInfo) {
      this.user = user
    },
    userSetActiveTab (selectedTab: string) {
      localStorage.setItem(`${this.user.userId}-active-tab`, selectedTab)
      this.activeTab = selectedTab
    },
    userCheckAuthenticated (): Promise<boolean> {
      return BffClientFactory().authentication_UserIsAuthenticated()
    },
    async userModuleDispatchUpdateUser () {
      return BffClientFactory().authentication_GetUserInfo()
        .then(async userInfo => {
          this.userModuleMutUpdateUser(userInfo)
          await this.getUserCapabilities()
          localStorage.setItem('Vip.Web.ActiveUser', JSON.stringify({
            userId: userInfo.userId,
            loginTimestamp: new Date()
          }))
          const message = JSON.stringify({
            type: 'vl.widget.global_header.citizen_profile.session.login.complete'
          })
          // Notify the top most window about our login progress.
          window.top.postMessage(message, config.VUE_APP_BASE_URI)
        })
    },
    async getUserCapabilities () {
      return BffClientFactory().authentication_GetUserCapabilities().then(async capabilities => {
        this.capabilities = capabilities
      })
    },
    userModuleSetLocalStorageItem (_unkown, data) {
      localStorage.setItem(`${this.userModuleGetUserInfo.userId}-${data.key}`, data.value)
    },
    userModuleSetUserInactive () {
      const signOutUri = this.userModuleGetSignOutUri
      sessionStorage.clear()
      localStorage.removeItem('Vip.Web.ActiveUser')
      setTimeout(() => { window.location.assign(signOutUri) }, 1000)
    },
    userModuleHasRole (roles?: string[]): boolean {
      if (roles && roles.length > 0) {
        return roles.map(role => role.toLowerCase()).some(role => this.userModuleGetLowerCaseRoles.includes(role))
      } else {
        return true
      }
    }
  }
})
