import { render, staticRenderFns } from "./vip-inlichting-preview-template-risicozone-voor-overstromingen.vue?vue&type=template&id=31ad83c9"
import script from "./vip-inlichting-preview-template-risicozone-voor-overstromingen.ts?vue&type=script&lang=ts&external"
export * from "./vip-inlichting-preview-template-risicozone-voor-overstromingen.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports