import vipInlichtingPreview from '../../vip-inlichting-preview'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingPreview).extend({
  name: 'vip-inlichting-preview-template-default',
  methods: {
    label (): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (this.previewData.detail && this.previewData.vanToepassing === true && this.previewData.detail.verordeningstype) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
        return this.previewData.detail.verordeningstype.label + ' (' + (this.previewData.detail.previewLabel as string).toLocaleLowerCase() + ')'
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
        return this.typeLabel
      }
    }
  }
})
