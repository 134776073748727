import FunctionalHeader from '@/components/vip-functional-header/functional-header.vue'
import LandingPageHeader from '@/components/vip-landing-page-header/landing-page-header.vue'
import * as widgetClient from '@govflanders/vl-widget-client'
import 'reflect-metadata'
import { Widget } from '@govflanders/vl-widget-core/types/plugin'
import axios from 'axios'
import { useUserStore } from '@/infrastructure/stores/user-store'
import { computed, defineComponent, onMounted, watch } from 'vue'

export default defineComponent({
  name: 'VipHeader',
  components: {
    FunctionalHeader,
    LandingPageHeader
  },
  props: {
    showFunctionalHeader: Boolean
  },
  setup () {
    const globalHeaderWidgetId = computed(() => config.VUE_APP_GLOBAL_HEADER_WIDGET_ID)
    const mbpHeaderWidgetUri = computed(() => config.VUE_APP_MBP_HEADER_URI)
    const bffUri = computed(() => config.VUE_APP_BASE_URI)
    const userStore = useUserStore()
    const isAuthenticated = computed(() => userStore.userModuleUserActive)

    const generateHeader = async () => {
      if (globalHeaderWidgetId.value) {
        try {
          const response = await axios.get(`https://widgets.vlaanderen.be/widget/live/${globalHeaderWidgetId.value}`)
          const script = (response.data as string).replace(
            /document\.write\((.*?)\);/,
            'document.getElementsByClassName("global-header-placeholder")[0].innerHTML = $1;'
          )
          eval(script)
        } catch (error) {
          console.error('Error generating header:', error)
        }
      } else if (mbpHeaderWidgetUri.value) {
        try {
          const header = await widgetClient.bootstrap(mbpHeaderWidgetUri.value)
          header.setMountElement(document.getElementsByClassName('global-header-placeholder')[0])
          header.mount()
          header.getExtension('citizen_profile.session').then((session: any) => {
            session.configure({
              endpoints: {
                loginUrl: '/login',
                switchCapacityUrl: `${bffUri.value}/auth/switch`,
                loginRedirectUrl: '/',
                logoutUrl: '/logout'
              }
            })
          })
          const script = document.createElement('script')
          if (config.VUE_APP_ENV !== 'production') {
            script.setAttribute('src', 'https://tni.widgets.burgerprofiel.dev-vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-polyfill/dist/index.js')
          } else {
            script.setAttribute('src', 'https://prod.widgets.burgerprofiel.vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-polyfill/dist/index.js')
          }
          document.head.appendChild(script)
        } catch (error) {
          console.error('Error bootstrapping header widget:', error)
        }
      }
    }

    const widgetCapture = () => {
      widgetClient.capture((widget: Widget) => {
        if (widget.getPluginTypeId() === 'global_header') {
          const userStore = useUserStore()
          const userActive = userStore.userModuleUserActive
          widget.getExtension('citizen_profile.session').then((session: any) => {
            session.configure({
              active: userActive
            })
          })
        }
      })
    }

    const widgetActivity = () => {
      widgetClient.capture((widget: Widget) => {
        if (widget.getPluginTypeId() === 'global_header') {
          widget.getExtension('citizen_profile.session').then((session: any) => {
            const activityEventHandler = () => {
              session.extend()
            }

            const eventNames = [
              'mousedown',
              'mousemove',
              'mousewheel',
              'DOMMouseScroll',
              'scroll',
              'wheel',
              'keydown',
              'keypress',
              'touchmove',
              'touchstart'
            ]

            for (const eventName of eventNames) {
              window.addEventListener(eventName, activityEventHandler)
            }
          })
        }
      })
    }

    const authChanged = () => {
      if (!isAuthenticated.value) {
        return
      }
      if (mbpHeaderWidgetUri.value) {
        widgetCapture()
        widgetActivity()
      }
    }

    watch(
      isAuthenticated,
      authChanged,
      { immediate: true, deep: true }
    )

    onMounted(async () => {
      if (mbpHeaderWidgetUri.value) {
        widgetActivity()
        widgetCapture()
      }
      await generateHeader()
    })

    return {
      globalHeaderWidgetId,
      mbpHeaderWidgetUri,
      generateHeader,
      widgetCapture,
      widgetActivity
    }
  }
})
