import vipInlichtingAttributes from '../../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-stedenbouwkundige-overtreding-sanctie',
  data () {
    return {
      sanctie: this.getAttribute('sanctie')
    }
  }
})
