import vipInlichtingPreview from '../../vip-inlichting-preview'
import mixins from 'vue-typed-mixins'
import { getMilieuActiviteitenDetails } from '@/infrastructure/helpers/milieu-activiteiten-helper'

export default mixins(vipInlichtingPreview).extend({
  name: 'vip-inlichting-preview-template-milieuvergunning',
  methods: {
    checkOrCross (activiteit: {isVergund: boolean}) {
      return (activiteit.isVergund) ? 'check' : 'cross'
    },
    getGeldigPeriodeTekst (geldigVan: string, geldigTotEnMet: string) {
      let geldigVanTekst = ''
      if (geldigVan) {
        geldigVanTekst = ' van ' + geldigVan
      }
      let geldigTotEnMetTekst = ''
      if (geldigTotEnMet) {
        geldigTotEnMetTekst = ' tot ' + geldigTotEnMet
      }
      return 'Geldig' + geldigVanTekst + geldigTotEnMetTekst
    },
    getActiviteitTekstFormatted (activiteit: any) {
      return getMilieuActiviteitenDetails(activiteit)
    }
  }
})
