import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class IpproPagination extends Vue {
  @Prop({ type: Number, required: true })
    pageSize!: number

  @Prop({ type: Number, default: 1 })
    currentPage!: number

  @Prop({ type: Number, required: true })
    total!: number

  @Prop({ type: Number, required: true })
    results!: number

  @Prop({ type: String, required: false, default: 'nl' })
    locale: string

  messages: { [key: string]: any } = {
    en: {
      of: 'of',
      next: 'Next',
      previous: 'Previous',
      goToPrevious: 'Go to the previous page',
      goToNext: 'Go to the next page',
      goToPage: 'Go to page'
    },
    nl: {
      of: 'van',
      next: 'Volgende',
      previous: 'Vorige',
      goToPrevious: 'Ga naar de vorige pagina',
      goToNext: 'Ga naar de volgende pagina',
      goToPage: 'Ga naar pagina'
    }
  }

  translation (key: string): string {
    return this.messages[this.messages[this.locale] ? this.locale : 'nl'][key]
  }

  get pagerFrom (): number {
    return this.currentPage > 1
      ? (this.currentPage - 1) * (this.pageSize || this.results) + 1
      : 1
  }

  get pagerTo (): number {
    return Math.min(
      this.total ? this.total : this.results * this.currentPage,
      this.pageSize * this.currentPage
    )
  }

  get pagerTotal (): number {
    return this.total || (this.results < this.pageSize ? this.results : 0)
  }

  get pagePosition (): string {
    return `<span><strong>${this.pagerFrom}&nbsp;-&nbsp;${this.pagerTo ||
      '...'}</strong> ${this.translation('of')}&nbsp;${this.pagerTotal ||
      '...'}</span>`
  }

  get pageCount (): number {
    return Math.ceil(this.total / (this.pageSize || 1))
  }

  get prevButtonActive (): boolean {
    return this.currentPage !== 1
  }

  get nextButtonActive (): boolean {
    return this.currentPage !== this.pageCount
  }

  get pagination (): string[] {
    const pageArray: string[] = []

    if (this.pageCount > 1) {
      if (this.pageCount < 7) {
        for (let i = 1; i <= this.pageCount; i++) {
          pageArray.push(i.toString())
        }
      } else {
        for (let i = 1; i <= 7; i++) {
          switch (i) {
            case 1:
              pageArray.push('1')
              break
            case 2:
              if (this.currentPage <= 4) {
                pageArray.push('2')
              } else {
                pageArray.push('...')
              }
              break
            case 3:
              if (this.currentPage <= 4) {
                pageArray.push('3')
              } else if (this.pageCount - this.currentPage < 4) {
                pageArray.push((this.pageCount - 4).toString())
              } else {
                pageArray.push((this.currentPage - 1).toString())
              }
              break
            case 4:
              if (this.currentPage <= 4) {
                pageArray.push('4')
              } else if (this.pageCount - this.currentPage < 4) {
                pageArray.push((this.pageCount - 3).toString())
              } else {
                pageArray.push(this.currentPage.toString())
              }
              break
            case 5:
              if (this.currentPage <= 4) {
                pageArray.push('5')
              } else if (this.pageCount - this.currentPage < 4) {
                pageArray.push((this.pageCount - 2).toString())
              } else {
                pageArray.push((this.currentPage + 1).toString())
              }
              break
            case 6:
              if (this.pageCount - this.currentPage < 4) {
                pageArray.push((this.pageCount - 1).toString())
              } else {
                pageArray.push('...')
              }
              break
            case 7:
              pageArray.push(this.pageCount.toString())
              break
          }
        }
      }
    }
    return pageArray
  }

  public changePage (pageNumber: number) {
    if (!isNaN(pageNumber)) {
      this.$emit('page-change', pageNumber)
    }
  }
}
