import vipInlichtingAttributes from '../../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-stedenbouwkundige-overtreding-aanmaning',
  data () {
    return {
      verzendDatum: this.getAttribute('verzendDatum'),
      opsteller: this.getAttribute('opsteller'),
      intentieOvertreder: this.getAttribute('intentieOvertreder')
    }
  }
})
