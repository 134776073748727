import { ApiException, CodelijstElement, DossierType, VipError } from '../bff-client/bff-client'
import EventBus from '../events/event-bus'
import axios from 'axios'
import { Extent, boundingExtent } from 'ol/extent'
import { useAlertStore, AlertMethod, AlertType, AlertIcons } from '@/infrastructure/stores/alert-store'

export const generateKey = () => {
  return Math.random()
    .toString(36)
    .substring(7)
}

export const makeUniqueKey = (prefix: string) => {
  return prefix + '-' + Math.random().toString(36).substring(7)
}

export const mapCodeLijst = (codeLijst: CodelijstElement[]) => {
  return codeLijst.map(codeLijstItem => ({
    value: codeLijstItem.value,
    label: codeLijstItem.label
  }))
}

export const isCaPaKeyFormat = (input: string) => {
  return /^\d{5}[A-Z]{1}(\d{4})\/(\d{2})([A-Z_]{1})(\d{3})$/.test(input)
}

export const errorAlert = (id: string, title: string, message: string, showToast?: boolean) => {
  const store = useAlertStore()
  store.addAlert({
    id,
    closable: true,
    method: showToast ? AlertMethod.Toast : AlertMethod.Page,
    title,
    message,
    type: AlertType.Error,
    icon: AlertIcons.AlertCircle
  })
}

export const catchBffError = (error: any, id: string, alertValidatie?: boolean, showToast?: boolean) => {
  const errorMessage = { errorCode: '', correlationId: '', message: '' }
  if (ApiException.isApiException(error)) {
    errorMessage.errorCode = error.status.toString()
  } else {
    // Ofwel generieke error => error.response, ofwel BadRequestResponseBody/NotFoundResponseBody
    if (isVipError(error)) {
      errorMessage.errorCode = '404'
      errorMessage.message = error.title
    } else {
      if (error.response) {
        errorMessage.errorCode = error.response.status
        if (error.response.headers !== undefined) {
          errorMessage.correlationId = error.response.headers['x-correlation-id']
        }
      }
    }
  }
  if (error.headers !== undefined) {
    errorMessage.correlationId = error.headers['x-correlation-id']
  }
  if (alertValidatie && errorMessage.errorCode !== '401' && errorMessage.errorCode !== '403') {
    if (errorMessage.message === '') {
      errorMessage.message = 'Er is iets onverwacht misgegaan.'
    }
    if (errorMessage.correlationId !== '') {
      errorMessage.message += '<br /><br />Gebruik volgende referentie om de fout te <a href="mailto:vip@athumi.eu?body=Referentie=' + errorMessage.correlationId + '">melden</a>: ' + errorMessage.correlationId
    }
    errorAlert(id, 'Fout in verzoek', errorMessage.message + '', showToast)
  } else {
    EventBus.$emit('error', errorMessage)
  }
}

const isVipError = (obj: any): obj is VipError => {
  return (obj as VipError).title !== undefined
}

export function getDossierType (type: string): DossierType {
  if (!type) {
    return DossierType.Toegekend
  }
  const conv = type.substring(0, 1).toLocaleUpperCase() + type.substring(1, type.length)
  return DossierType[conv]
}

export async function getLocationExtentAndGemeente (coordinates?: number[], gemeente?: string): Promise<{ extent: Extent, gemeente: string }> {
  const locationUrl = 'https://geo.api.vlaanderen.be/geolocation/v4/location?q=:keyword&c=1'
  const apiUrl = locationUrl.replace(':keyword', coordinates ? coordinates.join(',') : gemeente)
  return axios
    .get(apiUrl)
    .then(response => {
      if (response && response.data && response.data.LocationResult) {
        const locationResult = response.data.LocationResult[0]
        const responseCoordinates = locationResult.BoundingBox
        const extent: Extent = boundingExtent([
          [
            responseCoordinates.LowerLeft.X_Lambert72,
            responseCoordinates.LowerLeft.Y_Lambert72
          ],
          [
            responseCoordinates.UpperRight.X_Lambert72,
            responseCoordinates.UpperRight.Y_Lambert72
          ]
        ])
        return { extent, gemeente: locationResult.Municipality }
      }
    })
    .catch(() => {
      return { extent: null, gemeente: '' }
    })
}

export const isCodelijstElement = (obj: any): obj is CodelijstElement => {
  if (typeof obj !== 'object' || obj === null) {
    return false
  }

  if (obj.options !== undefined) {
    if (!Array.isArray(obj.options)) {
      return false
    }
    if (!obj.options.every(isCodelijstElement)) {
      return false
    }
  }

  return true
}
