import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-voorwaarden-gemeentelijke-akte',
  data () {
    return {
      voorwaarde: this.getAttribute('voorwaarde'),
      akteVerledenOp: this.getAttribute('datumAkte'),
      externeDocumentatie: this.getAttribute('ExterneDocumentatie')
    }
  }
})
