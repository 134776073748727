import Vue from 'vue'
import { AlertMethod, AlertType, IAlert, useAlertStore } from '@/infrastructure/stores/alert-store'
import { mapStores } from 'pinia'

export default Vue.extend({
  name: 'vip-alert',
  computed: {
    ...mapStores(useAlertStore),
    alerts () {
      return this.alertStore.alerts(AlertMethod.Page)
    },
    toasters () {
      return this.alertStore.alerts(AlertMethod.Toast)
    },
    types () {
      return AlertType
    },
    alertsAndToasters () {
      return this.alertStore.alertsAndToasters
    }
  },
  methods: {
    async removeAlert (id: string) {
      this.alertStore.removeAlerts([id])
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (oldRoute, currentRoute) {
        if (oldRoute?.name !== currentRoute?.name) {
          const alerts = this.alertStore.alerts(null)
          const alertsToRemove = [] as IAlert[]
          alerts.forEach((alert: IAlert) => {
            if (alert.routes.findIndex(ar => ar.name === currentRoute.name || ar.path === currentRoute.path) >= 0) {
              alertsToRemove.push(alert)
            }
          })
          if (alertsToRemove.length) {
            this.alertStore.removeAlerts(alertsToRemove.map((alert: IAlert) => alert.id))
          }
        }
      }
    }
  }
})
