import vipInlichtingPreview from '../../vip-inlichting-preview'
import mixins from 'vue-typed-mixins'
import Vue from 'vue'

export default mixins(vipInlichtingPreview).extend({
  name: 'vip-inlichting-preview-template-recht-van-voorkoop',
  computed: {
    formattedDate () {
      let formattedDate = ''
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const geldigVan = this.previewData.detail.geldigVan
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const geldigTotEnMet = this.previewData.detail.geldigTotEnMet

      if (geldigVan && geldigTotEnMet) {
        formattedDate = 'Geldig van ' + Vue.filter('formatDate')(geldigVan) + ' tot en met ' + Vue.filter('formatDate')(geldigTotEnMet)
      } else if (geldigVan) {
        formattedDate = 'Geldig van ' + Vue.filter('formatDate')(geldigVan)
      } else if (geldigTotEnMet) {
        formattedDate = 'Geldig tot en met ' + Vue.filter('formatDate')(geldigTotEnMet)
      }

      return formattedDate
    }
  }
})
