import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-onbebouwde-percelen',
  data () {
    return {
      referentie: this.getAttribute('Referentie'),
      categorie: this.getAttribute('Categorie'),
      beschrijving: this.getAttribute('Beschrijving'),
      loten: this.getAttribute('Lotnummer'),
      link: this.getAttribute('ExterneDocumentatie')
    }
  }
})
