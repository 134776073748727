import { defineComponent, PropType, ref } from 'vue'
import {
  AankoopOfGebruikGemeentegrondInlichting,
  AankoopOfGebruikGemeentegrondInlichtingInlichtingType,
  AankoopOfGebruikGemeentegrondInlichtingType,
  AankoopOfGebruikGemeentegrondInput,
  AankoopOfGebruikGemeentegrondInputDiscriminator,
  AankoopOfGebruikGemeentegrondInputInlichtingType,
  AankoopOfGebruikGemeentegrondInputType, BronInformatie,
  DossierStatus,
  Inlichting,
  InlichtingenIndicator,
  InlichtingInputInlichtingenIndicator
} from '@/infrastructure/bff-client/bff-client'
import useInlichting from '@/components/vip-inlichting/new-version/composables/inlichting.composable'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import { formatNumber } from '@/infrastructure/filters/filters'
import EventBus from '@/infrastructure/events/event-bus'
import { InlichtingType } from '@/infrastructure/constants/inlichting-types'

function mapInlichtingToInput (vipInlichting?: AankoopOfGebruikGemeentegrondInlichting): AankoopOfGebruikGemeentegrondInput {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingInputInlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator as unknown as InlichtingInputInlichtingenIndicator,
    beschrijving: vipInlichting?.beschrijving,
    type: vipInlichting?.type as unknown as AankoopOfGebruikGemeentegrondInputType,
    externeDocumentatie: vipInlichting?.externeDocumentatie,
    discriminator: AankoopOfGebruikGemeentegrondInputDiscriminator.AANKOOP_OF_GEBRUIK_GEMEENTEGROND_V1,
    inlichtingType: AankoopOfGebruikGemeentegrondInputInlichtingType.AANKOOP_OF_GEBRUIK_GEMEENTEGROND,
    bijlagen: vipInlichting?.bijlagen,
  } as AankoopOfGebruikGemeentegrondInput
}

function mapInputToInlichting (input: AankoopOfGebruikGemeentegrondInput, inlichtingId: string): AankoopOfGebruikGemeentegrondInlichting {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    beschrijving: input.beschrijving,
    type: input.type as unknown as AankoopOfGebruikGemeentegrondInlichtingType,
    externeDocumentatie: input.externeDocumentatie,
    discriminator: AankoopOfGebruikGemeentegrondInputDiscriminator.AANKOOP_OF_GEBRUIK_GEMEENTEGROND_V1,
    inlichtingType: AankoopOfGebruikGemeentegrondInlichtingInlichtingType.AANKOOP_OF_GEBRUIK_GEMEENTEGROND,
    bijlagen: input.bijlagen,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: []
  } as AankoopOfGebruikGemeentegrondInlichting
}

export default defineComponent({
  name: 'AankoopOfGebruikGemeentegrond',
  components: { VipInlichtingVanToepassing },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      downloadUrl,
      generateId
    } = useInlichting(props.inlichting ?? ({ inlichtingType: InlichtingType.AANKOOP_OF_GEBRUIK_GEMEENTEGROND } as unknown as AankoopOfGebruikGemeentegrondInlichting), mapInlichtingToInput, mapInputToInlichting)

    const addedBijlagen = ref([] as File[])

    const inlichtingTitle = 'Aankoop of gebruik gemeentegrond'

    const typeOptions =
        new Map<string, string>([
          ['AANKOOP', 'Aankoop'],
          ['GEBRUIK', 'Gebruik']
        ])

    const getTypeLabel = (type: string): string => {
      if (typeOptions.has(type)) {
        return typeOptions.get(type)
      }
    }

    const bijlageUploaded = (bijlagen: File[]) => {
      addedBijlagen.value.push(...bijlagen)
    }

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (!inlichtingInput.value.beschrijving) {
        errors.set('beschrijving', 'Beschrijving is verplicht')
      }
      return errors
    }

    const validationFailed = (errors: Map<string, string>) => {
      validationErrors.value = errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      canEdit,
      addedBijlagen,
      getTypeLabel,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      downloadUrl,
      generateId,
      formatNumber,
      inlichtingTitle,
      typeOptions,
    }
  }
})
