import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

enum ActiteitenToevoegenStep {
  Init = 1,
  KiesRubriekType,
  ToevoegenActiviteiten
}

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-risicogrond',
  data () {
    return {
      referentie: this.getAttribute('referentieVergunning'),
      typeInrichting: this.getAttribute('typeInrichting'),
      schrappingRisicogrond: this.getAttribute('schrappingRisicogrond'),
      schrappingDossiernr: this.getAttribute('schrappingDossiernummer'),
      schrappingReden: this.getAttribute('schrappingReden'),
      schrappingBeslistOp: this.getAttribute('schrappingBeslistOp'),
      activiteiten: this.getAttribute('activiteiten'),
      bijlage: this.getAttribute('bijlagen'),
      activiteitFields: [
        'activiteitRubriek',
        'activiteitVlareboCode',
        'activiteitRisicoKlasse',
        'activiteitOmschrijving',
        'activiteitRubriekType'
      ],
      addButtonLabel: '+ Activiteit toevoegen',
      currentActiviteitenToevoegenStep: ActiteitenToevoegenStep.Init
    }
  },
  methods: {
    schrappingChangeHandler: function () {
      this.schrappingReden.required = this.schrappingRisicogrond.value
    }
  },
  computed: {
    schrappingBeslistOpValue: {
      get: function () {
        return [this.getAttribute('schrappingBeslistOp').value]
      },
      set: function (date: string) {
        this.getAttribute('schrappingBeslistOp').value = new Date(Date.parse(date))
      }
    }
  },
  created () {
    this.schrappingReden.required = this.schrappingRisicogrond.value
  }
})
