import { render, staticRenderFns } from "./ippro-icon-text.html?vue&type=template&id=4b323368&external"
import script from "./ippro-icon-text.ts?vue&type=script&lang=ts&external"
export * from "./ippro-icon-text.ts?vue&type=script&lang=ts&external"
import style0 from "./ippro-icon-text.vue?vue&type=style&index=0&id=4b323368&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports