import Vue from 'vue'
import * as WebComponents from '@govflanders/vl-ui-vue-components'

export default {
  install (vue: typeof Vue): void {
    vue.use(WebComponents)
    vue.directive('vl-tooltip', WebComponents.VlTooltip)
    vue.directive('vl-modal-toggle', WebComponents.VlModalToggle)
  }
}
