import { InlichtingComponentData } from '@/components/vip-inlichting/vip-inlichting'
import vipInlichtingAttributes from '../../../vip-inlichting-attributes'
import { InlichtingAttribuut } from '@/infrastructure/bff-client/bff-client'
import mixins from 'vue-typed-mixins'
import EventBus from '@/infrastructure/events/event-bus'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-omgevingsvergunning-handeling-collection',
  data () {
    return {
      indexToRemove: null,
      handelingen: this.getAttribute('handelingen')
    }
  },
  props: {
    inlichting: Object as ()=> InlichtingComponentData,
    attributes: Array as ()=> InlichtingAttribuut[],
    addButtonLabel: {
      default: '+ Item toevoegen',
      type: String
    },
    modCanAdd: Boolean,
    minItems: {
      default: 0,
      type: Number
    },
    allwaysAllowDelete: {
      default: false,
      type: Boolean
    },
    modDisabled: Boolean
  },
  methods: {
    makeUnique (index: number) {
      // De Math random is toegevoegd omdat anders het verwijderen van de items niet correct werkt.
      return 'handeling-' + index.toString() + (Math.floor(Math.random() * (1000000 - 1 + 1) + 1)).toString()
    },
    requestRemoveByIndex (index: number) {
      this.indexToRemove = index
    },
    removeByIndex (index: number) {
      this.handelingen.value.splice(index, 1)
      this.indexToRemove = null
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    },
    handelingSelected (handelingType: string) {
      if (!this.handelingen.value) {
        this.handelingen.value = [] as InlichtingAttribuut[][]
      }

      let handelingMetadataAttributes: InlichtingAttribuut[]

      switch (handelingType.toLocaleLowerCase()) {
        case 'stedenbouwkundigehandeling':
          handelingMetadataAttributes = this.attributes.find(a => a.name === 'metadataStedenbouwkundigeHandeling').value as InlichtingAttribuut[]
          break
        case 'verkavelingvangronden':
          handelingMetadataAttributes = this.attributes.find(a => a.name === 'metadataVerkavelingVanGronden').value as InlichtingAttribuut[]
          break
        case 'wijzigenvankleinelandschapselementenofvegetatie':
          handelingMetadataAttributes = this.attributes.find(a => a.name === 'metadataWijzigenVanKleineLandschapselementenOfVegetatie').value as InlichtingAttribuut[]
          break
        case 'exploitatie':
          handelingMetadataAttributes = this.attributes.find(a => a.name === 'metadataExploitatieHandeling').value as InlichtingAttribuut[]
          break
        case 'kleinhandelsactiviteiten':
          handelingMetadataAttributes = this.attributes.find(a => a.name === 'metadataKleinhandelsactiviteit').value as InlichtingAttribuut[]
          break
      }

      if (handelingMetadataAttributes) {
        const handelingAttributesCopy = JSON.parse(JSON.stringify(handelingMetadataAttributes))
        this.getAttribute('handelingen').value.push(handelingAttributesCopy)
      }
    },
    // Wordt opgeroepen vanuit de Vue template.
    getHandelingTemplate (handeling: unknown) {
      const handelingType = (handeling as InlichtingAttribuut[]).find((i: InlichtingAttribuut) => i.name.toLowerCase() === 'HandelingtypeDiscriminator'.toLowerCase()).value
      switch (handelingType.toLocaleLowerCase()) {
        case 'stedenbouwkundigehandeling':
          return 'vip-stedenbouwkundige-handeling'
        case 'verkavelingvangronden':
          return 'vip-verkaveling-van-gronden'
        case 'wijzigenvankleinelandschapselementenofvegetatie':
          return 'vip-wijzigen-landschapselementen-vegetatie-handeling'
        case 'exploitatie':
          return 'vip-exploitatie-handeling'
        case 'kleinhandelsactiviteiten':
          return 'vip-kleinhandelsactiviteit'
        default:
          return ''
      }
    }
  }
})
