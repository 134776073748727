import { BaseTitle, IRouteConfig } from '@/infrastructure/route/vip-router'
import { Informatieaanvrager } from '@/infrastructure/constants/roles'
import { RouteDashboard } from '../dashboard-module/dashboard-routes'

export const RouteAanvraagDetail: IRouteConfig = {
  path: '/aanvragen/:aanvraagId',
  name: 'aanvraag-detail',
  meta: {
    title: `Aanvraag | ${BaseTitle}`,
    authorize: {
      roles: [Informatieaanvrager]
    },
    breadcrumb: {
      title: 'Nieuwe aanvraag',
      parent: RouteDashboard
    }
  },
  component: () => import('./aanvraag-detail.vue')
}

export default [RouteAanvraagDetail] as IRouteConfig[]
