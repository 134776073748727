import { CodelijstElement, InlichtingAttribuut } from '@/infrastructure/bff-client/bff-client'
import EventBus from '@/infrastructure/events/event-bus'
import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '../../../vip-inlichting-attributes'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-stedenbouwkundige-overtreding-procedure',
  data () {
    return {
      handhavingsinstrumenten: this.getAttribute('handhavingsinstrumenten'),
      handhavingsinstrumentType: this.getAttribute('handhavingsinstrumentType'),
      uitgeslotenHandhavingsinstrumenten: [],
      handhavingsinstrumentToevoegenActief: false,
      procedureType: this.getAttribute('procedureType'),
      verslagVastellingDatum: this.getAttribute('verslagVastellingDatum'),
      verslagVastellingNummer: this.getAttribute('verslagVastellingNummer'),
      indexToRemove: null
    }
  },
  methods: {
    getHandhavingsinstrumentenOptions (): CodelijstElement[] {
      if (this.procedureType.value === 'Misdrijf') {
        return this.handhavingsinstrumentType.options.filter(f => f.previewLabel !== 'Inbreuk')
      } else {
        return this.handhavingsinstrumentType.options.filter(f => f.previewLabel !== 'Misdrijf')
      }
    },
    handhavingsinstrumentToevoegen () {
      this.handhavingsinstrumentType.value = null
      this.handhavingsinstrumentToevoegenActief = true
    },
    handhavingsinstrumentSelectieToevoegen () {
      if (!this.handhavingsinstrumenten.value) {
        this.handhavingsinstrumenten.value = [] as InlichtingAttribuut[][]
      }
      const type = this.handhavingsinstrumentType.value.toLowerCase()
      const attributen = this.attributes.find(f => f.name.toLowerCase() === type + 'metadata').value as InlichtingAttribuut[]
      attributen.find(f => f.name === 'handhavingsinstrumentType').value = this.handhavingsinstrumentType.value
      const copy = JSON.parse(JSON.stringify(attributen))
      this.handhavingsinstrumenten.value.push(copy)
      if (/^(?:stakingsbevel|minnelijkeschikking|bestuurlijkesanctie)$/.test(type)) {
        this.setHandhavingsinstrumentUitsluiting(type)
      }
      this.handhavingsinstrumentAnnuleren()
    },
    handhavingsinstrumentAnnuleren () {
      this.handhavingsinstrumentToevoegenActief = false
      this.handhavingsinstrumentType.value = null
    },
    requestRemoveByIndex (index: number) {
      this.indexToRemove = index
    },
    removeByIndex () {
      const typeInstrument = (this.handhavingsinstrumenten.value[this.indexToRemove] as InlichtingAttribuut[]).find(f => f.name === 'handhavingsinstrumentType').value
      const checkType = this.procedureType.value.toLowerCase() + '_' + typeInstrument.toLowerCase()
      if (this.uitgeslotenHandhavingsinstrumenten.includes(checkType)) {
        this.uitgeslotenHandhavingsinstrumenten = this.uitgeslotenHandhavingsinstrumenten.filter(f => f.toString() !== checkType)
      }
      this.handhavingsinstrumenten.value.splice(this.indexToRemove, 1)
      this.indexToRemove = null
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    },
    cancelRemove () {
      this.indexToRemove = null
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    },
    setHandhavingsinstrumentUitsluiting (instrument: string) {
      this.uitgeslotenHandhavingsinstrumenten.push(this.procedureType.value.toLowerCase() + '_' + instrument.toLowerCase())
    },
    getHandhavingsInstrumentTemplate (instrument: InlichtingAttribuut[]): string {
      const type = instrument.find(f => f.name === 'handhavingsinstrumentType').value
      return 'vip-stedenbouwkundige-overtreding-' + type.toLowerCase().replace('bestuurlijke', '')
    }
  },
  mounted () {
    if (this.handhavingsinstrumenten.value && this.handhavingsinstrumenten.value.length > 0) {
      this.handhavingsinstrumentToevoegenActief = false
      for (const instrument of this.handhavingsinstrumenten.value) {
        const type = (instrument as InlichtingAttribuut[]).find(f => f.name === 'handhavingsinstrumentType').value.toLowerCase()
        if (['stakingsbevel', 'minnelijkeschikking', 'bestuurlijkesanctie'].indexOf(type) >= 0) {
          this.setHandhavingsinstrumentUitsluiting(type)
        }
      }
    }
    this.verslagVastellingDatum.required = this.procedureType.value === 'Inbreuk'
    this.verslagVastellingNummer.required = this.procedureType.value === 'Inbreuk'
  }
})
