import { render, staticRenderFns } from "./vip-inlichting-preview-template-besluit-burgemeester-openbare-veiligheid.vue?vue&type=template&id=edbe2e6c"
import script from "./vip-inlichting-preview-template-besluit-burgemeester-openbare-veiligheid.ts?vue&type=script&lang=ts&external"
export * from "./vip-inlichting-preview-template-besluit-burgemeester-openbare-veiligheid.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports