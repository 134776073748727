import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '../../../vip-inlichting-attributes'

enum BeslissingToevoegenStep {
  Init = 1,
  KiesBeslissingType
}

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-omgevingsvergunning-beslissingtype',
  data () {
    return {
      beslissingen: this.getAttribute('beslissingen'),
      beslissingType: null,
      BeslissingTypeAttribuut: this.getAttribute('BeslissingDiscriminator'),
      currentBeslissingToevoegenStep: BeslissingToevoegenStep.Init,
      addButtonLabel: '+ Beslissing toevoegen'
    }
  },
  methods: {
    initBeslissingen: function () {
      return this.currentBeslissingToevoegenStep === BeslissingToevoegenStep.Init
    },
    showBeslissingenType: function () {
      return this.currentBeslissingToevoegenStep === BeslissingToevoegenStep.KiesBeslissingType
    },
    showBeslissingenToevoegen: function () {
      return this.beslissingType != null
    },
    BeslissingenToevoegenClicked: function () {
      this.currentBeslissingToevoegenStep = BeslissingToevoegenStep.KiesBeslissingType
    },
    cancelBeslissingenRubriekTypeClicked: function () {
      this.beslissingType = null
      this.BeslissingTypeAttribuut.value = null
      this.currentBeslissingToevoegenStep = BeslissingToevoegenStep.Init
    },
    addBeslissingenRubriekTypeClicked: function () {
      this.$emit('beslissingSelected', this.beslissingType as string)
      this.beslissingType = null
      this.currentBeslissingToevoegenStep = BeslissingToevoegenStep.Init
    }
  }
})
