import { IRouteConfig } from '@/infrastructure/route/vip-router'
import Vue from 'vue'

interface BreadCrumb {
  label: string;
  route: IRouteConfig;
}

export default Vue.extend({
  name: 'VipBreadcrumb',
  data () {
    return {
      breadcrumbs: [] as BreadCrumb[]
    }
  },
  methods: {
    parseTitle (meta: any) {
      let title = meta.breadcrumb.title || meta.title
      if (this.$route.params.vipReferentie) {
        title = title.replace(':vipReferentie', this.$route.params.vipReferentie)
      }
      return title
    }
  },
  watch: {
    '$route.meta.breadcrumb': {
      immediate: true,
      handler () {
        const breadcrumbs: BreadCrumb[] = []
        if (this.$route.meta && this.$route.meta.breadcrumb) {
          if (this.$route.meta.breadcrumb.title || this.$route.meta.title) {
            breadcrumbs.push({
              route: null,
              label: this.parseTitle(this.$route.meta)
            })
          }

          let parent = this.$route.meta.breadcrumb.parent
          let breakClause = false
          while (parent && !breakClause) {
            const parentRoute = this.$router.resolve(parent)
            if (parentRoute.resolved?.meta?.breadcrumb) {
              breadcrumbs.push({
                route: parentRoute.resolved,
                label: this.parseTitle(parentRoute.resolved.meta)
              })
              parent = parentRoute?.route?.meta?.breadcrumb?.parent
            } else {
              breakClause = true
            }
          }
          this.breadcrumbs = breadcrumbs.reverse()
          this.$set(this.breadcrumbs, 'breadcrumbs', breadcrumbs.reverse)
        }
      }
    }
  }
})
