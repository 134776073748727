import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class IpproMapFullscreen extends Vue {
  @Prop({ type: Boolean, default: false })
  public initActive: boolean

  public active = false

  @Watch('active')
  public onFullscreenActiveChanged (active: boolean) {
    this.$emit('change', active)
  }

  @Watch('initActive')
  public onInitActiveChanged (active: boolean) {
    this.active = active
  }

  get fullscreenIcon () {
    return this.active ? 'shrink' : 'expand'
  }

  get fullscreenLabel () {
    return this.active
      ? 'Sluit&nbsp;volledig&nbsp;scherm'
      : 'Volledig&nbsp;scherm'
  }

  public toggleFullscreen () {
    this.active = !this.active
  }

  public fullscreenKeyboardHandler (event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.active = false
    }
  }

  public mounted () {
    window.addEventListener('keyup', this.fullscreenKeyboardHandler)
    if (this.initActive) {
      this.active = true
    }
  }

  public beforeDestroy () {
    window.removeEventListener('keyup', this.fullscreenKeyboardHandler)
  }
}
