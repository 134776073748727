import vipInlichtingAttributes from '../../../vip-inlichting-attributes'
import { InlichtingAttribuut } from '@/infrastructure/bff-client/bff-client'
import { InlichtingComponentData } from '@/components/vip-inlichting/vip-inlichting'
import mixins from 'vue-typed-mixins'
import EventBus from '@/infrastructure/events/event-bus'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-stedenbouwkundige-overtreding-procedure-collection',
  data () {
    return {
      indexToRemove: null,
      procedures: this.getAttribute('procedures'),
      voorDecreetProcedures: [] as InlichtingAttribuut[][],
      naDecreetProcedures: [] as InlichtingAttribuut[][]
    }
  },
  props: {
    inlichting: Object as ()=> InlichtingComponentData,
    attributes: Array as ()=> InlichtingAttribuut[],
    naDecreet: { type: Boolean, required: true },
    modDisabled: Boolean,
    addMisdrijf: Boolean
  },
  methods: {
    getProcedures (): InlichtingAttribuut[][] {
      if (!this.procedures.value) {
        return []
      }
      return this.naDecreet ? this.naDecreetProcedures : this.voorDecreetProcedures
    },
    makeUnique (index: number) {
      // De Math random is toegevoegd omdat anders het verwijderen van de items niet correct werkt.
      return 'procedure-' + index.toString() + (Math.floor(Math.random() * (1000000 - 1 + 1) + 1)).toString()
    },
    requestRemoveByIndex (index: number) {
      this.indexToRemove = index
    },
    removeByIndex (index: number) {
      this.procedures.value.splice(index, 1)
      this.indexToRemove = null
      this.setDecreetProcedures()
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    },
    cancelRemove () {
      this.indexToRemove = null
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    },
    addProcedure (misdrijf: boolean) {
      if (!this.procedures.value) {
        this.procedures.value = [] as InlichtingAttribuut[][]
      }

      const metadataAttributes = this.attributes.find(f => f.name === 'proceduresMetaData').value as InlichtingAttribuut[]

      if (metadataAttributes !== undefined) {
        metadataAttributes.find(f => f.name === 'procedureType').value = misdrijf ? 'Misdrijf' : 'Inbreuk'
        metadataAttributes.find(f => f.name === 'naDecreet').value = this.naDecreet
        const copy = JSON.parse(JSON.stringify(metadataAttributes))
        this.procedures.value.push(copy)
        this.setDecreetProcedures()
      }
    },
    setDecreetProcedures () {
      if (!this.procedures.value) {
        this.procedures.value = [] as InlichtingAttribuut[][]
      }
      this.voorDecreetProcedures = this.procedures.value.filter(f => f.some(p => p.name === 'naDecreet' && p.value === false))
      this.naDecreetProcedures = this.procedures.value.filter(f => f.some(p => p.name === 'naDecreet' && p.value === true))
    }
  },
  watch: {
    addMisdrijf: {
      handler (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.addProcedure(true)
        }
      }
    }
  },
  mounted () {
    this.setDecreetProcedures()
  }
})
