import { GuiInlichtingRubriek, TypeInlichting } from '@/infrastructure/bff-client/bff-client'

export interface InlichtingInfo {
  inlichtingType: TypeInlichting,
  inlichtingTypeApi: string,
  label: string,
  mandatory?: boolean
}

export interface InlichtingInfoGroup {
  group: string,
  label: string,
  inlichtingen: InlichtingInfo[]
}

// eslint-disable-next-line func-call-spacing
export const inlichtingTypeCanAddByRubriek = new Map<GuiInlichtingRubriek, (InlichtingInfo | InlichtingInfoGroup)[]>([
  [GuiInlichtingRubriek.Overheidsplannen, [
    { inlichtingType: TypeInlichting.Gewestplan, inlichtingTypeApi: 'GEWESTPLAN', label: 'Gewestplan', mandatory: true },
    { inlichtingType: TypeInlichting.AlgemeenPlanVanAanleg, inlichtingTypeApi: 'ALGEMEEN_PLAN_VAN_AANLEG', label: 'Algemeen plan van aanleg', mandatory: true },
    { inlichtingType: TypeInlichting.BijzonderPlanVanAanleg, inlichtingTypeApi: 'BIJZONDER_PLAN_VAN_AANLEG', label: 'Bijzonder plan van aanleg', mandatory: true },
    {
      group: 'RuimtelijkUitvoeringsplan',
      label: 'Ruimtelijk uitvoeringsplan',
      inlichtingen: [
        { inlichtingType: TypeInlichting.GewestelijkRuimtelijkUitvoeringsplan, inlichtingTypeApi: 'GEWESTELIJK_RUIMTELIJK_UITVOERINGSPLAN', label: 'Gewestelijk', mandatory: true },
        { inlichtingType: TypeInlichting.ProvinciaalRuimtelijkUitvoeringsplan, inlichtingTypeApi: 'PROVINCIAAL_RUIMTELIJK_UITVOERINGSPLAN', label: 'Provinciaal', mandatory: true },
        { inlichtingType: TypeInlichting.GemeentelijkRuimtelijkUitvoeringsplan, inlichtingTypeApi: 'GEMEENTELIJK_RUIMTELIJK_UITVOERINGSPLAN', label: 'Gemeentelijk', mandatory: true }
      ]
    },
    {
      group: 'Verordening',
      label: 'Verordening',
      inlichtingen: [
        { inlichtingType: TypeInlichting.GewestelijkeVerordening, inlichtingTypeApi: 'GEWESTELIJKE_VERORDENING', label: 'Gewestelijk', mandatory: true },
        { inlichtingType: TypeInlichting.ProvincialeVerordening, inlichtingTypeApi: 'PROVINCIALE_VERORDENING', label: 'Provinciaal', mandatory: true },
        { inlichtingType: TypeInlichting.GemeentelijkeVerordening, inlichtingTypeApi: 'GEMEENTELIJKE_VERORDENING', label: 'Gemeentelijk', mandatory: true }
      ]
    },
    { inlichtingType: TypeInlichting.GemeentelijkRooilijnplan, inlichtingTypeApi: 'GEMEENTELIJK_ROOILIJNPLAN', label: 'Gemeentelijk rooilijnplan', mandatory: true },
    { inlichtingType: TypeInlichting.GemeentelijkOnteigeningsplan, inlichtingTypeApi: 'GEMEENTELIJK_ONTEIGENINGSPLAN', label: 'Gemeentelijk onteigeningsplan', mandatory: true },
    { inlichtingType: TypeInlichting.RuilOfHerverkavelingsplan, inlichtingTypeApi: 'RUIL_OF_HERVERKAVELINGSPLAN', label: 'Ruil- of herverkavelingsplan' },
    { inlichtingType: TypeInlichting.ComplexProject, inlichtingTypeApi: 'COMPLEX_PROJECT', label: 'Complex project' },
    { inlichtingType: TypeInlichting.PlanbatenOfSchade, inlichtingTypeApi: 'PLANBATEN_OF_SCHADE', label: 'Planbaten- of schade' },
    { inlichtingType: TypeInlichting.VoorwaardenVanuitEenGemeentelijkeAkte, inlichtingTypeApi: 'VOORWAARDEN_VANUIT_EEN_GEMEENTELIJKE_AKTE', label: 'Voorwaarden vanuit een gemeentelijke akte' },
    { inlichtingType: TypeInlichting.Beleidsplan, inlichtingTypeApi: 'BELEIDSPLAN', label: 'Beleidsplan' },
    { inlichtingType: TypeInlichting.SoortWegWaarlangsPerceelGelegenIsV2, inlichtingTypeApi: 'SOORT_WEG_WAARLANGS_PERCEEL_GELEGEN_IS', label: 'Soort weg waarlangs het perceel is gelegen' }
  ]],
  [GuiInlichtingRubriek.Vergunningen, [
    { inlichtingType: TypeInlichting.AsBuiltAttest, inlichtingTypeApi: 'AS_BUILT_ATTEST', label: 'As-built attest' },
    { inlichtingType: TypeInlichting.Milieuvergunning, inlichtingTypeApi: 'MILIEUVERGUNNING', label: 'Milieuvergunning', mandatory: true },
    { inlichtingType: TypeInlichting.Omgevingsvergunning, inlichtingTypeApi: 'OMGEVINGSVERGUNNING', label: 'Omgevingsvergunning', mandatory: true },
    { inlichtingType: TypeInlichting.PlanologischAttest, inlichtingTypeApi: 'PLANOLOGISCH_ATTEST', label: 'Planologisch attest' },
    { inlichtingType: TypeInlichting.Splitsing, inlichtingTypeApi: 'SPLITSING', label: 'Splitsing' },
    { inlichtingType: TypeInlichting.StedenbouwkundigAttest, inlichtingTypeApi: 'STEDENBOUWKUNDIG_ATTEST', label: 'Stedenbouwkundig attest', mandatory: true },
    { inlichtingType: TypeInlichting.StedenbouwkundigeMelding, inlichtingTypeApi: 'STEDENBOUWKUNDIGE_MELDING', label: 'Stedenbouwkundige melding', mandatory: true },
    { inlichtingType: TypeInlichting.StedenbouwkundigeOvertreding, inlichtingTypeApi: 'STEDENBOUWKUNDIGE_OVERTREDING', label: 'Stedenbouwkundige overtreding', mandatory: true },
    { inlichtingType: TypeInlichting.StedenbouwkundigeVergunning, inlichtingTypeApi: 'STEDENBOUWKUNDIGE_VERGUNNING', label: 'Stedenbouwkundige vergunning', mandatory: true },
    { inlichtingType: TypeInlichting.Verkavelingsvergunning, inlichtingTypeApi: 'VERKAVELINGSVERGUNNING', label: 'Verkavelingsvergunning', mandatory: true },
    { inlichtingType: TypeInlichting.VergundGeachteGebouwen, inlichtingTypeApi: 'VERGUND_GEACHTE_GEBOUWEN', label: 'Vergund geachte gebouwen' },
    { inlichtingType: TypeInlichting.Vergunningstoestand, inlichtingTypeApi: 'VERGUNNINGSTOESTAND', label: 'Vergunningstoestand' }
  ]],
  [GuiInlichtingRubriek.Natuur, [{ inlichtingType: TypeInlichting.Kapmachtiging, inlichtingTypeApi: 'KAPMACHTIGING', label: 'Gemeentelijke kapmachtiging' }]],
  [GuiInlichtingRubriek.Erfdienstbaarheden, [{ inlichtingType: TypeInlichting.WettelijkeErfdienstbaarheidVanOpenbaarNut, inlichtingTypeApi: 'WETTELIJKE_ERFDIENSTBAARHEID_VAN_OPENBAAR_NUT', label: 'Wettelijke erfdienstbaarheid van openbaar nut' }]],
  [GuiInlichtingRubriek.GrondEnPandenBeleid, [
    { inlichtingType: TypeInlichting.AankoopOfGebruikGemeentegrond, inlichtingTypeApi: 'AANKOOP_OF_GEBRUIK_GEMEENTEGROND', label: 'Aankoop of gebruik gemeentegrond' },
    { inlichtingType: TypeInlichting.BesluitBurgemeesterInzakeOpenbareVeiligheid, inlichtingTypeApi: 'BESLUIT_BURGEMEESTER_INZAKE_OPENBARE_VEILIGHEID', label: 'Besluit van de Burgemeester inzake openbare veiligheid' },
    { inlichtingType: TypeInlichting.LeegstaandeEnVerwaarloosdeGebouwen, inlichtingTypeApi: 'LEEGSTAANDE_EN_VERWAARLOOSDE_GEBOUWEN', label: 'Leegstaand en/of verwaarloosd gebouw', mandatory: true },
    { inlichtingType: TypeInlichting.LeegstaandeEnVerwaarloosdeBedrijfsruimten, inlichtingTypeApi: 'LEEGSTAANDE_EN_VERWAARLOOSDE_BEDRIJFSRUIMTEN', label: 'Leegstaande en/of verwaarloosde bedrijfsruimte' },
    { inlichtingType: TypeInlichting.OnbebouwdePercelen, inlichtingTypeApi: 'ONBEBOUWDE_PERCELEN', label: 'Onbebouwde perceel' },
    { inlichtingType: TypeInlichting.VerbodTotPermanentWonen, inlichtingTypeApi: 'VERBOD_TOT_PERMANENT_WONEN', label: 'Verbod tot permanent wonen' }
  ]],
  [GuiInlichtingRubriek.Milieu, [{ inlichtingType: TypeInlichting.Risicogrond, inlichtingTypeApi: 'RISICOGROND', label: 'Risicogrond', mandatory: true }]],
  [GuiInlichtingRubriek.GemeentelijkeHeffingen, [{ inlichtingType: TypeInlichting.GemeentelijkeHeffing, inlichtingTypeApi: 'GEMEENTELIJKE_HEFFING', label: 'Gemeentelijke heffing' }]],
  [GuiInlichtingRubriek.OnroerendErfgoed, [{ inlichtingType: TypeInlichting.LokaalOnroerendErfgoed, inlichtingTypeApi: 'LOKAAL_ONROEREND_ERFGOED', label: 'Lokaal onroerend erfgoed' }]]
])

export const allDeletableInlichtingenByRubriek = (rubriek: GuiInlichtingRubriek): InlichtingInfo[] => {
  return Array.from(inlichtingTypeCanAddByRubriek.get(rubriek).values()).flatMap(item =>
    'inlichtingen' in item ? item.inlichtingen : [item]
  )
}

export enum InlichtingType {
  GEWESTPLAN = 'GEWESTPLAN',
  ALGEMEEN_PLAN_VAN_AANLEG = 'ALGEMEEN_PLAN_VAN_AANLEG',
  BIJZONDER_PLAN_VAN_AANLEG = 'BIJZONDER_PLAN_VAN_AANLEG',
  GEWESTELIJK_RUIMTELIJK_UITVOERINGSPLAN = 'GEWESTELIJK_RUIMTELIJK_UITVOERINGSPLAN',
  PROVINCIAAL_RUIMTELIJK_UITVOERINGSPLAN = 'PROVINCIAAL_RUIMTELIJK_UITVOERINGSPLAN',
  GEMEENTELIJK_RUIMTELIJK_UITVOERINGSPLAN = 'GEMEENTELIJK_RUIMTELIJK_UITVOERINGSPLAN',
  GEWESTELIJKE_VERORDENING = 'GEWESTELIJKE_VERORDENING',
  PROVINCIALE_VERORDENING = 'PROVINCIALE_VERORDENING',
  GEMEENTELIJKE_VERORDENING = 'GEMEENTELIJKE_VERORDENING',
  GEMEENTELIJK_ROOILIJNPLAN = 'GEMEENTELIJK_ROOILIJNPLAN',
  GEMEENTELIJK_ONTEIGENINGSPLAN = 'GEMEENTELIJK_ONTEIGENINGSPLAN',
  RECHT_VAN_VOORKOOP = 'RECHT_VAN_VOORKOOP',
  RUIL_OF_HERVERKAVELINGSPLAN = 'RUIL_OF_HERVERKAVELINGSPLAN',
  COMPLEX_PROJECT = 'COMPLEX_PROJECT',
  PLANBATEN_OF_SCHADE = 'PLANBATEN_OF_SCHADE',
  VOORWAARDEN_VANUIT_EEN_GEMEENTELIJKE_AKTE = 'VOORWAARDEN_VANUIT_EEN_GEMEENTELIJKE_AKTE',
  BELEIDSPLAN = 'BELEIDSPLAN',
  SOORT_WEG_WAARLANGS_PERCEEL_GELEGEN_IS = 'SOORT_WEG_WAARLANGS_PERCEEL_GELEGEN_IS',
  STEDENBOUWKUNDIGE_VERGUNNING = 'STEDENBOUWKUNDIGE_VERGUNNING',
  STEDENBOUWKUNDIGE_MELDING = 'STEDENBOUWKUNDIGE_MELDING',
  VERKAVELINGSVERGUNNING = 'VERKAVELINGSVERGUNNING',
  VERGUND_GEACHTE_GEBOUWEN = 'VERGUND_GEACHTE_GEBOUWEN',
  MILIEUVERGUNNING = 'MILIEUVERGUNNING',
  OMGEVINGSVERGUNNING = 'OMGEVINGSVERGUNNING',
  STEDENBOUWKUNDIGE_OVERTREDING = 'STEDENBOUWKUNDIGE_OVERTREDING',
  STEDENBOUWKUNDIG_ATTEST = 'STEDENBOUWKUNDIG_ATTEST',
  AS_BUILT_ATTEST = 'AS_BUILT_ATTEST',
  PLANOLOGISCH_ATTEST = 'PLANOLOGISCH_ATTEST',
  SPLITSING = 'SPLITSING',
  VERGUNNINGSTOESTAND = 'VERGUNNINGSTOESTAND',
  ZONERINGSPLAN = 'ZONERINGSPLAN',
  RISICOGROND = 'RISICOGROND',
  VOGELRICHTLIJNGEBIED = 'VOGELRICHTLIJNGEBIED',
  HABITATRICHTLIJNGEBIED = 'HABITATRICHTLIJNGEBIED',
  VEN_EN_IVON_GEBIED = 'VEN_EN_IVON_GEBIED',
  BESCHERMD_DUINGEBIED = 'BESCHERMD_DUINGEBIED',
  BESCHERMD_WATERWINNINGSGEBIED = 'BESCHERMD_WATERWINNINGSGEBIED',
  OVERSTROMINGSGEVOELIGHEID = 'OVERSTROMINGSGEVOELIGHEID',
  OVERSTROMINGSGEBIED_EN_OEVERZONE = 'OVERSTROMINGSGEBIED_EN_OEVERZONE',
  RISICOZONE_VOOR_OVERSTROMINGEN = 'RISICOZONE_VOOR_OVERSTROMINGEN',
  SIGNAALGEBIED = 'SIGNAALGEBIED',
  NATUURBEHEERPLAN = 'NATUURBEHEERPLAN',
  KAPMACHTIGING = 'KAPMACHTIGING',
  NATUURINRICHTINGSPROJECT = 'NATUURINRICHTINGSPROJECT',
  GRONDVERSCHUIVINGSGEVOELIGHEID = 'GRONDVERSCHUIVINGSGEVOELIGHEID',
  ONBEWOONBARE_EN_ONGESCHIKTE_WONING = 'ONBEWOONBARE_EN_ONGESCHIKTE_WONING',
  HERSTELVORDERING = 'HERSTELVORDERING',
  CONFORMITEITSATTEST = 'CONFORMITEITSATTEST',
  LEEGSTAANDE_EN_VERWAARLOOSDE_GEBOUWEN = 'LEEGSTAANDE_EN_VERWAARLOOSDE_GEBOUWEN',
  LEEGSTAANDE_EN_VERWAARLOOSDE_BEDRIJFSRUIMTEN = 'LEEGSTAANDE_EN_VERWAARLOOSDE_BEDRIJFSRUIMTEN',
  BROWNFIELD = 'BROWNFIELD',
  BEDRIJVENTERREIN = 'BEDRIJVENTERREIN',
  ONBEBOUWDE_PERCELEN = 'ONBEBOUWDE_PERCELEN',
  AANKOOP_OF_GEBRUIK_GEMEENTEGROND = 'AANKOOP_OF_GEBRUIK_GEMEENTEGROND',
  BESLUIT_BURGEMEESTER_INZAKE_OPENBARE_VEILIGHEID = 'BESLUIT_BURGEMEESTER_INZAKE_OPENBARE_VEILIGHEID',
  VERBOD_TOT_PERMANENT_WONEN = 'VERBOD_TOT_PERMANENT_WONEN',
  ONROEREND_ERFGOED = 'ONROEREND_ERFGOED',
  LOKAAL_ONROEREND_ERFGOED = 'LOKAAL_ONROEREND_ERFGOED',
  WETTELIJKE_ERFDIENSTBAARHEID_VAN_OPENBAAR_NUT = 'WETTELIJKE_ERFDIENSTBAARHEID_VAN_OPENBAAR_NUT',
  GEMEENTELIJKE_HEFFING = 'GEMEENTELIJKE_HEFFING',
}
