import Vue, { PropType } from 'vue'
import { generateKey } from '@/infrastructure/helpers/general'
import { AutoCompleteResult, AutoCompleteSelection } from '@/components/ippro/vl-ippro-autocomplete/ippro-autocomplete'
import IpproAutocomplete from '@/components/ippro/vl-ippro-autocomplete/ippro-autocomplete.vue'

export default Vue.extend({
  name: 'vip-suggestie-input',
  props: {
    data: {
      type: Array as PropType<string[]>,
      default: () => [] as string[]
    },
    value: String,
    minLength: Number,
    limitWidth: {
      type: Boolean,
      default: true
    }
  },
  components: {
    IpproAutocomplete
  },
  data () {
    return {
      searchComponentKey: generateKey(),
      convertedOptions: [] as AutoCompleteResult[],
      inputValue: this.value,
      inputClass: this.limitWidth ? 'map-search' : 'form-search'
    }
  },
  methods: {
    convertToAutoCompleteResult (label: string): AutoCompleteResult {
      const selection = { obj: label, name: label } as AutoCompleteSelection
      return { title: label, value: selection } as AutoCompleteResult
    },
    setSelected (item: AutoCompleteSelection) {
      if (item === undefined || item.name === undefined || item === null || item.name === null) {
        return
      }
      this.inputValue = item.name
      this.$emit('input', this.inputValue)
    },
    loadData () {
      this.convertedOptions = this.data.map(m => this.convertToAutoCompleteResult(m))
    },
    onInput () {
      this.$emit('input', this.inputValue)
      if (this.inputValue.length > this.minLength) {
        this.convertedOptions = this.data.filter(f => f.toLowerCase().includes(this.inputValue.toLowerCase())).map(m => this.convertToAutoCompleteResult(m))
      } else {
        this.convertedOptions = this.data.map(m => this.convertToAutoCompleteResult(m))
      }
    }
  }
})
