import { Vue } from 'vue-property-decorator'

export default Vue.extend({
  name: 'vip-footer',
  data () {
    return {
      testEnvironment: false
    }
  },
  mounted () {
    const scriptTag = document.createElement('script')
    scriptTag.src = 'https://cdn.nolt.io/widgets.js'
    scriptTag.id = 'nolt-feedback'
    scriptTag.async = true
    document.getElementsByTagName('head')[0].appendChild(scriptTag)
    const scriptTag2 = document.createElement('script')
    scriptTag2.append(document.createTextNode('window.noltQueue=window.noltQueue||[];function nolt(){noltQueue.push(arguments)}'))
    scriptTag2.append(document.createTextNode('nolt(\'init\', { url: \'https://vastgoedinformatieplatform.nolt.io\', selector: \'#nolt-feedback\'});'))
    scriptTag.id = 'nolt-feedback2'
    document.getElementsByTagName('head')[0].appendChild(scriptTag2)
    if (config.VUE_APP_ENV !== 'production') {
      this.testEnvironment = true
    }
  }
})
