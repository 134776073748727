import vipInlichtingAttributes from '../../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-stedenbouwkundige-overtreding-schikking',
  data () {
    return {
      datumAanvraag: this.getAttribute('datumAanvraag'),
      omschrijving: this.getAttribute('omschrijving'),
      betrokkenPartijen: this.getAttribute('betrokkenPartijen')
    }
  }
})
