import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-planologisch-attest',
  data () {
    return {
      beschrijving: this.getAttribute('beschrijving'),
      referentie: this.getAttribute('referentie'),
      dossierNummer: this.getAttribute('dossierNummer'),
      advies: this.getAttribute('aardAdvies'),
      afgeleverdOp: this.getAttribute('datumAdvies'),
      beslissing: this.getAttribute('aardBeslissing'),
      beslistOp: this.getAttribute('datumBeslissing'),
      externeDocumentatie: this.getAttribute('externeDocumentatie'),
      bijlagen2: this.getAttribute('externeDocumentatie')
    }
  }
})
