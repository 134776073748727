import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '../../../vip-inlichting-attributes'

enum HandelingToevoegenStep {
  Init = 1,
  KiesHandelingType
}

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-omgevingsvergunning-handelingtype',
  data () {
    return {
      handelingen: this.getAttribute('handelingen'),
      handelingType: null,
      handelingTypeAttribuut: this.getAttribute('HandelingtypeDiscriminator'),
      currentHandelingToevoegenStep: HandelingToevoegenStep.Init,
      addButtonLabel: '+ Projectelement toevoegen'
    }
  },
  methods: {
    initHandelingen () {
      return this.currentHandelingToevoegenStep === HandelingToevoegenStep.Init
    },
    showHandelingenType () {
      return this.currentHandelingToevoegenStep === HandelingToevoegenStep.KiesHandelingType
    },
    showHandelingenToevoegen () {
      return this.handelingType != null
    },
    handelingenToevoegenClicked () {
      this.currentHandelingToevoegenStep = HandelingToevoegenStep.KiesHandelingType
    },
    cancelHandelingenRubriekTypeClicked () {
      this.handelingType = null
      this.handelingTypeAttribuut.value = null
      this.currentHandelingToevoegenStep = HandelingToevoegenStep.Init
    },
    addHandelingenRubriekTypeClicked () {
      this.$emit('handelingSelected', this.handelingType as string)
      this.handelingType = null
      this.currentHandelingToevoegenStep = HandelingToevoegenStep.Init
    }
  },
  created () {
    if (!this.handelingen.value || this.handelingen.value.length === 0) {
      this.currentHandelingToevoegenStep = HandelingToevoegenStep.KiesHandelingType
    }
  }
})
