import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '@/components/vip-inlichting/components/vip-inlichting-attributes/vip-inlichting-attributes'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-lot-informatie',
  data () {
    return {
      nummer: this.getAttribute('lot_nummer'),
      datum: this.getAttribute('lot_datum'),
      motivering: this.getAttribute('lot_motivering'),
      status: this.getAttribute('lot_status')
    }
  }
})
