import Vue from 'vue'

declare const BUILD_VERSION: string

export default Vue.extend({
  name: 'VipEnvironment',
  data () {
    return {
      envStyling: '',
      message: ''
    }
  },
  mounted () {
    this.envStyling = config.VUE_APP_ENV
    if (this.envStyling === 'production') {
      this.envStyling = ''
    } else if (this.envStyling !== undefined) {
      this.message = this.envStyling.charAt(0).toLocaleUpperCase() + this.envStyling.substring(1)
      if (BUILD_VERSION !== '') {
        this.message += ' ' + BUILD_VERSION
      }
    }
  }
})
