import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-omgevingsvergunning',
  data () {
    return {
      beschrijving: this.getAttribute('beschrijving'),
      omvnummer: this.getAttribute('omvnummer'),
      projectType: this.getAttribute('projectType'),
      voorwaarden: this.getAttribute('voorwaarden'),
      handelingen: this.getAttribute('handelingen'),
      lopendeProcedure: this.getAttribute('lopendeProcedure')
    }
  }
})
