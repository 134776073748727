import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-lokaal-onroerend-erfgoed',
  data () {
    return {
      beschrijving: this.getAttribute('beschrijving'),
      referentie: this.getAttribute('referentie'),
      aanduidingsType: this.getAttribute('aanduidingsType'),
      geldigheidStartdatum: this.getDatePeriodFromAttribute('geldigPeriode'),
      geldigheidEinddatum: this.getDatePeriodUntilAttribute('geldigPeriode'),
      link: this.getAttribute('externeDocumentatie'),
      gevolg: this.getAttribute('gevolg'),
      toelichting: this.getAttribute('toelichting'),
      bijlage: this.getAttribute('bijlagen'),
      aanduidingsTypeOptions: []
    }
  },
  mounted () {
    this.aanduidingsTypeOptions = this.aanduidingsType.options.map(m => m.label)
  }
})
