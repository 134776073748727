import { InlichtingComponentData } from '@/components/vip-inlichting/vip-inlichting'
import { InlichtingAttribuut } from '@/infrastructure/bff-client/bff-client'
import EventBus from '@/infrastructure/events/event-bus'
import { generateKey } from '@/infrastructure/helpers/general'
import Vue from 'vue'

export interface CollectionItem {
  key: string;
  attributes: InlichtingAttribuut[]
}

export default Vue.extend({
  name: 'vip-inlichting-attribute-collection',
  data () {
    return {
      itemToRemove: '',
      userCollection: [] as CollectionItem[],
      collectionAttributes: [] as InlichtingAttribuut[]
    }
  },
  props: {
    inlichting: Object as ()=> InlichtingComponentData,
    attributes: Array as ()=> InlichtingAttribuut[],
    // De attributen die samen 1 entiteit vormen, bv. een beroepsprocedure.
    fields: {
      default: (): string[] => [],
      type: Array
    },
    template: {
      type: String,
      required: true
    },
    addButtonLabel: {
      default: '+ Item toevoegen',
      type: String
    },
    modCanAdd: Boolean,
    minItems: {
      default: 0,
      type: Number
    },
    allwaysAllowDelete: {
      default: false,
      type: Boolean
    },
    modDisabled: Boolean
  },
  watch: {
    userCollection: {
      handler () {
        const value: InlichtingAttribuut[][] = []
        this.userCollection.forEach(item => value.push(item.attributes))
        this.$emit('input', value)
      }
    }
  },
  methods: {
    addItem () {
      this.userCollection.push({
        key: generateKey(),
        attributes: this.collectionAttributes.map(attribute => ({
          ...attribute,
          value: null
        }))
      })
      this.$emit('add-attribute-collection-item')
    },
    requestRemove (key: string) {
      this.itemToRemove = key
    },
    remove (key: string) {
      this.userCollection = this.userCollection.filter(item => item.key !== key)
      this.$emit('remove-attribute-collection-item', key)
      this.dismissModal()
    },
    removeAll () {
      this.userCollection = []
    },
    dismissModal () {
      this.itemToRemove = ''
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }
  },
  mounted () {
    // Verzamel de attributen, die samen 1 entiteit vormen, op basis van de fields die als property zijn meegegeven.
    this.collectionAttributes = this.attributes.filter(attribute => this.fields.includes(attribute.name))
    if (this.$attrs.value && this.$attrs.value.length > 0) {
      // The value is gevuld met een collectie van de attributen die samen een entiteit vormen.
      // Bv.
      // value[0][0]= attribuut1
      // value[0][1]= attribuut2
      // value[1][0]= attribuut1
      // value[1][1]= attribuut2
      // Voeg deze collectie toe in de lijst om ze te tonen aan de gebruiker.
      this.userCollection = JSON.parse(JSON.stringify(this.$attrs.value)).map((item: InlichtingAttribuut[]) => ({
        attributes: item,
        key: generateKey()
      }))
    } else if (this.minItems && this.minItems > 0) {
      // Er zijn nog geen collectie items.  Voeg een lege toe met de attributen die samen een entiteit vormen (bv. een beroepsprocedure).
      this.userCollection.push({
        attributes: this.collectionAttributes,
        key: generateKey()
      })
    }
  }
})
