import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '@/components/vip-inlichting/components/vip-inlichting-attributes/vip-inlichting-attributes'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-wijzigen-landschapselementen-vegetatie-handeling',
  data () {
    return {
      omschrijving: this.getAttribute('omschrijving')
    }
  }
})
