import vipInlichtingAttributes from '../../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-soort-weg',
  data () {
    return {
      beherendeInstantie: this.getAttribute('beherendeInstantie'),
      beschrijving: this.getAttribute('beschrijving')
    }
  }
})
