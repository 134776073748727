import vipInlichtingAttributes from '../../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-stedenbouwkundige-overtreding-stakingsbevel',
  data () {
    return {
      datum: this.getAttribute('datum'),
      datumBekrachtiging: this.getAttribute('datumBekrachtiging'),
      datumDoorbreking: this.getAttribute('datumDoorbreking')
    }
  }
})
