import { render, staticRenderFns } from "./vip-preview-stedenbouwkundige-overtreding-herstelmaatregel.vue?vue&type=template&id=2a9e3fba"
import script from "./vip-preview-stedenbouwkundige-overtreding-herstelmaatregel.ts?vue&type=script&lang=ts&external"
export * from "./vip-preview-stedenbouwkundige-overtreding-herstelmaatregel.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports