import { Administrator, FinancieelBeheerder, Informatieaanvrager, Informatieleverancier, Kwaliteitscontroleur } from '@/infrastructure/constants/roles'
import { BaseTitle, IRouteConfig } from '@/infrastructure/route/vip-router'

export const RouteLogin: IRouteConfig = {
  path: '/login',
  name: 'login',
  props: {
    redirect: true
  },
  meta: {
    title: BaseTitle,
    breadcrumb: {
      title: 'Vastgoedinformatieplatform'
    }
  },
  component: () => import('@/modules/auth-module/login/login.vue')
}

export const RouteLogout: IRouteConfig = {
  path: '/logout',
  name: 'logout',
  meta: {
    title: BaseTitle,
    authorize: {
      roles: [Administrator, Informatieaanvrager, Informatieleverancier, Kwaliteitscontroleur, FinancieelBeheerder]
    },
    breadcrumb: {
      title: 'Vastgoedinformatieplatform'
    }
  },
  component: () => import('@/modules/auth-module/logout/logout.vue')
}

export default [RouteLogin, RouteLogout] as IRouteConfig[]
