import { render, staticRenderFns } from "./vip-inlichting-preview-template-voorwaarden-gemeentelijke-akte.vue?vue&type=template&id=281552f3"
import script from "./vip-inlichting-preview-template-voorwaarden-gemeentelijke-akte.ts?vue&type=script&lang=ts&external"
export * from "./vip-inlichting-preview-template-voorwaarden-gemeentelijke-akte.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports