import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-milieuvergunning',
  data () {
    return {
      beschrijving: this.getAttribute('beschrijving'),
      dossiernr: this.getAttribute('dossiernummer'),
      referentienr: this.getAttribute('referentienummer'),
      inrichtingsnr: this.getAttribute('inrichtingsnummer'),
      geldigVan: this.getDatePeriodFromAttribute('geldigPeriode'),
      geldigTotEnMet: this.getDatePeriodUntilAttribute('geldigPeriode'),
      vergunningVerlenendeInstantie: this.getAttribute('vergunningVerlenendeInstantie'),
      status: this.getAttribute('status'),
      beslistOp: this.getAttribute('beslistOp'),
      beroepAangetekend: this.getAttribute('beroepAangetekend'),
      beroepsProcedures: this.getAttribute('beroepsProcedures'),
      beroepsProceduresFields: [
        'beroeps_instantie',
        'beroep_status',
        'beroep_beslist_op'
      ],
      activiteiten: this.getAttribute('activiteiten'),
      activiteitFields: [
        'activiteitRubriek',
        'activiteitVlareboCode',
        'activiteitRisicoKlasse',
        'activiteitOmschrijving',
        'activiteitStatus',
        'activiteitPeriode',
        'activiteitRubriekType'
      ],
      risicoKlasse: this.getAttribute('risicoKlasse'),
      vergunningsStatus: this.getAttribute('vergunningsStatus'),
      toegepasteWetgeving: this.getAttribute('toegepasteWetgeving'),
      voorwaarden: this.getAttribute('voorwaarden'),
      externeDocumentatie: this.getAttribute('externeDocumentatie')
    }
  },
  watch: {
    'beroepAangetekend.value': function () {
      if (this.beroepAangetekend.value === false) {
        this.beroepsProcedures.value = null
      }
    }
  },
  computed: {
    setDatumBeslissingVerplicht () {
      const verplicht = this.getAttribute('status').value !== 'GeenBeslissing'
      this.getAttribute('beslistOp').required = verplicht
      return verplicht
    }
  }
})
