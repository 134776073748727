import EventBus from '@/infrastructure/events/event-bus'
import { useUserStore } from '@/infrastructure/stores/user-store'
import { computed, defineComponent, getCurrentInstance } from 'vue'

export default defineComponent({
  name: 'ForbiddenModal',
  setup () {
    const root = getCurrentInstance()?.proxy
    const userStore = useUserStore()

    const firstname = computed(() => userStore.userModuleGetUserInfo?.firstName)
    const lastname = computed(() => userStore.userModuleGetUserInfo?.lastName)
    const organisation = computed(() => userStore.userModuleGetUserInfo?.organisationName)

    const logoutUser = () => {
      userStore.userModuleSetUserInactive()
    }

    const navigateToHome = () => {
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
      root.$router.push('/')
    }

    return {
      firstname,
      lastname,
      organisation,
      logoutUser,
      navigateToHome
    }
  }
})
