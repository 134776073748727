import { InlichtingComponentData } from '@/components/vip-inlichting/vip-inlichting'
import Vue from 'vue'

export default Vue.extend({
  name: 'vip-inlichting-attribute-collection-item',
  props: {
    inlichting: Object as ()=> InlichtingComponentData,
    modRemovable: Boolean
  },
  methods: {
    remove () {
      this.$emit('remove')
    }
  }
})
