import Vue from 'vue'

export default Vue.extend({
  name: 'vip-inlichting-card',
  computed: {
    classes (): unknown {
      return [
        'vip-inlichting'
      ]
    }
  }
})
