import Tile from 'ol/layer/Tile'
import WMTS from 'ol/source/WMTS'
import WMTSTileGrid from 'ol/tilegrid/WMTS'
import { Extent } from 'ol/extent'
import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import CircleStyle from 'ol/style/Circle'
import { IpproMapFeatureStyle } from './vl-ippro-map'

export interface IpproMapLayer {
  name: string;
  active?: boolean;
  id: string;
  thumbnail: {
    src: string;
    srcset?: string;
    alt: string;
  };
  tileTemplate: {
    extent: Extent;
    source: WMTS;
  };
  tile: Tile;
}

export const projectionExtent: Extent = [22000, 153000, 259000, 245000]
export const resolutions: number[] = [
  1024,
  512,
  256,
  128,
  64,
  32,
  16,
  8,
  4,
  2,
  1,
  0.5,
  0.25,
  0.125,
  0.0625,
  0.03125
]

const matrixData = new Array(15)
for (let z = 0; z < 16; ++z) {
  matrixData[z] = z
}
const matrixIds = matrixData

export const grbLayerTile = {
  extent: projectionExtent,
  source: new WMTS({
    url: 'https://geo.api.vlaanderen.be/GRB/wmts',
    layer: 'grb_bsk',
    style: '',
    matrixSet: 'BPL72VL',
    cacheSize: 200,
    format: 'image/png',
    tileGrid: new WMTSTileGrid({
      origin: [9928, 329072],
      extent: projectionExtent,
      resolutions,
      matrixIds
    })
  })
}

export const grbLayer: IpproMapLayer = {
  name: 'GRB',
  id: 'grb_bsk',
  thumbnail: {
    src: '/img/grb.png',
    alt: 'Afbeelding van Basiskaart'
  },
  tileTemplate: grbLayerTile,
  tile: null
}

export const grayLayerTile = {
  extent: projectionExtent,
  source: new WMTS({
    url: 'https://geo.api.vlaanderen.be/GRB/wmts',
    layer: 'grb_bsk_grijs',
    style: '',
    matrixSet: 'BPL72VL',
    cacheSize: 200,
    format: 'image/png',
    tileGrid: new WMTSTileGrid({
      origin: [9928, 329072],
      extent: projectionExtent,
      resolutions,
      matrixIds
    })
  })
}

export const grayLayer: IpproMapLayer = {
  name: 'GRB',
  id: 'grb_bsk_grijs',
  thumbnail: {
    src: '/img/grb_bsk_grijs.png',
    alt: 'Afbeelding van Basiskaart in grijswaarden'
  },
  tileTemplate: grayLayerTile,
  tile: null
}

export const orthoLayerTile = {
  extent: projectionExtent,
  source: new WMTS({
    url: 'https://geo.api.vlaanderen.be/OMWRGBMRVL/wmts',
    layer: 'omwrgbmrvl',
    style: '',
    matrixSet: 'BPL72VL',
    cacheSize: 200,
    format: 'image/png',
    tileGrid: new WMTSTileGrid({
      origin: [9928, 329072],
      resolutions,
      matrixIds
    })
  })
}

export const orthoLayer: IpproMapLayer = {
  name: 'Luchtfoto',
  id: 'omwrgbmrvl',
  thumbnail: {
    src: '/img/ortho.png',
    alt: 'Afbeelding van Orthofoto'
  },
  tileTemplate: orthoLayerTile,
  tile: null
}

export const defaultStyle: IpproMapFeatureStyle = {
  default: new Style({
    fill: new Fill({
      color: 'rgba(0, 85, 204, 0.6)'
    }),
    stroke: new Stroke({
      color: 'rgba(0, 85, 204, 0.8)',
      width: 2
    }),
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({
        color: 'rgba(0, 85, 204, 0.6)'
      }),
      stroke: new Stroke({
        color: 'rgba(0, 85, 204, 0.8)',
        width: 1
      })
    })
  }),
  active: new Style({
    fill: new Fill({
      color: 'rgba(0, 85, 204, 0.9)'
    }),
    stroke: new Stroke({
      color: 'rgba(0, 85, 204, 1)',
      width: 2
    }),
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({
        color: 'rgba(0, 85, 204, 0.9)'
      }),
      stroke: new Stroke({
        color: 'rgba(0, 85, 204, 1)',
        width: 1
      })
    })
  })
}

export interface IpproMapLayerSetting {
  layerId: string;
  backgroundColor?: string;
  opacity?: number;
}

export const registeredLayers = [grbLayer, orthoLayer, grayLayer]
