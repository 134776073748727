import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '../../vip-inlichting-attributes'
enum ActiteitenToevoegenStep {
  Init = 1,
  KiesRubriekType,
  ToevoegenActiviteiten
}
export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attribute-activiteiten',
  data () {
    return {
      activiteiten: this.getAttribute('activiteiten'),
      currentActiviteitenToevoegenStep: ActiteitenToevoegenStep.Init,
      activiteitenRubriekType: this.getAttribute('activiteitenRubriekType'),
      addButtonLabel: '+ Activiteit toevoegen',
      geldigVan: this.getAttribute('geldigVan'),
      geldigTotEnMet: this.getAttribute('geldigTotEnMet')
    }
  },
  props: {
    fields: { type: Array },
    template: {
      type: String,
      required: true
    },
    hideRubriekTypeChoice: Boolean
  },
  watch: {
    'activiteitenRubriekType.value': function (value) {
      // De component triggert 2x een change.  De 1x wordt het element doorgegeven, de 2x de geselecteerde waarde?!
      if (value && !value.type) {
        this.activiteiten.required = true
      } else {
        this.activiteiten.required = false
      }
    }
  },
  methods: {
    initActiviteiten: function () {
      return this.currentActiviteitenToevoegenStep === ActiteitenToevoegenStep.Init && !this.hasActiviteiten()
    },
    hasActiviteiten: function () {
      return this.activiteiten.value && this.activiteiten.value.length > 0
    },
    activiteitenToevoegenClicked: function () {
      if (this.hideRubriekTypeChoice) {
        this.currentActiviteitenToevoegenStep = ActiteitenToevoegenStep.ToevoegenActiviteiten
      } else {
        this.currentActiviteitenToevoegenStep = ActiteitenToevoegenStep.KiesRubriekType
      }
    },
    showActiviteitenRubriekType: function () {
      return this.currentActiviteitenToevoegenStep === ActiteitenToevoegenStep.KiesRubriekType && !this.hasActiviteiten()
    },
    cancelActiviteitenRubriekTypeClicked: function () {
      this.activiteitenRubriekType.value = null
      this.currentActiviteitenToevoegenStep = ActiteitenToevoegenStep.Init
    },
    addActiviteitenRubriekTypeClickHandler: function () {
      this.currentActiviteitenToevoegenStep = ActiteitenToevoegenStep.ToevoegenActiviteiten
    },
    showActiviteitenToevoegen: function () {
      return this.currentActiviteitenToevoegenStep === ActiteitenToevoegenStep.ToevoegenActiviteiten || this.hasActiviteiten()
    },
    activiteitenInputHandler: function (activiteiten: unknown[]) {
      if (activiteiten.length === 0) {
        this.getAttribute('activiteitenRubriekType').value = null
        this.fields.forEach(f => { this.getAttribute(f as string).value = null })
        this.currentActiviteitenToevoegenStep = ActiteitenToevoegenStep.Init
      }
    }
  }
})
