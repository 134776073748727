import { mixin as focusMixin } from 'vue-focus'
import Vue from 'vue'
import ClickOutside from '@/infrastructure/helpers/click-outside'
import axios, { AxiosResponse } from 'axios'
import { orderBy } from 'lodash'

declare module 'vue-focus';

export interface IMunicipality {
  municipalityCode: string
  municipalityName: string
}

export interface IMunicipalityResponse {
  municipalities: IMunicipality[]
}

export interface IDepartment {
  departmentCode: string
  departmentName: string
}

export interface IDepartmentResponse {
  departments: IDepartment[]
}

export interface ISection {
  sectionCode: string
}

export interface ISectionResponse {
  sections: ISection[]
}

export interface IParcel {
  perceelnummer: string
  capakey: string
}

export interface IParcelResponse {
  parcels: IParcel[]
}

export interface IParcelSearchResult {
  capakey: string
  municipalityCode?: string
  municipalityName?: string
  departmentCode?: string
  departmentName?: string
  sectionCode?: string
  perceelnummer?: string
  grondnummer?: string
  exponent?: string
  macht?: string
  bisnummer?: string
  adres?: string[]
  geometry?: { boundingBox: string, center: string }
}

export default Vue.extend({
  name: 'CapakeySearch',
  directives: {
    ClickOutside
  },
  mixins: [focusMixin],
  data () {
    return {
      isOpen: false,
      toggleFocus: false,
      gemeenteLijst: [],
      departementLijst: [],
      sectieLijst: [],
      perceelLijst: [],
      perceelResultaat: null,
      showNisCode: false,
      selectedNiscode: '',
      selectedDepartement: '',
      selectedSectie: '',
      selectedPerceel: '',
      baseUrl: 'https://geo.api.vlaanderen.be/capakey/v2/municipality'
    }
  },
  methods: {
    getGemeentenGesorteerd (): IMunicipality[] {
      return orderBy(this.gemeenteLijst, (g: IMunicipality) => this.showNisCode ? g.municipalityCode : g.municipalityName)
    },
    getDepartementenGesorteerd (): IDepartment[] {
      return orderBy(this.departementLijst, (g: IDepartment) => this.showNisCode ? g.departmentCode : g.departmentName)
    },
    toggle () {
      this.isOpen = !this.isOpen
    },
    close () {
      this.isOpen = false
    },
    selectGemeente () {
      if (!this.selectedNiscode) {
        return
      }
      this.departementLijst = null
      this.sectieLijst = null
      this.perceelLijst = null
      this.perceelResultaat = null
      this.selectedDepartement = null
      this.selectedSectie = null
      this.selectedPerceel = null
      this.fetchDepartments(this.selectedNiscode)
    },
    selectDepartement () {
      if (!this.selectedNiscode || !this.selectedDepartement) {
        return
      }
      this.sectieLijst = null
      this.perceelLijst = null
      this.perceelResultaat = null
      this.selectedSectie = null
      this.selectedPerceel = null
      this.fetchSections(this.selectedNiscode, this.selectedDepartement)
    },
    selectSectie () {
      if (!this.selectedNiscode || !this.selectedDepartement || !this.selectedSectie) {
        return
      }
      this.perceelLijst = null
      this.perceelResultaat = null
      this.selectedPerceel = null
      this.fetchParcels(this.selectedNiscode, this.selectedDepartement, this.selectedSectie)
    },
    selectPerceel () {
      if (!this.selectedNiscode || !this.selectedDepartement || !this.selectedSectie || !this.selectedPerceel) {
        return
      }
      this.perceelResultaat = null
      this.fetchParcelResult(this.selectedNiscode, this.selectedDepartement, this.selectedSectie, this.selectedPerceel)
    },
    fetchMunicipalities () {
      const municipalityUrl = `${this.baseUrl}?type=json&data=adp&status=actual`

      axios
        .get(municipalityUrl)
        .then((response: AxiosResponse<IMunicipalityResponse>) => {
          this.gemeenteLijst = response.data?.municipalities
        })
        .catch(() => {
          console.error('Ophalen gemeenten mislukt')
        })
    },
    fetchDepartments (nisCode: string): void {
      const departmentUrl = `${this.baseUrl}/${nisCode}/department?type=json&data=adp&status=actual`

      axios
        .get(departmentUrl)
        .then((response: AxiosResponse<IDepartmentResponse>) => {
          this.departementLijst = response.data?.departments
        })
        .catch(() => {
          console.error('Ophalen departementen mislukt')
        })
    },
    fetchSections (nisCode: string, departmentCode: string): void {
      const sectionUrl = `${this.baseUrl}/${nisCode}/department/${departmentCode}/section?type=json&data=adp&status=actual`

      axios
        .get(sectionUrl)
        .then((response: AxiosResponse<ISectionResponse>) => {
          this.sectieLijst = response.data?.sections
        })
        .catch(() => {
          console.error('Ophalen secties mislukt')
        })
    },
    fetchParcels (nisCode: string, departmentCode: string, sectionCode: string): void {
      const parcelUrl = `${this.baseUrl}/${nisCode}/department/${departmentCode}/section/${sectionCode}/parcel?type=json&data=adp&status=actual`

      axios
        .get(parcelUrl)
        .then((response: AxiosResponse<IParcelResponse>) => {
          this.perceelLijst = response.data?.parcels
        })
        .catch(() => {
          console.error('Ophalen percelen mislukt')
        })
    },
    fetchParcelResult (nisCode: string, departmentCode: string, sectionCode: string, parcelNr: string): void {
      const resultUrl = `${this.baseUrl}/${nisCode}/department/${departmentCode}/section/${sectionCode}/parcel/${parcelNr}?type=json&data=adp&status=actual`

      axios
        .get(resultUrl)
        .then((response: AxiosResponse<IParcelSearchResult>) => {
          this.perceelResultaat = response.data
        })
        .catch(() => {
          console.error('Ophalen perceelinformatie mislukt')
        })
    },
    selecteerPerceel () {
      this.$emit('selectie-perceel', this.perceelResultaat.capakey)
    }
  },
  mounted () {
    this.fetchMunicipalities()
  }
})
