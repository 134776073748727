import { Component, Prop, Vue } from 'vue-property-decorator'
import Feature from 'ol/Feature'
import { IpproMapType } from '../../../vl-ippro-map/vl-ippro-map'

@Component
export default class IpproMapZoomToFeature extends Vue {
  public map: IpproMapType = null

  @Prop({ required: false })
    feature!: Feature

  initMap () {
    if (!this.map) {
      let parent = this.$parent
      while (parent && !this.map) {
        if (parent.$options.name === 'IpproMap') {
          this.map = parent as IpproMapType
        }
        parent = parent.$parent
      }
    }
  }

  zoomToFeature (duration: number) {
    this.$emit('zoom-to-feature', this.feature)
    if (this.map) {
      const extent = this.feature.getGeometry().getExtent()
      this.map.zoomToExtent(extent, duration)
    }
  }

  mounted () {
    Vue.nextTick(() => {
      this.initMap()
    })
  }
}
