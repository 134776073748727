import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '@/components/vip-inlichting/components/vip-inlichting-attributes/vip-inlichting-attributes'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-risicogrond-activiteiten',
  data () {
    return {
      rubriek: this.getAttribute('activiteitRubriek'),
      vlareboCode: this.getAttribute('activiteitVlareboCode'),
      klasse: this.getAttribute('activiteitRisicoKlasse'),
      omschrijving: this.getAttribute('activiteitOmschrijving'),
      rubriekType: this.getAttribute('activiteitRubriekType')
    }
  }
})
