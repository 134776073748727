import { BaseTitle, IRouteConfig } from '@/infrastructure/route/vip-router'
import { Administrator, FinancieelBeheerder } from '@/infrastructure/constants/roles'
import { RouteDashboard } from '../dashboard-module/dashboard-routes'

export const RouteFinancieel: IRouteConfig = {
  path: '/financieel',
  name: 'financieel',
  meta: {
    title: `Financieel dashboard | ${BaseTitle}`,
    authorize: {
      roles: [FinancieelBeheerder, Administrator]
    },
    breadcrumb: {
      title: 'Financieel rapport',
      parent: RouteDashboard
    }
  },
  component: () => import('./financieel-dashboard.vue')
}

export default [RouteFinancieel] as IRouteConfig[]
