import vipInlichtingAttributes from '../../../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'
import { CollectionItem } from '../../../../components/vip-inlichting-attribute-collection/vip-inlichting-attribute-collection'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-stedenbouwkundige-overtreding-pv-uitspraak',
  data () {
    return {
      vervolgingRechterlijkeInstantie: this.getAttribute('vervolgingRechterlijkeInstantie'),
      adviesHogeRaadVoorHandhavingsuitvoeringDatum: this.getAttribute('adviesHogeRaadVoorHandhavingsuitvoeringDatum'),
      adviesHogeRaadVoorHandhavingsuitvoeringSoortAdvies: this.getAttribute('adviesHogeRaadVoorHandhavingsuitvoeringSoortAdvies'),
      eersteAanlegDatumVonnis: this.getAttribute('eersteAanlegDatumVonnis'),
      eersteAanlegOmschrijvingVonnis: this.getAttribute('eersteAanlegOmschrijvingVonnis'),
      eersteAanlegDatumBetekeningVonnis: this.getAttribute('eersteAanlegDatumBetekeningVonnis'),
      hofVanBeroepDatumIngediend: this.getAttribute('hofVanBeroepDatumIngediend'),
      hofVanBeroepDatumArrest: this.getAttribute('hofVanBeroepDatumArrest'),
      hofVanBeroepOmschrijvingArrest: this.getAttribute('hofVanBeroepOmschrijvingArrest'),
      hofVanBeroepDatumDadingUitvoeringstermijnHerstelmaatregel: this.getAttribute('hofVanBeroepDatumDadingUitvoeringstermijnHerstelmaatregel'),
      hofVanBeroepDatumBetekeningArrest: this.getAttribute('hofVanBeroepDatumBetekeningArrest'),
      instantieOpties: this.getAttribute('vervolgingRechterlijkeInstantie').options,
      showAdviesHogeRaad: false,
      showRechtbankEersteAanleg: false,
      showHofVanBeroep: false
    }
  },
  props: {
    userCollection: Array as ()=> CollectionItem[]
  },
  methods: {
    setRequired (instantie: string) {
      this.adviesHogeRaadVoorHandhavingsuitvoeringDatum.required = false
      this.adviesHogeRaadVoorHandhavingsuitvoeringSoortAdvies.required = false
      this.eersteAanlegDatumVonnis.required = false
      this.eersteAanlegOmschrijvingVonnis.required = false
      this.hofVanBeroepDatumIngediend.required = false
      this.showAdviesHogeRaad = false
      this.showRechtbankEersteAanleg = false
      this.showHofVanBeroep = false
      if (instantie === 'HogeRaadVoorHandhavingsuitvoering') {
        this.adviesHogeRaadVoorHandhavingsuitvoeringDatum.required = true
        this.adviesHogeRaadVoorHandhavingsuitvoeringSoortAdvies.required = true
        this.showAdviesHogeRaad = true
      } else if (instantie.includes('EersteAanleg')) {
        this.eersteAanlegDatumVonnis.required = true
        this.eersteAanlegOmschrijvingVonnis.required = true
        this.showRechtbankEersteAanleg = true
      } else if (instantie === 'HofVanBeroep') {
        this.hofVanBeroepDatumIngediend.required = true
        this.showHofVanBeroep = true
      }
    }
  },
  watch: {
    vervolgingRechterlijkeInstantie: {
      handler (newValue) {
        if (newValue !== null) {
          this.setRequired(newValue.value)
        }
      },
      deep: true
    }
  },
  mounted () {
    if (this.userCollection.map(m => m.attributes.find(f => f.name === 'vervolgingRechterlijkeInstantie').value).includes('HogeRaadVoorHandhavingsuitvoering') && this.vervolgingRechterlijkeInstantie.value !== 'HogeRaadVoorHandhavingsuitvoering') {
      this.instantieOpties = this.vervolgingRechterlijkeInstantie.options.filter(f => f.value !== 'HogeRaadVoorHandhavingsuitvoering')
    }
    if (this.vervolgingRechterlijkeInstantie.value && this.vervolgingRechterlijkeInstantie.value !== '') {
      this.setRequired(this.vervolgingRechterlijkeInstantie.value)
    }
  }
})
