import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-splitsing',
  data () {
    return {
      beschrijving: this.getAttribute('Beschrijving'),
      beslistDoor: this.getAttribute('Instantie'),
      gemeentelijkDossiernummer: this.getAttribute('GemeentelijkDossiernummer'),
      aardVanDeAanvraag: this.getAttribute('AardAanvraag'),
      beslissing: this.getAttribute('Beslissing'),
      beslistOp: this.getAttribute('DatumBeslissing'),
      antwoordVerstuurdOp: this.getAttribute('DatumAntwoord'),
      externeDocumentatie: this.getAttribute('ExterneDocumentatie')
    }
  }
})
