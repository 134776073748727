import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '@/components/vip-inlichting/components/vip-inlichting-attributes/vip-inlichting-attributes'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-risicogrond-v2-rubrieken',
  data () {
    return {
      rubrieknummer: this.getAttribute('Rubrieknummer'),
      vlareboCode: this.getAttribute('VlareboCode'),
      omschrijving: this.getAttribute('Omschrijving'),
      startdatum: this.getAttribute('Startdatum'),
      einddatum: this.getAttribute('Einddatum'),
      vitoUrl: this.getAttribute('VitoUrl'),
      referentieVergunning: this.getAttribute('ReferentieVergunning'),
      rubriekType: this.getAttribute('RubriekType'),
      schrapping: this.getAttribute('Schrapping'),
      schrappingReden: this.getAttribute('Reden'),
      schrappingDossier: this.getAttribute('Dossiernummer'),
      geschraptOp: this.getAttribute('DatumBeslissing')
    }
  },
  methods: {
    schrappingChangeHandler: function () {
      this.schrappingReden.required = this.schrapping.value
    }
  },
  computed: {
    schrappingBeslistOpValue: {
      get: function () {
        return [this.getAttribute('DatumBeslissing').value]
      },
      set: function (date: string) {
        this.getAttribute('DatumBeslissing').value = new Date(Date.parse(date))
      }
    }
  },
  created () {
    this.schrappingReden.required = this.schrapping.value
  }
})
