import { InlichtingComponentData } from '@/components/vip-inlichting/vip-inlichting'
import vipInlichtingAttributes from '../../../vip-inlichting-attributes'
import { InlichtingAttribuut } from '@/infrastructure/bff-client/bff-client'
import mixins from 'vue-typed-mixins'
import EventBus from '@/infrastructure/events/event-bus'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-omgevingsvergunning-beslssing-collection',
  data () {
    return {
      indexToRemove: null,
      beslissingen: this.getAttribute('beslissingen')
    }
  },
  props: {
    inlichting: Object as ()=> InlichtingComponentData,
    attributes: Array as ()=> InlichtingAttribuut[],
    addButtonLabel: {
      default: '+ Item toevoegen',
      type: String
    },
    modCanAdd: Boolean,
    minItems: {
      default: 0,
      type: Number
    },
    allwaysAllowDelete: {
      default: false,
      type: Boolean
    },
    modDisabled: Boolean
  },
  methods: {
    makeUnique (index: number) {
      // De Math random is toegevoegd omdat anders het verwijderen van de items niet correct werkt.
      return 'beslissing-' + index.toString() + (Math.floor(Math.random() * (1000000 - 1 + 1) + 1)).toString()
    },
    requestRemoveByIndex (index: number) {
      this.indexToRemove = index
    },
    removeByIndex (index: number) {
      this.beslissingen.value.splice(index, 1)
      this.indexToRemove = null
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    },
    beslissingSelected (beslissingType: string) {
      if (!this.beslissingen.value) {
        this.beslissingen.value = [] as InlichtingAttribuut[][]
      }

      let beslissingMetadataAttributes: InlichtingAttribuut[]

      switch (beslissingType.toLocaleLowerCase()) {
        case 'arrestvanraadvanstate':
          beslissingMetadataAttributes = this.attributes.find(a => a.name === 'metadataArrestVanRaadVanState').value as InlichtingAttribuut[]
          break
        case 'melding':
          beslissingMetadataAttributes = this.attributes.find(a => a.name === 'metadataMelding').value as InlichtingAttribuut[]
          break
        case 'eersteadministratieveaanleg':
          beslissingMetadataAttributes = this.attributes.find(a => a.name === 'metadataEersteAdministratieveAanleg').value as InlichtingAttribuut[]
          break
        case 'tweedeadministratieveaanleg':
          beslissingMetadataAttributes = this.attributes.find(a => a.name === 'metadataTweedeAdministratieveAanleg').value as InlichtingAttribuut[]
          break
        case 'arrestraadvergunningbetwisting':
          beslissingMetadataAttributes = this.attributes.find(a => a.name === 'metadataArrestRaadVoorVergunningbetwisting').value as InlichtingAttribuut[]
          break
      }

      if (beslissingMetadataAttributes) {
        const beslissingAttributesCopy = JSON.parse(JSON.stringify(beslissingMetadataAttributes))
        this.getAttribute('Beslissingen').value.push(beslissingAttributesCopy)
      }
    },
    // Wordt opgeroepen vanuit de Vue template.
    getBeslissingTemplate (beslissing: unknown) {
      const beslissingType = (beslissing as InlichtingAttribuut[]).find((i: InlichtingAttribuut) => i.name.toLowerCase() === 'BeslissingDiscriminator'.toLowerCase()).value
      switch (beslissingType.toLocaleLowerCase()) {
        case 'arrestvanraadvanstate':
          return 'vip-arrest-van-raadvanstate'
        case 'melding':
          return 'vip-melding'
        case 'eersteadministratieveaanleg':
          return 'vip-eerste-administratieve-aanleg'
        case 'tweedeadministratieveaanleg':
          return 'vip-tweede-administratieve-aanleg'
        case 'arrestraadvergunningbetwisting':
          return 'vip-arrest-raad-vergunningbetwisting'
        default:
          return ''
      }
    }
  }
})
