import { mixin as focusMixin } from 'vue-focus'
import Vue from 'vue'
import ClickOutside from '@/infrastructure/helpers/click-outside'
import { useUserStore } from '@/infrastructure/stores/user-store'
import { Administrator, FinancieelBeheerder } from '@/infrastructure/constants/roles'
import { mapStores } from 'pinia'

export default Vue.extend({
  name: 'ProfileDropdown',
  directives: {
    ClickOutside
  },
  mixins: [focusMixin],
  data () {
    return {
      isOpen: false,
      toggleFocus: false,
      financieelMenuZichtbaar: false
    }
  },
  computed: {
    ...mapStores(useUserStore),
    displayName (): string | undefined {
      const user = this.userStore.userModuleGetUserInfo
      if (user && (user.firstName || user?.lastName)) {
        return `${user?.firstName} ${user?.lastName}`
      } else {
        return undefined
      }
    },
    orgName (): string | undefined {
      const user = this.userStore.userModuleGetUserInfo
      return user?.organisationName
    },
    signOutUri (): string {
      return this.userStore.userModuleGetSignOutUri
    }
  },
  methods: {
    switchIdentity () {
      sessionStorage.clear()
      window.location.href = this.userStore.userModuleGetSwitchIdentityUri
    },
    toggle () {
      this.isOpen = !this.isOpen
    },
    close () {
      this.isOpen = false
    }
  },
  mounted () {
    this.financieelMenuZichtbaar = this.userStore.userModuleHasRole([FinancieelBeheerder, Administrator])
  }
})
