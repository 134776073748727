import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'
import ClickOutside from '../directives/click-outside'

export interface AutoCompleteSelection {
  obj: any;
  name: string;
}

export interface AutoCompleteResult {
  title: string;
  subtitle?: string;
  value: AutoCompleteSelection;
  selected?: boolean;
}

Vue.directive('click-outside', ClickOutside)

@Component({
  inheritAttrs: false
})
export default class IpproAutocomplete extends Vue {
  @Ref() readonly inputField!: HTMLInputElement

  public results: AutoCompleteResult[] = []
  public resultsVisible = true

  @Prop({ type: Array, required: true })
  public data!: AutoCompleteResult[]

  @Prop({ type: Boolean, default: false })
  public fetching: boolean

  @Prop({ type: String, default: '' })
  public feedback: string

  @Prop({
    type: String,
    required: false,
    default:
        'Gebruik de pijltjes (boven en onder) om, na het invoeren van X karakters, door de suggesties van de suggestiebox te navigeren. Bevestig je keuze met &quot;enter&quot; of gebruik de &quot;escape&quot; knop om te suggestiebox te sluiten.'
  })
  public helpText!: string

  @Prop({ type: Boolean, required: false, default: false })
  public modRequired: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public modSelectFirstResult: boolean

  @Watch('data', { immediate: true, deep: true })
  public onDataChanged () {
    this.results = (this.data || []).map((obj, index) => ({
      ...obj,
      selected: this.modSelectFirstResult && index === 0
    }))
  }

  @Watch('results', { immediate: true, deep: true })
  public onResultsChanged () {
    if (this.hasResults) {
      this.showResults()
    }
  }

  get hasResults () {
    return this.results.length
  }

  public reset () {
    this.results = []
  }

  public hideResults () {
    this.resultsVisible = false
  }

  public showResults () {
    this.resultsVisible = true
  }

  public navigateResults (direction: string) {
    if (this.results.length) {
      const currentResult = this.results.findIndex(result => result.selected)
      const newResult =
          direction === 'down'
            ? currentResult === this.results.length - 1
              ? 0
              : currentResult + 1
            : !currentResult || currentResult === 0
                ? this.results.length - 1
                : currentResult - 1
      this.results = this.results.map(obj => ({ ...obj, selected: false }))
      this.results[newResult].selected = true
    }
  }

  public arrowDown () {
    if (this.results && !this.resultsVisible) {
      this.resultsVisible = true
    } else {
      this.navigateResults('down')
    }
  }

  public arrowUp () {
    this.navigateResults('up')
  }

  public selectFocussedResult () {
    const focussedResult = this.results.filter(obj => obj.selected)
    if (this.results && this.resultsVisible && focussedResult[0]) {
      this.select(focussedResult[0].value)
      if (event) {
        event.preventDefault()
      }
    }
  }

  public select (item: any) {
    this.$attrs.value = item.name
    this.$emit('select', item)
    this.reset()
  }
}
