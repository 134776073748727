function getMilieuActiviteitPeriodText (activiteit: {geldigVan: string, geldigTot: string}): string {
  let geldigVan
  let geldigTot

  if (activiteit.geldigVan || activiteit.geldigTot) {
    if (activiteit.geldigVan) {
      geldigVan = ' van ' + activiteit.geldigVan
    } else {
      geldigVan = ''
    }

    if (activiteit.geldigTot) {
      geldigTot = ' t.e.m. ' + activiteit.geldigTot
    } else {
      geldigTot = ''
    }

    return 'Geldig' + geldigVan + geldigTot
  } else {
    return ''
  }
}

export const getMilieuActiviteitenDetails = (activiteit: any): string => {
  let omschrijving = ''
  if (activiteit.rubriekType) {
    omschrijving += activiteit.rubriekType + '$'
  }
  if (activiteit.vlareboCode) {
    omschrijving += '<abbr title="' + activiteit.vlareboDescription + '">' + activiteit.vlareboCode + '</abbr>$'
  }
  if (activiteit.klasse) {
    omschrijving += activiteit.klasse + '$'
  }
  if (activiteit.status) {
    omschrijving += activiteit.status + '$'
  }
  omschrijving += getMilieuActiviteitPeriodText(activiteit)
  return omschrijving.replaceAll('$', ' - ')
}
