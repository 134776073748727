import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '@/components/vip-inlichting/components/vip-inlichting-attributes/vip-inlichting-attributes'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-kleinhandelsactiviteit',
  data () {
    return {
      beschrijving: this.getAttribute('omschrijving'),
      type: this.getAttribute('type'),
      vervalDatum: this.getAttribute('datumVerval'),
      vervalMotivering: this.getAttribute('motiveringNietVerval'),
      vervalStatus: this.getAttribute('vervalStatus')
    }
  }
})
