import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class IpproIconText extends Vue {
  @Prop({ type: String, required: false, default: 'span' })
  public tagName!: string

  @Prop({ type: String, required: true })
  public icon!: string

  @Prop({ type: Boolean, default: false })
  public modIconBefore!: boolean

  @Prop({ type: Boolean, default: false })
  public modIconAfter!: boolean

  get iconClasses () {
    return [
      'ippro-icon-text__icon',
      {
        'ippro-icon-text__icon--before': this.modIconBefore,
        'ippro-icon-text__icon--after': this.modIconAfter
      }
    ]
  }
}
