import { NavigationGuard } from 'vue-router'
import { IRoute } from './vip-router'

export const metaDataHandler: NavigationGuard = (to: IRoute, _from: IRoute, next) => {
  if (document && to.meta) {
    if (to.meta.title) {
      let title = to.meta.title
      // Replace any placeholder in the page title with the corresponding router parameter value.
      Object.keys(to.params).forEach(routeParameterKey => { title = title.replace(`{{:${routeParameterKey}}}`, to.params[routeParameterKey]) })
      document.title = title
    }
  }
  next()
}
