import { IpproMapZone } from '@/components/ippro/vl-ippro-map/vl-ippro-map'
import vipInlichting from '../../vip-inlichting'
import mixins from 'vue-typed-mixins'
import { InlichtingResponse } from '@/infrastructure/bff-client/bff-client'

export default mixins(vipInlichting).extend({
  name: 'vip-inlichting-template-read-only-map',
  data () {
    return {
      showMap: false
    }
  },
  computed: {
    zones (): IpproMapZone[] {
      return ((this.inlichting as InlichtingResponse)?.detail?.zones || []).map((zone: any) => ({
        geometry: zone
      }))
    }
  }
})
