import { Informatieaanvrager } from '@/infrastructure/constants/roles'
import { BaseTitle, IRouteConfig } from '@/infrastructure/route/vip-router'
import { RouteDashboard } from '../dashboard-module/dashboard-routes'

export const RouteAanvraagCreatie: IRouteConfig = {
  path: '/aanvragen/nieuw',
  name: 'aanvraag',
  meta: {
    title: `Nieuwe aanvraag | ${BaseTitle}`,
    authorize: {
      roles: [Informatieaanvrager]
    },
    breadcrumb: {
      title: 'Nieuwe aanvraag',
      parent: RouteDashboard
    }
  },
  component: () => import('./aanvraag-creatie.vue')
}

export default [RouteAanvraagCreatie] as IRouteConfig[]
