import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-template-stedenbouwkundige-melding',
  data () {
    return {
      beschrijving: this.getAttribute('beschrijving'),
      dossiernummer: this.getAttribute('referentie'),
      aardVanDeAanvraag: this.getAttribute('aardaanvraag'),
      verlener: this.getAttribute('vergunningverlenendeoverheid'),
      status: this.getAttribute('beslissingeersteaanleg'),
      beslist_op: this.getAttribute('datumbeslissing'),
      vervalDatum: this.getAttribute('Vervaldatum'),
      vervalMotivering: this.getAttribute('VervalMotivering'),
      vervalStatus: this.getAttribute('VervalStatus'),
      beroepAangetekend: this.getAttribute('beroepAangetekend'),
      beroepStatus: this.getAttribute('beroep_status'),
      beroepBeslistOp: this.getAttribute('beroep_beslist_op'),
      bijlage: this.getAttribute('bijlagen'),
      externeDocumentatie: this.getAttribute('externeDocumentatie'),
      gemeentelijkDossiernummer: this.getAttribute('gemeentelijkDossiernummer'),
      intrekkingPartij: this.getAttribute('Intrekking_PartijType'),
      intrekkingDatum: this.getAttribute('Intrekking_Datum'),
      intrekkingMotivering: this.getAttribute('Intrekking_Motivering'),
    }
  },
  methods: {
    getOptionLabel (option: string) {
      const res = this.aardVanAanvraagOptions.find(f => f.value === option)
      if (res !== undefined) {
        return res.label
      }
      return ''
    }
  },
  computed: {
    aardVanAanvraagOptions: {
      get: function () {
        return (this.getAttribute('aardaanvraag').options || [])
      }
    }
  },
  watch: {
    'beroepAangetekend.value': function (value: unknown) {
      if (value === false) {
        this.beroepStatus.value = null
        this.beroepStatus.required = false
        this.beroepBeslistOp.value = null
      } else {
        this.beroepStatus.required = true
      }
    },
    'vervalStatus.value': function (value: unknown) {
      if (value && value !== 'Ingetrokken') {
        this.intrekkingPartij.value = ''
        this.intrekkingDatum.value = ''
        this.intrekkingMotivering.value = ''
      } else if (value === 'Ingetrokken') {
        this.vervalMotivering.value = ''
        this.vervalDatum.value = ''
      }
    }
  }
})
