import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import {
  DossierStatus,
  Inlichting,
  InlichtingenIndicator, InlichtingenStatus,
  TypeInlichting
} from '@/infrastructure/bff-client/bff-client'
import { useUserStore } from '@/infrastructure/stores/user-store'
import { BffClientFactory } from '@/infrastructure/bff-client/bff-client-handler'
import { formatDate } from '../../../../../infrastructure/filters/filters'

export default defineComponent({
  name: 'vip-inlichting-preview-new',
  methods: { formatDate },
  props: {
    dossierId: String,
    dossierStatus: Number as PropType<DossierStatus>,
    isVerplicht: Boolean,
    inlichtingLabel: String,
    vipInlichting: {
      type: Object as PropType<Inlichting>,
      required: true
    }
  },
  setup (props, context) {
    const isValidated = ref(false)
    const userStore = useUserStore()
    const inlichting = ref(props.vipInlichting as Inlichting)
    const informatieVerstrekker = ref((props.vipInlichting as Inlichting)?.bronInformatie?.informatieverstrekker ?? '')
    const bronLaatsteWijziging = ref((props.vipInlichting as Inlichting)?.bronInformatie?.laatsteWijzigingTijdstip as Date ?? '')

    const userCanEdit = computed(() => userStore.userCanEditDossier)
    const userIsOperationeelBeheerder = computed(() => userStore.userIsAdmin)
    const isVerrijkingGefaald = computed(() => (props.vipInlichting as Inlichting)?.inlichtingenStatus === InlichtingenStatus.VERRIJKING_DOOR_VIP_NIET_GESLAAGD)

    const openInteractieveKaart = () => {
      for (const url of (props.vipInlichting as Inlichting).kaarten.map(kaart => kaart.url)) {
        window.open(url, '_blank')
      }
    }

    const herbevraagInlichtingType = (inlichtingType: TypeInlichting) => {
      BffClientFactory().dossierInlichtingen_HerbevraagInlichtingType(props.dossierId, inlichtingType)
        .then(() => {
          context.emit('herbevraagd')
        })
    }

    onMounted(() => {
      isValidated.value = props.dossierStatus === DossierStatus.Gevalideerd
    })

    return {
      inlichting,
      informatieVerstrekker,
      bronLaatsteWijziging,
      isValidated,
      userCanEdit,
      userIsOperationeelBeheerder,
      isVerrijkingGefaald,
      openInteractieveKaart,
      herbevraagInlichtingType,
      InlichtingenIndicator
    }
  }
})
