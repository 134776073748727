import VipInlichting from '../../vip-inlichting'
import mixins from 'vue-typed-mixins'

export default mixins(VipInlichting).extend({
  name: 'vip-inlichting-template-default',
  methods: {
    inlichtingToggled (isOpen: boolean) {
      if (isOpen) {
        if (this.inlichting.vanToepassing === true) {
          this.scrollInlichtingIntoView()
        } else {
          window.scrollBy({ top: 200 })
        }
      }
    }
  }
})
