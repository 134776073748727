import vipInlichtingAttributes from '../../vip-inlichting-attributes'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-inlichting-attributes-planbaten-of-schade',
  data () {
    return {
      beschrijving: this.getAttribute('Beschrijving'),
      compensatieType: this.getAttribute('CompensatieType'),
      datumStatus: this.getAttribute('Datum'),
      dossierNummer: this.getAttribute('DossierNummer'),
      dossiertype: this.getAttribute('Dossiertype'),
      externeDocumentatie: this.getAttribute('ExterneDocumentatie'),
      oorspronkelijkeBestemming: this.getAttribute('OorspronkelijkeBestemming'),
      nieuweBestemming: this.getAttribute('NieuweBestemming'),
      planStatus: this.getAttribute('Status'),
      referentie: this.getAttribute('Referentie')
    }
  }
})
