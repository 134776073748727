import { InlichtingAttribuut } from '@/infrastructure/bff-client/bff-client'
import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '@/components/vip-inlichting/components/vip-inlichting-attributes/vip-inlichting-attributes'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-verkaveling-van-gronden',
  data () {
    return {
      externeDocumentatie: this.getAttribute('ExterneDocumentatie'),
      lasten: this.getAttribute('Lasten'),
      beschrijving: this.getAttribute('Omschrijving'),
      type: this.getAttribute('Type'),
      verkoopbaarheidsattestDatum: this.getAttribute('VerkoopbaarheidsattestDatum'),
      vervalDatum: this.getAttribute('Datum'),
      vervalMotivering: this.getAttribute('Motivering'),
      vervalStatus: this.getAttribute('Status'),
      lotInformatie: this.getAttribute('LotInformatie'),
      lotInformatieFields: this.getAttribute('lotInformatieMetadata').value.map((a: InlichtingAttribuut) => a.name),
      lotInformatieMetadata: this.getAttribute('lotInformatieMetadata').value
    }
  },
  methods: {
    getOptionLabel (option: string) {
      const res = this.lasten.options.find(f => f.value === option)
      if (res !== undefined) {
        return res.label
      }
      return ''
    }
  }
})
