import Vue from 'vue'
import ProfileDropdown from './components/profile-dropdown/profile-dropdown.vue'
import VipBreadcrumb from './components/vip-breadcrumb/vip-breadcrumb.vue'
import { useUserStore } from '@/infrastructure/stores/user-store'
import { mapState } from 'pinia'

export default Vue.extend({
  name: 'Functional-Header',
  components: {
    ProfileDropdown,
    VipBreadcrumb
  },
  computed: {
    ...mapState(useUserStore, ['userModuleGetUserInfo']),
    isUpdating () {
      return false
    },
    isLoggedIn () {
      return this.userModuleGetUserInfo
    }
  }
})
