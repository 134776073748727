import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '../../../../vip-inlichting-attributes'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-melding',
  data () {
    return {
      beslistOp: this.getAttribute('DatumBeslissing'),
      beslissing: this.getAttribute('Type'),
      beslistDoor: this.getAttribute('Instantie')
    }
  }
})
