import { InlichtingAttribuut } from '@/infrastructure/bff-client/bff-client'
import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '@/components/vip-inlichting/components/vip-inlichting-attributes/vip-inlichting-attributes'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-exploitatie-handeling',
  data () {
    return {
      onderwerp: this.getAttribute('onderwerp'),
      geldigVan: this.getDatePeriodFromAttribute('geldigPeriode'),
      geldigTotEnMet: this.getDatePeriodUntilAttribute('geldigPeriode'),
      inrichtingsNr: this.getAttribute('inrichtingsnummer'),
      risicoKlasse: this.getAttribute('risicoKlasse'),
      rubriekenLijst: this.getAttribute('activiteitenRubriekType'),
      activiteiten: this.getAttribute('activiteiten'),
      activiteitenFields: this.getAttribute('activiteitenMetaData').value.map((a: InlichtingAttribuut) => a.name),
      activiteitenMetadata: this.getAttribute('activiteitenMetaData').value,
      vervalDatum: this.getAttribute('Vervaldatum'),
      vervalMotivering: this.getAttribute('VervalMotivering'),
      vervalStatus: this.getAttribute('VervalStatus'),
      addButtonLabel: '+ Activiteit toevoegen'
    }
  },
  methods: {
    selectRubriekenLijst () {
      if (this.rubriekenLijst.value === 'Onbekend') {
        if ((this.$refs.activiteiten as any).userCollection.length > 0) {
          (this.$refs.activiteiten as any).removeAll()
        }
        return
      }
      if ((this.$refs.activiteiten as any).userCollection.length === 0) {
        (this.$refs.activiteiten as any).addItem()
      }
    }
  }
})
