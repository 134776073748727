import vipInlichtingPreview from '../../vip-inlichting-preview'
import mixins from 'vue-typed-mixins'

export default mixins(vipInlichtingPreview).extend({
  name: 'vip-inlichting-preview-template-gemeentelijk-onteigeningsplan',
  methods: {
    linksAvailable () {
      if (this.previewData.detail === null) {
        return false
      }
      return this.previewData.detail.bijlagen?.length > 0 || this.previewData.detail.externeDocumentatie?.length > 0
    }
  }
})
