import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '@/components/vip-inlichting/components/vip-inlichting-attributes/vip-inlichting-attributes'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-verkaveling-van-gronden-lot-info',
  data () {
    return {
      nummer: this.getAttribute('Nummer'),
      datum: this.getAttribute('Datum'),
      motivering: this.getAttribute('Motivering'),
      status: this.getAttribute('Status')
    }
  }
})
