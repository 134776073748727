import { Component, Vue } from 'vue-property-decorator'
import * as widgetClient from '@govflanders/vl-widget-client'
import 'reflect-metadata'
import axios from 'axios'

@Component({
  name: 'VipFooter'
})
export default class VipFooter extends Vue {
  get globalFooterWidgetId () {
    return config.VUE_APP_GLOBAL_FOOTER_WIDGET_ID
  }

  get mbpFooterWidgetUri () {
    return config.VUE_APP_MBP_FOOTER_URI
  }

  private async generateFooter () {
    if (this.globalFooterWidgetId) {
      axios
        .get(
          `https://widgets.vlaanderen.be/widget/live/${this.globalFooterWidgetId}`
        )
        .then(response => {
          const script = (response.data as string).replace(
            /document\.write\((.*?)\);/,
            'document.getElementsByClassName("global-footer-placeholder")[0].innerHTML = $1;'
          )
          eval(script)
        })
    } else if (this.mbpFooterWidgetUri) {
      try {
        const footer = await widgetClient.bootstrap(this.mbpFooterWidgetUri)
        footer.setMountElement(document.getElementsByClassName('global-footer-placeholder')[0])
        footer.mount()
      } catch (error) {}
    }
  }

  mounted () {
    this.generateFooter()
  }
}
