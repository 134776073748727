import {
  Inlichting,
  InlichtingenIndicator,
  InlichtingInput, InlichtingInputInlichtingenIndicator
} from '@/infrastructure/bff-client/bff-client'
import { computed, getCurrentInstance, onMounted, reactive, Ref, ref } from 'vue'
import { Aangevraagd } from '@/infrastructure/constants/dashboard-tabs'
import { useUserStore } from '@/infrastructure/stores/user-store'
import { useDossierStore } from '@/infrastructure/stores/dossier-store'
import VipInlichtingAccordion
  from '@/components/vip-inlichting/components/vip-inlichting-accordion/vip-inlichting-accordion'

export default function useInlichting (
  inlichting: Inlichting,
  mapInlichtingToInput: (vipInlichting?: Inlichting)=> InlichtingInput,
  mapInputToInlichting: (input: InlichtingInput, inlichtingId: string)=> Inlichting) {
  const refs: { [key: string]: Ref } = reactive({})
  const vipInlichting = ref(inlichting)
  const inlichtingInput = ref(mapInlichtingToInput(inlichting))
  const saving = ref(false)

  const validationErrors = ref(new Map<string, string>())
  const accordion = ref<InstanceType<typeof VipInlichtingAccordion>>(null)

  const instance = getCurrentInstance()
  const route = instance?.proxy?.$route

  const userStore = useUserStore()
  const dossierStore = useDossierStore()

  const isDossierEditable = computed(() => !dossierStore.dossierIsReadOnly && route?.params?.dossierType !== Aangevraagd)
  const canEdit = computed(() => isDossierEditable.value && (userStore.userCanEditDossier || userStore.userIsAdmin))

  onMounted(() => {
    if (!inlichting) { return }
    refs[inlichting.inlichtingId] = ref(null)
  })

  const inlichtingToggled = (isOpen: boolean) => {
    if (isOpen) {
      if (vipInlichting.value.inlichtingenIndicator === InlichtingenIndicator.JA) {
        const element = refs[inlichting.inlichtingId].value
        scrollInlichtingIntoView(element)
      } else {
        window.scrollBy({ top: 200 })
      }
    }
  }

  const toggleIndicator = (vanToepassing: boolean) => {
    inlichtingInput.value.inlichtingenIndicator = vanToepassing ? InlichtingInputInlichtingenIndicator.JA : InlichtingInputInlichtingenIndicator.NEE
  }

  const cancelEdit = () => {
    inlichtingInput.value = mapInlichtingToInput(vipInlichting.value)
    accordion.value.opened = false
    scrollInlichtingIntoView(accordion.value)
    validationErrors.value.clear()
  }

  const saveInlichting = (inlichtingId: string) => {
    vipInlichting.value = mapInputToInlichting(inlichtingInput.value, inlichtingId)
    accordion.value.opened = false
    scrollInlichtingIntoView(accordion.value)
  }

  const downloadUrl = (bijlageId: string): string => {
    return config.VUE_APP_BASE_URI + '/bff/dossiers/bijlagen/' + bijlageId
  }

  const scrollInlichtingIntoView = (element: any) => {
    if (element) {
      setTimeout(() => {
        element.$el.scrollIntoView({ behavior: 'smooth' })
        window.scrollBy({ top: -160 })
      }, 100)
    }
  }

  const generateId = (propertyName: string) => {
    return `${(inlichting as any).inlichtingType.toString().toLowerCase()}${(inlichting.inlichtingId ? '-' + inlichting.inlichtingId : '')}-${propertyName}`
  }

  return {
    refs,
    accordion,
    vipInlichting,
    inlichtingInput,
    validationErrors,
    saving,
    canEdit,
    toggleIndicator,
    inlichtingToggled,
    cancelEdit,
    saveInlichting,
    downloadUrl,
    generateId
  }
}
