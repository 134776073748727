import mixins from 'vue-typed-mixins'
import vipInlichtingAttributes from '../../../../vip-inlichting-attributes'

export default mixins(vipInlichtingAttributes).extend({
  name: 'vip-arrest-van-raadvanstate',
  data () {
    return {
      ingediendOp: this.getAttribute('DatumBeroepIngediend'),
      arrest: this.getAttribute('Type'),
      uitgesprokenOp: this.getAttribute('DatumArrest')
    }
  }
})
